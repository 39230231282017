import React, { useEffect, useState, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import CountryCodeDropdown from "../CountryCodeDropdown";
import { useHistory, useLocation } from "react-router-dom";
import back from "@assets/pictures/svgs/back-modal.svg";
import close from "@assets/pictures/svgs/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { login, register } from "../../../store/auth/authActions";
import { setTimeoutError } from "../../../store/movies/movieActions";
import { withNamespaces } from "react-i18next";

const Timeout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const t = props.t;

  useEffect(() => {
    // setRegisterNew(registerNew);
  }, []);
  // const onFormSubmit = (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);
  //   let count = 0;
  //   if (email !== "") {
  //     setEmailError(false);
  //   }
  //   if (email === "") {
  //     setEmailError(true);
  //     count++;
  //   }
  //   if (registerNew && firstName !== "") {
  //     setFirstNameError(false);
  //   }
  //   if (registerNew && firstName === "") {
  //     setFirstNameError(true);
  //     count++;
  //   }
  //   if (registerNew && lastName !== "") {
  //     setLastNameError(false);
  //   }
  //   if (registerNew && lastName === "") {
  //     setLastNameError(true);
  //     count++;
  //   }
  //   if (registerNew && phone_no !== "") {
  //     setPhoneNoError(false);
  //   }
  //   if (registerNew && (phone_no === "" || !countryId)) {
  //     setPhoneNoError(true);
  //     count++;
  //   }

  //   if (password !== "") {
  //     setPasswordError(false);
  //   }
  //   if (password === "") {
  //     setPasswordError(true);
  //     count++;
  //   }
  //   if (count > 0) {
  //     return;
  //   } else {
  //   }
  //   setLoading(true);
  //   setSubmitted(false);
  //   if (registerNew) {
  //     dispatch(
  //       register({
  //         email,
  //         first_name: firstName,
  //         last_name: lastName,
  //         password,
  //         phone_no,
  //         country_id: countryId,
  //         callback: setLoading,
  //       })
  //     );
  //   } else {
  //     dispatch(login({ email, password, callback: setLoading }));
  //   }
  // };
  // const loginDetails = useSelector((state) => state.auth);
  const screeningTypesError = useSelector(
    (state) => state.movies.screeningTypesError
  );
  const show = useSelector((state) => state.movies.show);
  // const { loginError, loggedInUser } = loginDetails;
  // useEffect(() => {
  //   if (loggedInUser) {
  //     props.closeFunc(false);
  //   }
  // }, [loggedInUser, props]);

  // const onCloseModal = () => {
  //   setEmail("");
  //   setFirstName("");
  //   setLastName("");
  //   setPassword("");
  //   setPhone_no("");
  //   setCountryId(null);
  //   setCountryMobCode(null);
  //   props.closeFunc(false);
  //   // if (registerNew) {
  //   //   registerNew(true);
  //   // }
  // };
  const timeoutError = useSelector((state) => state.movies.timeoutError);
  const onClickSubmit = () => {
    if (show && show.movie_id && show.content_lang_id && show.category_id) {
      if (show.category_id == 1) {
        history.push(
          `/private-booking-flow/${show.movie_id}/${show.content_lang_id}`
        );
      } else if (show.category_id == 2) {
        history.push(`/now-showing-booking/${show.movie_id}/${show.content_lang_id}`);
      } else {
        history.push(`/`);
      }
    } else {
      history.push(`/`);
    }
    dispatch(setTimeoutError(false));
  };

  return (
    timeoutError && (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="login-popup timeout_popup"
        centered
        // onHide={onCloseModal}
      >
        <section className="wrapper container-fluid">
          <div className="d-flex register_tabs"></div>

          {/* <img
          src={props.register ? back : close}
          alt=""
          className="back-btn-main"
          onClick={onCloseModal}
        /> */}

          <p className="title mt-md-4 text-center">{t(timeoutError.message)}</p>
          <div className="d-flex justify-content-center">
            <button
              onClick={onClickSubmit}
              type="submit text-align-center ml-2"
              className={`btn-main mt-3 ${
                  (
                    pathname.startsWith("/new-on-demand") ||
                    pathname.startsWith("/private-booking-flow") ||
                    (
                      pathname.startsWith("/payment") &&
                      pathname.split('/')?.[3]?.startsWith('1') // eg pathname = `/payment/497732952/1#`
                    )
                  )
                  && " golden_btn_pvt "
                } ${loading && "loader-text"}`}
            >
              {t(`Try again`)}
            </button>
          </div>
        </section>
      </Modal>
    )
  );
};

export default withNamespaces()(Timeout);
