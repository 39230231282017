import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import code from "@assets/pictures/svgs/code.svg";
import poster2 from "@assets/pictures/poster-2.png";
import filtericon from "@assets/pictures/svgs/filters.svg";
import { fetchUserHistory, login } from "../../store/auth/authActions";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { sendEmail, sendSms } from "../../store/movies/movieActions";
import { withNamespaces } from "react-i18next";
import { currencyFormatter } from "../../helper/currencyFormatter";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";
import Spinner from ".././partials/Spinner";
import { ReactComponent as TicketImggold } from "@assets/pictures/svgs/ticketimggold.svg";
import { ReactComponent as TicketImg } from "@assets/pictures/svgs/ticketimg.svg";
import { movieLanguagesWithSubs } from "@helper/formatting";
import _ from "lodash";
import useIsMobile from "@src/customHooks/useIsMobile";

const UnTransactions = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const loginDetails = useSelector((state) => state.auth);
  const userHistoryLoading = useSelector(
    (state) => state.auth.userHistoryLoading
  );
  const { loggedInUser, userUnpaidHistory } = loginDetails;
  const resendSMSError = useSelector((state) => state.movies.resendSMSError);
  const resendEmailError = useSelector(
    (state) => state.movies.resendEmailError
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const [ticketIndex, setTicketIndex] = useState(null);
  const { lang_name, iso_2, lang_id } = selected_language;
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log("login :>> ", login);
  //   dispatch(
  //     login({ email: user.email, password: user.password, callback: () => {} })
  //   );
  // }, []);
  useEffect(() => {
    // if (loggedInUser?.cust_id) {
    //   dispatch(fetchUserHistory({ customer_id: loggedInUser?.cust_id }));
    // } else {
    //   history.push(`/`);
    // }
  }, [dispatch, history, loggedInUser]);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  function isGoldColor(bookingDetails, category_id) {
    let isPrivate = false;

    if(!bookingDetails || !category_id) return false

    if(bookingDetails?.booking_type_id === 2) return false

    if(bookingDetails?.booking_type_id === 3) return true

    if(bookingDetails?.booking_type_id === 1 && isPrivate) return true

    return false
  }

  function extractSeatTypes(selectedSeats) {
    return _.chain(selectedSeats)
    .groupBy("screen_seat_type_id")
    .toPairs()
    .map((x) => ({
      sst_seat_type: x[1][0].sst_seat_type,
      seats: x[1],
    }))
    .map((x) => {
      x.seats = _.chain(x.seats)
      .groupBy("rate_card_group_child_id")
      .toPairs()
      .map((x) => ({
        tt_name: x[1][0].tt_name,
        ticket_type_translations: x[1][0].ticketTypeTranslations,
        no_of_seats: x[1].length,
        single_seat_price: x[1][0].rc_total_amount,
        single_seat_net_price: x[1][0].rc_net_amount,
        total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
        seats: x[1],
      }))
      .value();
      return x;
    })
    .value();
  }

  function getTicketTypes(selectedSeatsFromTrx = []) {
    let finalResult = [];

    let seatTypes = extractSeatTypes( selectedSeatsFromTrx);

    for (let seatType of seatTypes) {
      let seats = seatType.seats.map(
        (x) =>
          `${x.no_of_seats} ${
            x?.ticket_type_translations?.[iso_2?.toLowerCase()] || x?.tt_name
          }`
      );
      finalResult.push({
        ticket_type: seatType.sst_seat_type,
        seat_types: seats,
      });
    }

    return finalResult
  }

  return (
    <div className="transactions">
      {/* <div className="header">
        <div className="d-flex align-items-center pb-1">
          <img src={filtericon} className="img-fluid" alt="" />
          <label className="pl-2 pt-2">Filters</label>
        </div>
        <Dropdown className="user-custom-dropdown">
          <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
            Select
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className="dropdownItems">
            <Dropdown.Item className="selected">All</Dropdown.Item>
            <Dropdown.Item>Coming</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      {userHistoryLoading && (
        <div className="row mt-5">
          <div className="col-12 text-center">
            <Spinner />
          </div>
        </div>
      )}
      {userUnpaidHistory?.length > 0 && (
        <div className="booked-tickets">
          {userUnpaidHistory?.map((transaction, index) => (
            <>
              {index !== 0 && <hr className="custom-border" />}
              <div className="book-ticket">
                <div className="ticket">
                  <div className={`ticket-header-profile ${isGoldColor(transaction?.Seats[0], 1) ? "back_gold_final" : ""}`}>
                    <div>
                      <Link to>
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          height="28px"
                          // className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="final_svg_top" style={{ alignItems: 'center' }}>
                      { isGoldColor(transaction?.Seats[0], 1)
                        ? (
                          <TicketImggold className="svg_ticket" style={{ marginRight: '9px' }}/>
                        ): (
                          <TicketImg style={{ marginRight: '9px', marginTop: '2px' }}/>
                        )
                      }

                      <h6 className="mb-0" style={{color: isGoldColor(transaction?.Seats[0], 1) ? "black" : "white"}}>
                        { isGoldColor(transaction?.Seats[0], 1) ? t("Private Cinema"):t("Your Ticket")}</h6>
                    </div>
                  </div>
                  <div className="header">




                    <div className="left">
                      <div className="movie-img">
                        <img
                          src={
                            getLangSpecificAttribute(
                              transaction?.Seats[0]?.languageSpecificImages,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          className="img-fluid"
                          alt="movie-img"
                        />

                        {/* <img src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1609780139976-ramprasad.jpg" className="img-fluid" /> */}
                      </div>
                      {/*<div className="movie-content">
                        <h4 className="heading modelica-bold">
                          {getLangSpecificAttribute(
                            transaction?.Seats[0]?.movieTranslations,
                            lang_id,
                            "mc_title"
                          ) || transaction?.Seats[0]?.movie_name}
                        </h4>
                        <div className="features">
                          <p>
                            {transaction?.Seats[0]?.movie_language}{" "}
                            {transaction?.Seats[0]?.movie_format}
                          </p>
                          <p>
                            {transaction?.Seats[0]?.genreTranslations?.map(
                              (genre, index) =>
                                genre[iso_2?.toLowerCase()] && (
                                  <span key={index}>
                                    {" "}
                                    {genre[iso_2?.toLowerCase()]} |
                                  </span>
                                )
                            )}{" "}
                            {transaction?.Seats[0]?.movie_rating}
                          </p>
                        </div>
                        <div className="booked-details">
                          <div>
                            <p className="fsd-18 text-success modelica-bold">
                              {transaction?.Seats[0]?.cine_name}
                            </p>
                            <p className="fsd-14">
                              {transaction?.Seats[0]?.cine_address}
                              {/* {transaction?.Seats[0]?.cine_location yaha peh tha pehley}
                            </p>
                          </div>
                          <div>
                            <p className="fsd-14">{t(`Seats Selected`)}:</p>
                            <p className="fsd-16 font-weight-bold text-break">
                              {transaction?.Seats[0]
                                ?.mycinema_booking_type_id === 3
                                ? t("entire-hall-booked")
                                : transaction?.seatsString?.join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>*/}
                      <article className="mozilla_final_mob_alignmnet transaction_sec">
                        <p className={`mb-1 final_heading ${isGoldColor(transaction?.Seats[0], 1) ? "final_heading_gold" : ""}`}>
                          {isGoldColor(transaction?.Seats[0], 1) ? t("Private Cinema") : ''}
                        </p>
                        <h1 className="movie_info_title_trans mb-2">
                          {getLangSpecificAttribute(
                            transaction?.Seats[0]?.languageSpecificImages,
                            lang_id,
                            "mc_title"
                          ) || transaction?.Seats[0]?.movie_title}
                        </h1>

                        <div className="btn_alignment">
                          {
                            !!transaction?.Seats[0]?.movie_lang_name && (
                              <button className="prof_btn mob_prof_btn">
                                {
                                  movieLanguagesWithSubs(
                                    transaction?.Seats[0]?.movie_lang_name,
                                    transaction?.Seats[0]?.sub_1_iso_2,
                                    transaction?.Seats[0]?.sub_2_iso_2,
                                  )
                                }
                              </button>
                            )
                          }
                          {/*<button className="prof_btn">
                                 136 mins
                                </button>*/}
                          {
                            !!transaction?.Seats[0]?.movie_rating && (
                              <button className="prof_btn">
                                {transaction?.Seats[0]?.movie_rating}
                              </button>
                            )
                          }
                          {
                            !!transaction?.Seats[0]?.movie_format && (
                              <button className="prof_btn">
                                {transaction?.Seats[0]?.movie_format}
                              </button>
                            )
                          }

                        </div>
                        { false &&<div
                          className="movie_info_rating_n_format justify-content-start res_final_spacing"
                          style={{ marginBottom: !isMobile ? "40px" : undefined }}
                        >
                                <span className="movie_info_language ">
                                        {(!!t(transaction?.Seats[0]?.lang_name) ? t(transaction?.Seats[0]?.lang_name) : transaction?.Seats[0]?.lang_name)
                                          || transaction?.Seats[0]?.version_name}{`${
                                  transaction?.Seats[0]?.sub_1_iso_2 ? `/${transaction?.Seats[0]?.sub_1_iso_2}` : ''
                                }${
                                  transaction?.Seats[0]?.sub_1_iso_2 && transaction?.Seats[0]?.sub_2_iso_2 ? `-${transaction?.Seats[0]?.sub_2_iso_2}` : ''
                                }`}
                                        </span>

                          {(<>

                            {
                              transaction?.Seats[0]?.duration &&
                              <button className="newtime_final ">
                                {transaction?.Seats[0]?.duration} {t("common.mins")}
                              </button>
                            }
                            { /*<span className="movie_info_language">
                                        {(!!t(transaction?.Seats[0]?.lang_name) ? t(transaction?.Seats[0]?.lang_name) : transaction?.Seats[0]?.lang_name)
                                        || transaction?.Seats[0]?.version_name}{`${
                                          transaction?.Seats[0]?.sub_1_iso_2 ? `/${transaction?.Seats[0]?.sub_1_iso_2}` : ''
                                      }${
                                          transaction?.Seats[0]?.sub_1_iso_2 && transaction?.Seats[0]?.sub_2_iso_2 ? `-${transaction?.Seats[0]?.sub_2_iso_2}` : ''
                                      }`}
                                    </span>*/}
                            <span className="movie_info_rating ">
                                    {transaction?.Seats[0]?.rating}
                                  </span>
                            <span className="movie_info_language ">
                                          {transaction?.Seats[0]?.mf_name}
                                        </span>
                          </>)
                          }

                        </div>}

                        <div className=" text-left middle-align-prop">
                          <h6 className={`final_heading pt-1
                                ${isGoldColor(transaction?.Seats[0], 1) ? "gold_final_text" : ""}`} >
                            {t("Date & Time")}</h6>
                          <p className="final_para text-uppercase">
                            {
                              moment(transaction?.Seats[0]?.show_date).format("DD MMM YYYY")
                            } | {
                            moment(transaction?.Seats[0]?.ss_start_show_time,
                              "HH:mm"
                            ).format("HH:mm")}{" "}

                          </p>
                          {/*
                                    transaction?.Seats[0]?.booking_type_id === 3 && (<>
                                    <img src={Diamond} alt="diamond" />
                                    </>)*/}

                          {/*<h6 className="">{t("Genre")}</h6>
                                  <p/>
                        {!!(transaction?.Seats[0]?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                    &&
                                    <p>
                                    { (<>
                                          {transaction?.Seats[0]?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || transaction?.Seats[0]?.g_name}
                                        </>)}
                                              </p>}*/}
                        </div>
                        <div className="text-left">
                          <h6 className={`final_heading 
                                  ${isGoldColor(transaction?.Seats[0], 1) ? "gold_final_text" : ""}`}>
                            {t("Cinema")}
                          </h6>
                          <p/>
                          <p className="final_para" >
                            {transaction?.Seats[0]?.cine_name},{" "}
                            {transaction?.Seats[0]?.cine_address}
                            {/* {transaction?.Seats[0]?.cine_location} */}
                          </p>
                        </div>
                        {/* <div className="features">
                                <p>
                                  {
                                    transaction?.Seats[0]?.is_version_apply
                                        ? <>{transaction?.Seats[0].version_name}{" "}
                                          {transaction?.Seats[0]?.mf_name}</>
                                        : <>{transaction?.Seats[0]?.lang_name}{" "}
                                          {transaction?.Seats[0]?.mf_name}</>
                                  }
                                </p>
                                <p>
                                  {
                                    !!(transaction?.Seats[0]?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                      ? (<>
                                          {transaction?.Seats[0]?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || transaction?.Seats[0]?.g_name}{" "}
                                          | {transaction?.Seats[0]?.rating}
                                        </>)
                                      : (<>
                                          {transaction?.Seats[0]?.rating}
                                        </>)
                                  }
                                </p>
                              </div>
                              <div className="cinema-name">
                                <p className="fs-16 d-none d-md-block">
                                  {t("Cinema")}:
                                </p>
                                <p className="font-weight-bold">
                                  {transaction?.Seats[0]?.cine_name},{" "}
                                  {transaction?.Seats[0]?.cine_address}
                                   {transaction?.Seats[0]?.cine_location}
                                </p>
                              </div> */}

                      </article>
                    </div>
                    <div className="right">
                      {/* <div class="py-2 d-flex align-items-center">
                        <div className="date-day d-flex align-items-center pr-3">
                          <h3 className="modelica-bold mb-0">
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("DD")}
                          </h3>
                          <p className="fsd-14 pl-2">
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("MMMM")}{" "}
                            <br />{" "}
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("dddd")}
                          </p>
                        </div>
                        <div className="date-time pl-3">
                          <h3 className="modelica-bold mb-0 pr-4">
                            {moment(
                              transaction?.Seats[0]?.ss_start_show_time,
                              "HH:mm"
                            ).format("HH:mm")}{" "}
                          </h3>
                          <div>
                            {/* {moment(
                              transaction?.Seats[0]?.ss_actual_start_date_time
                            ).format("A")} //The comment was closed here
                          </div>
                        </div>
                      </div>*/}
                      <div className="ticket_text trans_ticket">

                        <div className={`final_heading final_heading_mob ${isGoldColor(transaction?.Seats[0], 1) ? "gold_final_text" : ""}`}>{t("Reservation ID")}:
                        </div>

                        <div className="final_para final_para_mob">
                          {transaction?.Seats[0]?.reservation_id}
                        </div>


                      </div>
                      <div className=" pt-1 text-center">
                        {!transaction?.Seats[0]?.t_refund_date_time_stamp && (
                          <>

                            {/* <img src={code} className="img-fluid" alt="" /> */}
                            <div className="">
                              {/* trans-barcode */}
                              {/* <Barcode
                              value={transaction?.Seats[0]?.sb_booking_code}
                            /> */}
                              {transaction?.reservation_id && (
                                <QRCode
                                  value={transaction?.reservation_id}
                                  className="mx-auto qr-border"
                                />

                              )}
                            </div>

                          </>
                        )}
                      </div>
                      <p className="text-grey fs-12 pb-2" style={{textAlign:"center"}}>
                        {t(`QR Code`)}
                      </p>
                    </div>
                  </div>
                  {/* {transaction?.Seats[0]?.fnb_items?.length > 0 && (*/}

                  {/*auditorium saal section */}
                  <div className="top_prop">

                    <div className="profile_tic_heading">
                      {t("Auditorium")}
                      <p className="para_profile">
                        {transaction?.Seats[0]?.screen_name}
                      </p>
                    </div>

                    <div className="profile_tic_heading">
                      {t("Selected Seats")}
                      <p className="para_profile">
                        {transaction?.Seats[0]
                          ?.mycinema_booking_type_id === 3
                          ? t("entire-hall-booked")
                          : transaction?.seatsString?.join(", ")
                        }
                      </p>
                    </div>

                    {transaction?.Seats[0]?.mycinema_booking_type_id !== 3 && (
                      <div className="profile_tic_heading">
                        {t("Ticket Type")}
                        <p className="para_profile">{
                          getTicketTypes(transaction?.Seats[0]?.selectedSeats).map(
                            seatType => {
                              // console.log({seatType})
                              return (
                                <div>
                            <span className="">
                              {t(seatType.ticket_type)}&nbsp;
                              {!isMobile && "-"}&nbsp;
                            </span>
                                  {seatType.seat_types.map(
                                    (item, index) => (
                                      <span
                                        className={`${
                                          isMobile ? "d-block" : ""
                                        }`}
                                      >
                            {item}
                                        {seatType.seat_types.length -
                                          1 !==
                                          index && ", "}
                          </span>
                                    )
                                  )}
                                </div>
                              )
                            }
                          )
                        }</p>
                      </div>
                    )}
                  </div>

                  {/* My profile ticket bottom fnb section*/}
                  <div className="ticket_bottom_profile">
                    <div className="mob_span_profile">

                      <div >
                        <div className="profile_tic_heading mob_pad">
                          {t("BOOKING / FOOD & BEVERAGE SUMMARY")}
                        </div>

                        {
                          transaction?.Seats[0]?.booking_type_id !== 3 &&
                          extractSeatTypes(transaction?.Seats[0]?.selectedSeats || [])?.length > 0 &&
                          extractSeatTypes(transaction?.Seats[0]?.selectedSeats || [])?.map((seatType, seatTypeIndex) => (
                            <React.Fragment key={seatTypeIndex}>
                              {seatType?.seats?.length > 0 && seatType?.seats?.map((rcg_child, index2) => (
                                <article className="main_trans_footer my-1" key={index2}>
                                  <div className="fnb_profile">

                                    <p className="prop_p mob_prop_p">
                                      <span>(x{rcg_child.no_of_seats} {t("Seats")})</span>
                                      <span> {seatType.sst_seat_type}</span>
                                      <span className=""> {rcg_child?.ticket_type_translations?.[iso_2?.toLowerCase()] || rcg_child.tt_name}</span>
                                    </p>
                                    <p className="prop_p">
                                      <span> {curr_code}{" "}{currencyFormatter(rcg_child?.total_price)}</span>
                                    </p>
                                  </div>
                                  <div>
                                  </div>
                                </article>
                              ))}
                            </React.Fragment>
                          ))
                        }

                        {/*FnB for non private booking */}
                        {
                          transaction?.Seats[0]?.booking_type_id !== 3 &&
                          transaction?.Seats[0]?.fnb_items &&
                          transaction?.Seats[0]?.fnb_items.length > 0 &&
                          transaction?.Seats[0]?.fnb_items.map((item) => (
                            <>
                              {/*<p className="">{t("FnB")}:</p>*/}
                              <article className="main_trans_footer my-1">
                                <div className="fnb_profile">

                                  <p className="prop_p mob_prop_p">
                                    <span>(x{item.fst_quantity})</span>
                                    <span> {getLangSpecificAttribute(
                                      item?.FnbContent,
                                      lang_id,
                                      "item_lang_name"
                                    ) || item.item_name}
                                </span>

                                  </p>
                                  <p className="prop_p">
                          <span> {curr_code}{" "}
                            {currencyFormatter(item.total_price)}</span>
                                  </p>
                                </div>
                                {/* <div>
                              <p className="">x{item.fst_quantity}</p>
                           </div>*/}
                                {/*<div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.fp_total_amount)}
                              </p>
                            </div>*/}
                                <div>

                                </div>
                              </article>
                            </>
                          ))}

                        {/*private booking fnb*/}
                        {
                          transaction?.Seats[0]?.private_rate_card_detail &&
                          transaction?.Seats[0]?.private_rate_card_detail?.length > 0 &&
                          (
                            <article className="main_trans_footer my-1">
                              <div className="fnb_profile">

                                <p className="prop_p mob_prop_p">
                                  <span>{t("entire-hall-booked")}</span>
                                  <span></span>
                                </p>
                                <p className="prop_p">
                                    <span> {curr_code}{" "}
                                      {currencyFormatter(
                                        (transaction?.Seats[0]?.private_rate_card_detail?.[0]?.customSeatCount || 0) *
                                        (transaction?.Seats[0]?.private_rate_card_detail[0]?.slab?.[0]?.price || 0)
                                      )}</span>
                                </p>
                              </div>
                              <div>
                              </div>
                            </article>
                          )
                        }
                        {
                          transaction?.Seats[0]?.private_rate_card_detail?.[0]?.fnb &&
                          transaction?.Seats[0]?.private_rate_card_detail?.[0]?.fnb.length > 0 &&
                          transaction?.Seats[0]?.private_rate_card_detail?.[0].fnb.map((item, index) => (
                            <article className="main_trans_footer my-1" key={index}>
                              <div className="fnb_profile">

                                <p className="prop_p mob_prop_p">
                                  <span>x{item.quantity}</span>
                                  <span> {item.item_name}</span>

                                </p>
                                <p className="prop_p">
                                    <span> {curr_code}{" "}
                                      {currencyFormatter(
                                        item.price *
                                        transaction?.Seats[0]?.private_rate_card_detail?.[0]?.customSeatCount
                                      )}</span>
                                </p>
                              </div>
                              <div>
                              </div>
                            </article>
                          ))}
                        {
                          transaction?.Seats[0]?.private_rate_card_detail?.[0]?.addon &&
                          transaction?.Seats[0]?.private_rate_card_detail?.[0]?.addon.length > 0 &&
                          transaction?.Seats[0]?.private_rate_card_detail?.[0].addon.map((item, index) => (
                            <article className="main_trans_footer my-1" key={index}>
                              <div className="fnb_profile">

                                <p className="prop_p mob_prop_p">
                                  <span>x{item.quantity}</span>
                                  <span> {item.item_name}</span>

                                </p>
                                <p className="prop_p">
                                    <span> {curr_code}{" "}
                                      {currencyFormatter(item.price * item.quantity)}</span>
                                </p>
                              </div>
                              <div>
                              </div>
                            </article>
                          ))}

                      </div>
                    </div>

                    <div >
                      <p className="profile_tic_heading mob_pad">{t(`Payment Method`)}:</p>
                      <p className="my-1 med mob_text">
                        {t("Unpaid Booking")}
                      </p>
                    </div>

                    <div style={{ paddingLeft: isMobile ? "20px" : undefined }}>
                      <p className="profile_tic_heading mob_pad">{t(`Total Amount To Paid`)}</p>
                      <p className="my-1 med mob_text">
                        {curr_code}{" "}
                        {currencyFormatter(
                            parseFloat(
                              (transaction?.Seats[0]?.amount || 0)
                            )
                        )}
                      </p>
                    </div>
                  </div>


                  {false && <div className="concessions">
                    {/* <p className="comma-separated">
                        {transaction?.Seats[0]?.fnb_items.map((item) => (
                          <span>
                            {item?.fst_quantity}{" "}
                            {getLangSpecificAttribute(
                              item?.FnbContent,
                              lang_id,
                              "item_lang_name"
                            ) || item.item_name}
                          </span>
                        ))}
                      </p>

                  )} */}
                    {transaction?.Seats[0]?.fnb_items &&
                      transaction?.Seats[0]?.fnb_items.length > 0 &&
                      transaction?.Seats[0]?.fnb_items.map((item) => (
                        <>
                          {/*<p className="">{t("FnB")}:</p>*/}
                          <article className="main_trans_footer">
                            <div className="">
                              <p className="">
                                <p className=" ">x{item.fst_quantity}</p>
                                {getLangSpecificAttribute(
                                  item?.FnbContent,
                                  lang_id,
                                  "item_lang_name"
                                ) || item.item_name}
                              </p>
                            </div>
                            {/* <div>
                              <p className="">x{item.fst_quantity}</p>
                           </div>*/}
                            {/*<div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.fp_total_amount)}
                              </p>
                            </div>*/}
                            <div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.total_price)}
                              </p>
                            </div>
                          </article>
                        </>
                      ))}
                  </div>}




                  { false && !!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.slab
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].slab.map(
                          (item) => (
                            <>
                              <p className="">{t("Tickets")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{"Private booking"}</p>
                                </div>
                                <div>
                                  <p className="">
                                    x
                                    {
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount
                                    }
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code} {currencyFormatter(item.price)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  {false && !!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.fnb
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].fnb.map(
                          (item) => (
                            <>
                              <p className="">{t("Concessions")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{item.item_name}</p>
                                </div>
                                <div>
                                  <p className="">
                                    x
                                    {
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount
                                    }
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code} {currencyFormatter(item.price)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  {false && !!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.addon
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].addon.map(
                          (item) => (
                            <>
                              <p className="">{t("Add Ons")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{item.item_name}</p>
                                </div>
                                <div>
                                  <p className="">x{item.quantity}</p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code} {currencyFormatter(item.price)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      item.quantity * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  { false && <div className="footer">
                    <div>
                      <p>{t(`Booking Date & Time`)}:</p>
                      <p>
                        {" "}
                        {moment(
                          transaction?.Seats[0]?.transaction_date_time ||
                          transaction?.Seats[0]?.booking_date
                        ).format(
                          "ll"
                        )}{" "}
                        |{" "}
                        {moment(
                          transaction?.Seats[0]?.transaction_date_time ||
                          transaction?.Seats[0]?.booking_date
                        ).format(
                          "HH:mm"
                        )}{" "}
                      </p>
                    </div>
                    <div>
                      <p>{t(`Payment Method`)}:</p>
                      <p>
                        {transaction?.Seats[0]?.payment_mode ||
                          transaction?.Seats[0]?.stripeDetails?.card ||
                          "Debit Card"}
                      </p>
                    </div>
                    {transaction?.Seats[0]?.voucher_code && (
                      <div>
                        <p>{t(`Voucher Code`)}:</p>
                        <p>{transaction?.Seats[0]?.voucher_code}</p>
                      </div>
                    )}
                    {transaction?.Seats[0]?.t_refund_date_time_stamp && (
                      <div>
                        <p>{t(`Status`)}:</p>
                        <p>{t("Refunded")}</p>
                      </div>
                    )}
                    {transaction?.Seats[0]?.rr_reason && (
                      <div>
                        <p>{t(`Refund Reason`)}:</p>
                        <p>{t(transaction?.Seats[0]?.rr_reason)}</p>
                      </div>
                    )}
                    <div>
                      <p>{t(`Ticket ID`)}:</p>
                      <p>{transaction?.Seats[0]?.sb_booking_code}</p>
                    </div>
                    <div>
                      <p>{t(`Screening Type`)}:</p>
                      <p>{t(transaction?.Seats[0]?.screening_name)}</p>
                    </div>
                    <div>
                      <p>{t(`Total Amount Paid`)}</p>
                      <p>
                        {curr_code}{" "}
                        {currencyFormatter(
                          parseFloat(
                            transaction?.Seats[0]?.sb_total_amount +
                            transaction?.Seats[0]?.internet_handling_fee +
                            (transaction?.Seats[0]?.ft_total_amount || 0) -
                            transaction?.Seats[0].offer_price
                          )
                        )}
                      </p>
                    </div>

                    {transaction?.Seats[0].ss_actual_start_date_time >
                      moment().valueOf() && (
                        <div className="footer-actions">
                          {(loading.email || loading.sms) &&
                          index === ticketIndex ? (
                            <Spinner animation="border" />
                          ) : (
                            <>
                              <p className="text-primary fs-12">
                              <span
                                style={{ cursor: "pointer" }}
                                className="loader-text"
                                onClick={() => {
                                  !resendEmailError && setTicketIndex(index);
                                  setLoading({ ...loading, email: true });
                                  dispatch(
                                    sendEmail({
                                      sb_booking_code:
                                      transaction?.Seats[0].sb_booking_code,
                                    })
                                  ).then((response) => {
                                    setLoading({ ...loading, email: false });
                                  });
                                }}
                              >
                                {t(`Resend Email`)}
                              </span>{" "}
                                |{" "}
                                {/* | {loading.email ? <Spinner size="sm" /> : null} */}
                                <span
                                  style={{ cursor: "pointer" }}
                                  className={` ${loading.sms && "loader-text"}`}
                                  onClick={() => {
                                    !resendSMSError && setTicketIndex(index);
                                    setLoading({ ...loading, sms: true });
                                    dispatch(
                                      sendSms({
                                        sb_booking_code:
                                        transaction?.Seats[0].sb_booking_code,
                                      })
                                    ).then((response) => {
                                      setLoading({ ...loading, sms: false });
                                    });
                                  }}
                                >
                                {t(`Resend M-ticket`)}
                              </span>{" "}
                                {/* {loading.sms ? <Spinner size="sm" /> : null} */}
                              </p>
                              {resendSMSError && (
                                <p className="error mt-2 mb-0">
                                  {resendSMSError}
                                </p>
                              )}
                              {resendEmailError && (
                                <p className="error mt-2 mb-0">
                                  {resendEmailError}
                                </p>
                              )}
                              <p></p>
                            </>
                          )}
                        </div>
                      )}
                  </div>}
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {/* <div className="booked-tickets d-flex justify-content-center align-items-center"> */}
      {!userHistoryLoading && userUnpaidHistory?.length === 0 && (
        <p className="text-center my-5 py-5">{t(`No Transactions Found`)}</p>
      )}
      {/* </div> */}
    </div>
  );
};

export default withNamespaces()(UnTransactions);
