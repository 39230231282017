import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useIsMobile from "../../customHooks/useIsMobile";

// Components
import { Card, Accordion } from "react-bootstrap";
import CashCardInfo from "./helper/CashCardInfo";
import CashCardStepOne from "./helper/CashCardStepOne";
import GiftCardStepTwo from "./helper/CashCardStepTwo";
import GoBack from "@components/partials/GoBack";
import { useDispatch, useSelector } from "react-redux";
import { fetchCashCardGallery, fetchWebsiteCashCardByUniqueId } from "../../store/cashCard/cashCardActions";
import Spinner from "../partials/Spinner";
import {
  SET_CASHCARD_BUY_AMOUNT,
  SET_CASHCARD_BUY_QUANTITY,
  SET_CASHCARD_NAME_TO_PRINT
} from "../../store/cashCard/cashCardTypes";

const CashCardDetailsContainer = ({ t }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();

  let { w_cash_card_unique_id } = useParams();
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;


  const [activeAccordionTab, setActiveAccordionTab] = useState([true, false, false]);
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState("0");

  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = [false, false, false];
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  useEffect(() => { window.scrollTo(0, 0) }, []);
  useEffect(() => {
    if (w_cash_card_unique_id)
      dispatch(fetchWebsiteCashCardByUniqueId(w_cash_card_unique_id));
  }, [w_cash_card_unique_id]);

  const websiteCashCard = useSelector(s => s.cashCard.websiteCashCard) ?? [];
  const websiteCashCardLoading = useSelector(s => s.cashCard.websiteCashCardLoading) ?? false;
  const cashCardBuySelection = useSelector((state) => state.cashCard.cashCardBuySelection);


  useEffect(() => {
    return () => {
      // dispatch to reset: amount qantity and name
      dispatch({ type: SET_CASHCARD_BUY_QUANTITY, payload: 1 })
      dispatch({ type: SET_CASHCARD_BUY_AMOUNT, payload: 0 })
      dispatch({ type: SET_CASHCARD_NAME_TO_PRINT, payload: "" })
    }
  }, [])


  useEffect(()=> {
    if (isMobile && activeAccordionTabIndex == 0) {
      handleAccordionTab(1)
    }
  }, [isMobile])

  if (websiteCashCardLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  return (
    <>
      <div className="booking_flow_container">
        {isMobile ?
          // MOBILE DESIGN
          <>
            <Accordion
              activeKey={activeAccordionTabIndex}
              className="booking_accordion">
              {/* STEP ONE ~ GIFT CARD DETAILS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey="0"
                  onClick={() => {
                    handleAccordionTab(0);
                  }}
                  className={`${activeAccordionTab[0] ? "active" : ""}`}
                >
                  <article>
                    <h2>
                      {/* <span>1.</span> */}
                      {t("Cash Cards")} {t("Details")}
                    </h2>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <article className="booking_accordion_step">
                    <CashCardInfo onClickNext={() => handleAccordionTab(1)} />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP TWO ~ SELECT A VALUE */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey="1"
                  onClick={() => {
                    handleAccordionTab(1);
                  }}
                  className={`${activeAccordionTab[1] ? "active" : ""}`}
                >
                  <article>
                    <><h2>
                      <span>1.</span> Select a value
                    </h2>
                    </>
                    <>
                      <p></p>
                    </>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <article className="booking_accordion_step">
                    <CashCardStepOne
                      onClickNext={() => handleAccordionTab(2)}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP THREE ~ ENTER RECIPIENT DETAILS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey="2"
                  onClick={() => {
                    handleAccordionTab(2);
                  }}
                  className={`${activeAccordionTab[2] ? "active" : ""}`}>
                  <article>
                    <>
                      <h2>
                        <span>2.</span> Enter recipient details
                      </h2></>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <article className="booking_accordion_step">
                    <GiftCardStepTwo onClickNext={() => handleAccordionTab(3)} isPrivate={true} />
                  </article>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
          :
          // DESKTOP/TABLET DESIGN
          <section className="row">
            <article className="col-md-4 col-xl-3 col-lg-4 pl-md-0" id="movie_info">
              <CashCardInfo />
            </article>

            <article className="col-md-8 col-xl-9 col-lg-8 pr-md-0">
              <section className="inner_pages_header">
                <GoBack
                  onClick={() => {
                    if ((+activeAccordionTabIndex) === 0)
                      history.go(-1);
                    else if ((+activeAccordionTabIndex) > 0) {
                      handleAccordionTab(+activeAccordionTabIndex - 1);
                    }
                  }}
                />
                {/*<div className="custom_breadcrumbs">
                <span onClick={() => history.push("/cash-cards")}>
                  {t("Cash Cards")}</span> <span>
                  {t("Cash Cards")} {t("Details")}</span>
                </div>*/}
                <div className="custom_breadcrumbs" >
                  <div className="steps_new">
                      <span
                        className="bor_new1">
                        {t("Cash Cards")}
                        </span>
                    <span className="mx-3 bor_new">{t("Cash Cards")} {t("Details")}</span>
                    <span
                        className=" bor_new1">
                        {t("Checkout")}
                        </span>
                  </div>
                </div>
                <div />
              </section>

              <Accordion
                activeKey={activeAccordionTabIndex}
                className="booking_accordion"
              >
                {/* STEP ONE ~ SELECT A VALUE */}
                <Card>
                  <Accordion.Toggle
                    as={Card.Header} eventKey={activeAccordionTab[0]}
                    onClick={() => {
                      handleAccordionTab(0);
                    }}
                    className={`${activeAccordionTab[0] ? "active" : ""}`}
                  >
                    <article>
                      <h2>
                        <span>1.</span> {t("Select a value")}
                      </h2>
                      {(+activeAccordionTabIndex !== 0 || activeAccordionTabIndex == null) && (<>
                        <p>| {curr_code} {cashCardBuySelection?.amount} (X {cashCardBuySelection?.quantity})  </p>
                      </>)}
                    </article>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">

                    <article className="booking_accordion_step">
                      <CashCardStepOne
                        isPrivate={true}
                        onClickNext={() => handleAccordionTab(1)}
                      />
                    </article>
                  </Accordion.Collapse>
                </Card>

                {/* STEP TWO ~ ENTER RECIPIENT DETAILS */}
                <Card>
                  <Accordion.Toggle
                    as={Card.Header} eventKey="1"
                    onClick={() => {
                      handleAccordionTab(1);
                    }}
                    className={`${activeAccordionTab[1] ? "active" : ""}`}>
                    <article>
                      <h2>
                        <span>2.</span> {t('Enter recipient details')}
                      </h2>
                    </article>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="1">
                    <article className="booking_accordion_step">
                      <GiftCardStepTwo
                        isPrivate={true}
                        onClickNext={() => {
                          handleAccordionTab(2);
                        }}
                      />
                    </article>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </article>
          </section>
        }
      </div>
    </>
  );
};

export default withNamespaces()(CashCardDetailsContainer);
