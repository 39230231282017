import React from "react";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { motion } from "framer-motion";
import useIsMobile from "@src/customHooks/useIsMobile";
// Images
import {ReactComponent as Screen} from "@assets/pictures/svgs/screen_new.svg"
import {ReactComponent as Profile} from "@assets/pictures/svgs/profile_new.svg"
import {ReactComponent as NextArrow} from "@assets/pictures/svgs/next_circled_arrow.svg"

const OnDemandInfo = ({ t }) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    return (<>
        <section className="row" style={{justifyContent: isMobile ? "center" : undefined}}>
            <article className="col-12">
                <motion.div className="on_demand_info"
                initial={{  opacity: 0 }}
      animate={{  opacity: 1 }}
      transition={{ duration: 0.5 }}>
                    {/*<div className="d-flex flex-column align-items-center" style={{display: isMobile ? "none" : undefined}}>
                        <Screen className="on_demand_info_screen" style={{display: isMobile ? "none" : undefined}}/>
                        <Profile className="on_demand_info_profile" style={{display: isMobile ? "none" : undefined}}/>
    </div>*/}
                    <div className="on_demand_text_wrapper flex-column flex-md-row">
                        <h2 className={isMobile ? undefined : "mr-4"}>{t('On-demand')}
                        {/* This dot is part of the design */}
                        {/* <span>&nbsp;.&nbsp;</span> */}
                        </h2>
                    <h6>
                        {t('Just pick the movie you like from the large library and Book the entire cinema right now just for you, family and friends.')}
                    </h6>
                    </div>
                    <NextArrow className="on_demand_info_next_arrow" onClick={()=>history.push("/new-on-demand") } style={{display: isMobile ? "none" : undefined}}/>
                </motion.div>
            </article>
    </section>
    </>);
}
 
export default withNamespaces()(OnDemandInfo);
