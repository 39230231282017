import React from "react";
import { withNamespaces } from "react-i18next";

// Components
import CountryCodeDropdown from "@components/partials/CountryCodeDropdown";
import showPasswordIcon from "@assets/pictures/svgs/show-password.svg";
import hidePasswordIcon from "@assets/pictures/svgs/hide-password.svg";
import qrCodeIcon from "@assets/pictures/svgs/camera.svg";


const CustomInput = (props) => {
  const {
    t,
    classes = "",
    onChange,
    value,
    type,
    textarea,
    autoComplete,
    autoFocus,
    label,
    subLabel,
    errorStatus,
    errorMsg,
    placeholder,
    countryCode,
    onSelectCountry = () => {},
    forPassword = false,
    showLoginPassword = false,
    setShowLoginPassword = () => {},
    forCashCardNumber = false,
    showCashCardPinScanner = false,
    setshowCashCardPinScanner = () => {},
  } = props;

  React.useEffect(() => {}, [props])

    return (
        <div className={`custom_input position-relative ${classes}`}>
            <label>
                <p>
                    {label}
                    {subLabel && <span>({subLabel})</span>}
                </p>
                {textarea
                  ? <textarea rows="5" value={value} onChange={onChange} placeholder={placeholder} style={{paddingTop:"10px",resize:"none"}}/>
                  :
                    <>
                        <div className="d-flex">
                         {countryCode && <CountryCodeDropdown className="mr-2" onSelectCountry={onSelectCountry} />}
                            <input type={type} autoComplete={autoComplete} autoFocus={autoFocus} value={value} onChange={onChange} placeholder={placeholder} autocomplete="new-password" />
                        </div>
                    </>}
              {!!forPassword && !showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  style={{ top: '30px' }}
                  src={showPasswordIcon}
                  onClick={() => setShowLoginPassword(!showLoginPassword)}
                />
              )}
              {!!forPassword && !!showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  style={{ top: '30px' }}
                  src={hidePasswordIcon}
                  onClick={() => setShowLoginPassword(!showLoginPassword)}
                />
              )}
              {!!forCashCardNumber && (
                <img
                  title={t('Scan QR Code')}
                  alt=""
                  width="25"
                  height="25"
                  className="show-password-icon"
                  src={qrCodeIcon}
                  onClick={() => { setshowCashCardPinScanner(!showCashCardPinScanner) }}
                />
              )}
            </label>
           {errorStatus && <p className="error">{errorMsg}</p>}
        </div>
     );
}

export default withNamespaces()(CustomInput);
