import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CashCards from "./helper/CashCardsGallery";
import HowItWorks from "./helper/HowItWorks";
import CheckBalance from "./helper/CheckBalance";
import TopupCard from "./helper/TopupCard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchCashCardGallery } from "../../store/cashCard/cashCardActions";
import useIsMobile from "@src/customHooks/useIsMobile.js";

let activeTabMap = {
  1: "Cash Cards",
  2: "How It Works",
  3: "Check Balance",
  4: "Top-up",
}

const CashCardsContainer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { active } = useParams()

  const [activeTitle, setActiveTitle] = useState("Cash Cards");
  const isMobile = useIsMobile();
  useEffect(() => {
    dispatch(fetchCashCardGallery());
  }, []);

  useEffect(() => {
    if (active) {
      setActiveTitle(activeTabMap[active])
    }
  }, [active])

  return (
    <>
      <div className="container-fluid gift_cards_container">
        <div className="gift_cards_title">
          <h1>{t(activeTitle)}</h1>
          <article style={{marginTop: isMobile ? "10px" : "20px"}}>
            <p
              onClick={(e) => setActiveTitle("Cash Cards")}
              className={`${activeTitle === "Cash Cards" ? "active" : ""}`}
            >
              {t("Cash Cards")}
            </p>

            <p
              onClick={(e) => setActiveTitle("How It Works")}
              className={`${activeTitle === "How It Works" ? "active" : ""}`}
            >
              {t("How It Works")}
            </p>

            <p
              onClick={(e) => setActiveTitle("Check Balance")}
              className={`${activeTitle === "Check Balance" ? "active" : ""}`}
            >
              {t("Check Balance")}
            </p>

            <p
              onClick={(e) => setActiveTitle("Top-up")}
              className={`${activeTitle === "Top-up" ? "active" : ""}`}
            >
              {t("Top-up")}
            </p>
          </article>
        </div>
        {
          activeTitle === "Cash Cards"
            ? <CashCards />
            : activeTitle === "How It Works"
              ? <HowItWorks />
              : activeTitle === "Check Balance"
                ? <CheckBalance />
                : activeTitle === "Top-up"
                  ? <TopupCard />
                  : null
        }
      </div>
    </>
  );
};

export default withNamespaces()(CashCardsContainer);
