import {
  FETCH_LABELED_ONDEMAND_MOVIES,
  FETCH_FORMAT_LIST,
  FETCH_FORMAT_LIST_SUCCESS,
  FETCH_FORMAT_LIST_ERROR,
  FETCH_LANGUAGE_LIST,
  FETCH_LANGUAGE_LIST_SUCCESS,
  FETCH_LANGUAGE_LIST_ERROR,
  SEARCH_MOVIE_SUCCESS,
  SEARCH_MOVIE_ERROR,
  SEARCH_MOVIE,
  QUICK_BOOK,
  QUICK_BOOK_MOVIE_SUCCESS,
  QUICK_BOOK_MOVIE_ERROR,
  QUICK_BOOK_MOVIE,
  QUICK_BOOK_LANG_SUCCESS,
  QUICK_BOOK_FORMAT_SUCCESS,
  QUICK_BOOK_CINEMA_SUCCESS,
  QUICK_BOOK_DATE_SUCCESS,
  QUICK_BOOK_TIME_SUCCESS,
  QUICK_BOOK_ERROR,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_ERROR,
  SET_FILTER,
  SET_QB_STAGE,
  SET_SELECTED_MINI_FESTIVAL,
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_ERROR,
  SET_ON_DEMAND_CMS,
  SET_SOCIALMEDIA_CMS,
  SET_CMS_SETTINGS,
  SET_HEADERS_CMS,
  SET_FOOTER_CMS,
  SET_MOVIE_CMS,
  FETCH_LABELED_MOVIES
} from "./homepageTypes";
const _ = require("lodash");

let initialState = {
  todaysScreenings: null,
  todaysScreeningsLabeledMovies: [],
  todaysScreeningsLoading: false,
  todaysScreeningsLastPage: null,
  todaysScreeningsError: null,
  createOwnShow: null,
  onDemandLabeledMovies: [],
  createOwnShowLastPage: null,
  createOwnShowLoading: false,
  createOwnShowError: null,
  comingSoon: null,
  comingSoonLoading: false,
  comingSoonLastPage: null,
  comingSoonError: null,
  formatList: [],
  formatListLoading: false,
  formatListError: null,
  languageList: [],
  languageListLoading: false,
  languageListError: null,
  festivalList: [],
  festivalListLoading: false,
  festivalListError: null,
  searchMovies: [],
  searchMoviesLoading: false,
  searchMoviesError: null,
  quickBookMovies: [],
  quickBookLanguages: [],
  quickBookFormats: [],
  quickBookCinemas: [],
  quickBookDates: [],
  quickBookTimes: [],
  filters: [],
  qbStage: 0,
  miniFestivals: null,
  miniFestivalsLoading: false,
  miniFestivalsError: null,
  selectedMiniFestival: null,
  cmsData: [],
  cmsLoader: false,
  onDemand_cms: [],
  cms_settings: [],
  social_media_cms: [],
  headers_cms: [],
  footer_cms: [],
  movie_cms: [],
};
const homepageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_LABELED_MOVIES:
      let sortedArray = _.sortBy(payload, (e) => e[0].order)
      return {
        ...state,
        todaysScreeningsLabeledMovies: sortedArray,
        todaysScreeningsLoading: false,
      };

    case FETCH_LABELED_ONDEMAND_MOVIES: {
      let sortedArray = _.sortBy(payload, (e) => e[0].order)
      return {
        ...state,
        onDemandLabeledMovies: sortedArray,
        createOwnShowLoading: false,
      };
    }
    case FETCH_FORMAT_LIST:
      return {
        ...state,
        formatList: [],
        formatListLoading: true,
      };
    case FETCH_FORMAT_LIST_SUCCESS:
      return {
        ...state,
        formatList: payload,
        formatListLoading: false,
      };
    case FETCH_FORMAT_LIST_ERROR:
      return { ...state, formatList: [], formatListError: payload };
    case FETCH_LANGUAGE_LIST:
      return {
        ...state,
        languageList: [],
        languageListLoading: true,
      };
    case FETCH_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        languageList: payload,
        languageListLoading: false,
      };
    case FETCH_LANGUAGE_LIST_ERROR:
      return { ...state, languageList: [], languageListError: payload };
    case SEARCH_MOVIE:
      return {
        ...state,
        searchMovies: null,
        searchMoviesLoading: true,
      };

    case SEARCH_MOVIE_SUCCESS:
      return {
        ...state,
        searchMovies: payload,
        searchMoviesLoading: false,
      };
    case SEARCH_MOVIE_ERROR:
      return { ...state, searchMovies: [], searchMoviesError: payload };
    case QUICK_BOOK_MOVIE:
      return { ...state, quickBookMovies: [] };
    case QUICK_BOOK_MOVIE_ERROR:
      return { ...state, quickBookMovies: [] };
    case QUICK_BOOK_MOVIE_SUCCESS:
      return {
        ...state,
        quickBookMovies: payload,
      };
    case QUICK_BOOK:
      return {
        ...state,
        quickBookMovies: [],
        quickBookLanguages: [],
        quickBookFormats: [],
        quickBookCinemas: [],
        quickBookDates: [],
        quickBookTimes: [],
      };
    case QUICK_BOOK_ERROR:
      return {
        ...state,
        quickBookMovies: [],
        quickBookLanguages: [],
        quickBookFormats: [],
        quickBookCinemas: [],
        quickBookDates: [],
        quickBookTimes: [],
      };

    case QUICK_BOOK_LANG_SUCCESS:
      return {
        ...state,
        quickBookLanguages: payload,
      };
    case QUICK_BOOK_FORMAT_SUCCESS:
      return {
        ...state,
        quickBookFormats: payload,
      };
    case QUICK_BOOK_CINEMA_SUCCESS:
      return {
        ...state,
        quickBookCinemas: payload,
      };
    case QUICK_BOOK_DATE_SUCCESS:
      return {
        ...state,
        quickBookDates: payload,
      };
    case QUICK_BOOK_TIME_SUCCESS:
      return {
        ...state,
        quickBookTimes: payload,
      };
    case SET_QB_STAGE:
      return {
        ...state,
        qbStage: payload,
      };
    case SET_FILTER:
      let newFilters = state.filters;
      newFilters[payload.index].typeArray.map((x) => (x.selected = false));
      newFilters[payload.index].typeArray[payload.typeIndex].selected = true;
      return {
        ...state,
        filters: newFilters,
      };
    case FETCH_FILTERS:
      return {
        ...state,
        filters: [],
      };
    case FETCH_FILTERS_ERROR:
      return {
        ...state,
        filters: [],
      };
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filters: payload,
      };
    case SET_SELECTED_MINI_FESTIVAL:
      return {
        ...state,
        selectedMiniFestival: payload,
      };
    case FETCH_CMS:
      return {
        ...state,
        cmsData: [],
        cmsLoader: true,
      };
    case FETCH_CMS_SUCCESS:
      return {
        ...state,
        cmsData: payload,
        cmsLoader: false,
      };
    case FETCH_CMS_ERROR:
      return {
        ...state,
        cmsData: [],
        cmsLoader: false,
      };
    case SET_CMS_SETTINGS:
      return {
        ...state,
        cms_settings: payload,
      };
    case SET_ON_DEMAND_CMS:
      return {
        ...state,
        onDemand_cms: payload,
      };
    case SET_SOCIALMEDIA_CMS:
      return {
        ...state,
        social_media_cms: payload,
      };
    case SET_HEADERS_CMS:
      return {
        ...state,
        headers_cms: payload,
      };
    case SET_FOOTER_CMS:
      return {
        ...state,
        footer_cms: payload,
      };
    case SET_MOVIE_CMS:
      return {
        ...state,
        movie_cms: payload,
      };
    default:
      return state;
  }
};

export default homepageReducer;
