import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import Slider from "react-slick";
import BreadCrumb from "@components/partials/BreadCrumbs";

// Images
import seatsImg from "@assets/pictures/about-us.png";

const EventsAndAppetisers = (props) => {
  const { t, classes = "" } = props;
  const [swiping, setSwiping] = useState(false);

  const handleSwipe = () => {
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <>
      <section className=" event_and_appetisers_wrapper static_page align_new">
        <div className="left_right_align">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Our Event and Appetizers")} />
        <CenteredTitle firstHalf={t("Our Event and Appetizers")} secondHalf="" />
        </div>
      
        {/* <div className="row">
                    <div className="col-12 px-0">
                        <Slider {...heroSlider}>
                    {Array(10).fill(0).map((item, index) => (
                        <article className="col" key={index}>
                            <div className="event_appetiser">
                                <figure>
                                    <img src={seatsImg} alt="" />
                                </figure>
                                <h6>
                                    Event {index + 1} Name
                                </h6>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </article>
                    ))}
                </Slider>
                    </div>
                </div> */}
        <article className="row">
          <div className="col-xl-12 mx-auto event_back">
            <p className="static_page_highlight mb-4 mb-xl-5">
              {t("Events.Header", { cinema: "Kino Stüssihof" })}
            </p>
            <p className="mt-4 mt-xl-5">
              {t("Events.info1")}
            </p>
            <p>
              {t("Events.info2")}
            </p>
            <p className="mb-4 mb-xl-5">
              {t("Events.info3")}
            </p>
            <p className="text-primary">
              {t("Whether you")}...
            </p>
            <p className="mb-4 mb-xl-5">
              {t("Events.events")}
            </p>
            <p className="text-primary">
              ...{t("want to organise")}
            </p>
            <p>
              {t("Events.info4")}
            </p>
            <p>
              {t("Events.info5")}
            </p>
            <p className="mb-4 mb-xl-5">
              {t("Events.info6")}
            </p>
            <p className="mt-4 mt-xl-5">
              {t("Telephone")}:044 296 90 78
            </p>
            <p>
               <span className="text-primary"> zoe@stuessihof.ch </span>
            </p>
          </div>
        </article>
      </section>
    </>
  );
};

export default withNamespaces()(EventsAndAppetisers);
