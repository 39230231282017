import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  ListGroup,
  Alert,
  Form,
  Button,
  Modal,
  Row,
  Container,
  Col,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import MovieFooter from "./partials/movieFooter";
import close from "@assets/pictures/svgs/close.svg";
import infoCircle from "@assets/pictures/svgs/info-circle.svg";
import {ReactComponent as ScreenSl} from "@assets/pictures/svgs/screen-sl.svg";
import standardSeat from "@assets/pictures/svgs/seat-standard.svg";
import loungerSeat from "@assets/pictures/svgs/seat-lounger.svg";
import selectedLoungerSeat from "@assets/pictures/svgs/seat-lounger-selected.svg";
import unavailableLoungerSeat from "@assets/pictures/svgs/seat-lounger-unavailable.svg";
import selectedSeat from "@assets/pictures/svgs/seat-standard-selected.svg";
import unavailableSeat from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import cinemaBanner from "@assets/pictures/cinema-banner.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchSeatLayout,
  selectSeats,
  clearSeatSelection,
  reserveSeats,
  cinemaReserveSeat,
  releaseSeat,
} from "../store/seatLayout/seatLayoutActions";
import {
  onSetCompleteRateCard,
  onSetCompleteNewTotalAmount,
  fetchScheduleDetails,
  fetchStripePublisherKey,
  fetchScreeningTypes,
  resetTimer,
} from "../store/movies/movieActions";
import {
  clearSelectedVoucher,
  fetchVouchers,
} from "../store/promotions/promotionsActions";
import { clearFnbState } from "../store/foodAndBeverages/foodAndBeveragesActions";
import { useParams } from "react-router";
import { slImageOnhover } from "../helper/formatting.js";
import Spinner from "./partials/Spinner";
import { ReactComponent as ArrowBack } from "@assets/pictures/svgs/arrow-back.svg";
import { ReactComponent as ArrowBackActive } from "@assets/pictures/svgs/arrow-back-active.svg";
import useIsMobile from "../customHooks/useIsMobile";
import { currencyFormatter } from "../helper/currencyFormatter";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";

const SeatLayout = ({ t }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [seats, setSeats] = useState([]);
  const [userSelectedSeatsNew, setUserSelectedSeatsNew] = useState([]);
  const [back, setBack] = useState(false);
  const movieDetails = useSelector((state) => state.movies.movieDetails2);
  const isFromMoviePage = useSelector((state) => state.movies.fromMoviePage);
  const {
    screen_id,
    ss_id,
    md_id,
    // category_id,
    gogo_ss_id,
    booking_type_id,
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
    mycinema_ss_d_id,
  } = useParams();
  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservation_id = useSelector(
    (state) => state.seatLayout.reservation_id
  );
  const category_id = 1;
  useEffect(() => {
    dispatch(clearFnbState());
    if (reservation_id) {
      dispatch(releaseSeat({ reservation_id }));
    }
  }, []);

  useEffect(() => {
    dispatch(clearSelectedVoucher());
    dispatch(fetchSeatLayout({ mycinema_ss_d_id }));
    console.log("ss_id :>> ", ss_id);
    dispatch(
      fetchScheduleDetails({
        // ss_id: category_id === 1 ? ss_id : gogo_ss_id,
        // category_id,
        mycinema_ss_d_id,
        booking_type_id,
      })
    );
  }, []);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const [specialRequest, setSpecialRequest] = useState("");
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_kiosk_screen_image_url,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const seatLayout = useSelector((state) => state.seatLayout.seatLayout);
  const seatLayoutLoading = useSelector(
    (state) => state.seatLayout.seatLayoutLoading
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  useEffect(() => {
    if (category_id == 1) {
      dispatch(fetchScreeningTypes({ mycinema_ss_d_id, payload: 3 }));
    }
  }, []);
  const [minSeatsCompulsory, setMinSeatsCompulsory] = useState(null);
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  useEffect(() => {
    if (booking_type_id) {
      if (screeningTypes && category_id == 1 && booking_type_id == 1) {
        const x = screeningTypes.find((type) => type.booking_type_id === 1);
        if (x) {
          setNumberOfSeats(x.min_seats);
          setMinSeatsCompulsory(x.min_seats);
        }
      }
    }
  }, [screeningTypes]);

  useEffect(() => {
    if (category_id == 1 && booking_type_id == 2) {
      setNumberOfSeats(undefined);
    }
  }, [category_id, booking_type_id]);

  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const [modalShow, setModalShow] = useState(false);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalBtnMessage, setShowModalBtnMsg] = useState("");

  const onSelectSeats = (seatTypeIndex, SeatTypes, numberOfSeats) => {
    const { sl_seat_name } = SeatTypes;
    dispatch(
      selectSeats({
        seatTypeIndex,
        SeatTypes,
        numberOfSeats,
        booking_type_id,
        category_id,
      })
    );
    // dispatch(fetchVouchers({ seat: SeatTypes }));
    const newSeats = [...seats];
    if (newSeats.indexOf(sl_seat_name) > -1) {
      newSeats.splice(newSeats.indexOf(sl_seat_name), 1);
    } else {
      newSeats.push(sl_seat_name);
    }
    setSeats(newSeats);
    setUserSelectedSeatsNew(SeatTypes);
  };

  const getSeatClass = (status, singleSeat) => {
    if (singleSeat.sst_seat_type === "Wheelchair") {
      return "cell wheel-chair";
    } else if (status === 3) {
      return "cell wheel-chair";
    } else if (singleSeat.sst_seat_type === "Sofa") {
      return "cell lounger";
    }
    // else if (singleSeat.sst_seat_type === "Standard" || singleSeat.sst_seat_type === "Normal" || singleSeat.sst_seat_type === "Balkon") {
    // }
    else {
      if (singleSeat.covidBlocked || singleSeat.covidBlockedTemp) {
        return "cell seat covid-blocked";
      } else {
        return "cell seat";
      }
      // return "cell";
    }
  };

  const onClickNumberOfSeatsSelector = (index) => {
    setNumberOfSeats(index);
    settotalSeatBooked(0);
    dispatch(clearSeatSelection());
  };
  const [totalSeatBooked, settotalSeatBooked] = useState(0);
  let totalAmountPrice = useRef(0);
  let totalNetAmout = useRef(0);
  let seatsSelectedRateGroupNew = useRef([]);
  const [rateCardGroups, setrateCardGroups] = useState([]);

  const rateCardGroupsFunction = useCallback(() => {
    if (selected_seats.length > 0) {
      let array = [];
      let checkCheckoutRateCardNeeded = 0;
      settotalSeatBooked(0);
      totalAmountPrice.current = 0;
      totalNetAmout.current = 0;
      selected_seats.map((x) => {
        let find_In = array.findIndex((new1) => new1.sst_id === x.sst_id);

        if (find_In >= 0) {
          let defaultRateCardIndex = (
            array[find_In]["rate_card_group"] || []
          ).findIndex((x) => x?.default_rate_card === "Y");
          defaultRateCardIndex =
            defaultRateCardIndex < 0 ? 0 : defaultRateCardIndex;

          array[find_In]["AllSeats"].push(x);

          // if (array[find_In]["rate_card_group"].length == 1) {
          array[find_In]["totalSelected"] = array[find_In]["totalSelected"] + 1;

          array[find_In]["rate_card_group"][
            defaultRateCardIndex
          ].totalSeatSelected =
            array[find_In]["rate_card_group"][defaultRateCardIndex]
              .totalSeatSelected + 1;

          totalAmountPrice.current =
            parseFloat(totalAmountPrice.current) +
            parseFloat(
              array[find_In]["rate_card_group"][defaultRateCardIndex]
                .rc_total_amount
            );

          totalNetAmout.current =
            parseFloat(totalNetAmout.current) +
            parseFloat(
              array[find_In]["rate_card_group"][defaultRateCardIndex]
                .rc_net_amount
            );

          settotalSeatBooked((prev) => prev + 1);
          // } else if
          if (array[find_In]["rate_card_group"].length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
        } else {
          let arrayCardGroup = [];
          x.rate_card_group.map((rg) => {
            rg["totalSeatSelected"] = 0;
            arrayCardGroup.push(rg);
          });
          let obj = {
            srt_name: x.srt_name,
            sst_seat_type: x.sst_seat_type,
            sst_id: x.sst_id,
            AllSeats: [x],
            totalSelected: 0,
            rate_card_group: arrayCardGroup,
          };
          let defaultRateCardIndex = (obj["rate_card_group"] || []).findIndex(
            (x) => x?.default_rate_card === "Y"
          );
          defaultRateCardIndex =
            defaultRateCardIndex < 0 ? 0 : defaultRateCardIndex;

          // if (arrayCardGroup.length == 1) {
          obj["totalSelected"] = 1;
          obj["rate_card_group"][defaultRateCardIndex].totalSeatSelected = 1;
          totalAmountPrice.current =
            parseFloat(totalAmountPrice.current) +
            parseFloat(
              obj["rate_card_group"][defaultRateCardIndex].rc_total_amount
            );
          totalNetAmout.current =
            parseFloat(totalNetAmout.current) +
            parseFloat(
              obj["rate_card_group"][defaultRateCardIndex].rc_net_amount
            );
          settotalSeatBooked((prev) => prev + 1);
          // } else if
          if (arrayCardGroup.length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }

          array.push(obj);
        }
      });
      seatsSelectedRateGroupNew.current = array;
      console.log(
        "🚀 ~ file: SeatLayout.js ~ line 259 ~ rateCardGroupsFunction ~ seatsSelectedRateGroupNew",
        seatsSelectedRateGroupNew
      );

      if (
        checkCheckoutRateCardNeeded == 0
        // &&
        // array[0]["rate_card_group"].length > 0
      ) {
        dispatch(
          onSetCompleteRateCard({
            status: true,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
        dispatch(
          onSetCompleteNewTotalAmount({
            totalAmount: totalAmountPrice.current,
            netAmount: totalNetAmout.current,
          })
        );
      } else if (checkCheckoutRateCardNeeded > 0) {
        dispatch(
          onSetCompleteRateCard({
            status: false,
            seatGroup: seatsSelectedRateGroupNew.current,
          })
        );
      }
      setrateCardGroups(array);
    } else {
      seatsSelectedRateGroupNew.current = [];
    }
  }, [selected_seats]);

  const history = useHistory();
  const totalCount = (rg, index, rg_index, type) => {
    console.log("seatsSelectedRateGroupNew", seatsSelectedRateGroupNew.current);
    let x =
      seatsSelectedRateGroupNew.current[index]["rate_card_group"][rg_index];
    if (
      type == "increment" &&
      seatsSelectedRateGroupNew.current[index].totalSelected ==
        seatsSelectedRateGroupNew.current[index].AllSeats.length
    ) {
      return false;
    }

    if (
      type == "increment" &&
      totalSeatBooked + 1 <= selected_seats.length &&
      x["totalSeatSelected"] <= selected_seats.length
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] + 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected + 1;
    } else if (
      seatsSelectedRateGroupNew.current[index].totalSelected >= 1 &&
      type == "decrement" &&
      x["totalSeatSelected"] >= 1
    ) {
      x["totalSeatSelected"] = x["totalSeatSelected"] - 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected - 1;
    }
    let totalAmount = 0;
    let totalSeatBooked2 = 0;
    let totalNetAmout2 = 0;
    seatsSelectedRateGroupNew.current.map((z) => {
      z.rate_card_group.map((y) => {
        totalAmount =
          parseFloat(totalAmount) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_total_amount);

        totalNetAmout2 =
          parseFloat(totalNetAmout2) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_net_amount);
      });

      totalSeatBooked2 = parseInt(totalSeatBooked2) + parseInt(z.totalSelected);
    });
    totalAmountPrice.current = totalAmount;

    settotalSeatBooked(totalSeatBooked2);
    totalNetAmout.current = totalNetAmout2;
    x["tt_name"] = x["tt_name"] + " ";
    if (totalSeatBooked2 == selected_seats.length) {
      dispatch(
        onSetCompleteRateCard({
          status: true,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
      dispatch(
        onSetCompleteNewTotalAmount({
          totalAmount: totalAmountPrice.current,
          netAmount: totalNetAmout.current,
        })
      );
    } else {
      dispatch(
        onSetCompleteRateCard({
          status: false,
          seatGroup: seatsSelectedRateGroupNew.current,
        })
      );
    }
  };
  useEffect(() => {
    rateCardGroupsFunction();
  }, [selected_seats]);
  const [reserveSeatError, setReserveSeatError] = useState(null);
  const onProceedRateCardGroup = async () => {
    setLoader(true);
    dispatch(
      cinemaReserveSeat({
        selected_seats,
        mycinema_ss_d_id,
        history,
        cinema_id: scheduleDetails.cinema_id,
        seatGroup: seatsSelectedRateGroupNew.current,
        booking_type_id,
        gogo_booking_type_id: booking_type,
        category_id,
        callback: setLoader,
        screen_id,
        ss_id,
        md_id,
        closeModal: () => setModalShow(false),
        specialRequest,
        genre_id,
        movie_id,
        content_lang_id,
        isPrivate,
        setReserveSeatError,
        setShowModal,
        setModalMessage,
      })
    );
  };
  // category_id == 2
  // ? reserveSeats({
  //   selected_seats,
  //   mycinema_ss_d_id,
  //   history,
  //   cinema_id: scheduleDetails.cinema_id,
  //   seatGroup: seatsSelectedRateGroupNew.current,
  //   gogo_ss_id: scheduleDetails.gogo_ss_id,
  //   gogo_booking_type_id: scheduleDetails.gogo_booking_type_id,
  //   category_id,
  //   callback: setLoader,
  //   screen_id,
  //   ss_id,
  //   md_id,
  //   closeModal: () => setModalShow(false),
  //   specialRequest,
  //   genre_id,
  //   movie_id,
  //   content_lang_id,
  //   isPrivate,
  // })
  // :
  useEffect(() => {
    dispatch(clearSeatSelection());
    window.scrollTo(0, 0);
    dispatch(resetTimer());
  }, []);

  useEffect(slImageOnhover);

  // if (!isFromMoviePage) {
  //   history.replace(`/movie-details/${movie_id}/${content_lang_id}`);
  // }

  const seatLayoutRef = useRef();

  const [widthOfWindow, setWidthOfWindow] = useState(window.innerWidth);
  
  useEffect(() => {
     const resetWidthOfWindow = () => {
        setWidthOfWindow(window.innerWidth);
      }
      window.addEventListener("resize", resetWidthOfWindow)
    return ()=>window.removeEventListener("resize", resetWidthOfWindow)
  }, [])


  useEffect(() => {
    if (seatLayout) {
      let maxSeatsInARow = 0;
      seatLayout.forEach((seatType) => {
          if (seatType && seatType.seatsFinal) {
            seatType.seatsFinal.forEach((seatsRow) => {
            if (seatsRow.length && seatsRow.length > maxSeatsInARow) {
          maxSeatsInARow = seatsRow.length;
        }
          })
        }
      })
      console.log({ maxSeatsInARow });


      if (seatLayoutRef.current) {
        const cells = seatLayoutRef.current.querySelectorAll(".cell");
        cells.forEach((cell) => {
          let cellSize = ""
          if (widthOfWindow > 767 && widthOfWindow < 992) {
            if (maxSeatsInARow > 10) {
              cellSize = "24";
              if (cell.classList.contains("lounger")) {
                cellSize -= 2;
              }
               cell.style.width = cellSize + "px";
              cell.style.height = cellSize + "px";
          }    
          } else if (widthOfWindow < 767) {
            if (maxSeatsInARow > 10) {
              cellSize = seatLayoutRef.current.clientWidth / maxSeatsInARow;
               if (cell.classList.contains("lounger")) {
                cellSize -= 3;
              }
              cell.style.width = cellSize + "px";
              cell.style.height = cellSize + "px";
            }
          } else {
             cell.style.width = "";
              cell.style.height = "";
          }
          
           
        })
}
    }
  }, [seatLayout, seatLayoutRef.current, widthOfWindow])

  return (
    <div>
      {/* <SeatTypePopup show={modalShow} onHide={() => setModalShow(false)} />
       */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        className="seat-type-popup"
        centered
        show={modalShow}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => setModalShow(!modalShow)}
            alt=""
          />

          <div className="row title-row">
            <div className="col-12 mb-3 text-center">
              <p className="title">{t("Specify seat type")}</p>
            </div>
            <div className="col-12 text-center">
              <div className="selected-seats">
                {t("Tickets Selected")} {totalSeatBooked}/
                {selected_seats.length}
              </div>
            </div>
          </div>
          <div className="seat-picker">
            <div className="seat-picker-row headings">
              <div>
                <p>{t("Seat Type")}</p>
              </div>
              <div>
                <p>{t("No. of Seats")}</p>
              </div>
              <div>
                <p>{t("Price")}</p>
              </div>
            </div>
            {rateCardGroups?.length > 0 &&
              rateCardGroups?.map((item, index) => (
                <>
                  <div className="seat-type-cat">{item?.sst_seat_type}</div>

                  {item?.rate_card_group?.map((rg, rg_index) => (
                    <div className="seat-picker-row" key={rg_index}>
                      <div>
                        <p>
                          {rg?.ticketTypeTranslations?.[iso_2?.toLowerCase()] ||
                            rg.tt_name}
                        </p>
                        <p className="pb-1">
                          {curr_code} {currencyFormatter(rg.rc_total_amount)}
                        </p>
                      </div>
                      <div className="modifier">
                        <span
                          className={
                            rg.totalSeatSelected == 0 ? "inactive" : ""
                          }
                          onClick={() =>
                            totalCount(rg, index, rg_index, "decrement")
                          }
                          style={{alignItems: isMobile ? "baseline" : "flex-end" }}
                        >
                          -
                        </span>
                        <span
                          className={`${
                            rg.totalSeatSelected == "0" ? "inactive" : ""
                          }`}
                        >
                          {rg.totalSeatSelected}
                        </span>
                        <span
                          className={
                            item.totalSelected == item.AllSeats.length
                              ? "inactive"
                              : ""
                          }
                          onClick={() =>
                            totalCount(rg, index, rg_index, "increment")
                          }
                        >
                          +
                        </span>
                      </div>
                      <div>
                        <p>
                          {curr_code}{" "}
                          {currencyFormatter(
                            rg.rc_total_amount * rg.totalSeatSelected
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            <div className="seat-picker-row total">
              <div className="">
                <p>{t("Total")}</p>
              </div>
              <div>
                <p>
                  {curr_code} {currencyFormatter(totalAmountPrice.current)}
                </p>
              </div>
            </div>
          </div>

          {false && (
            <>
              <div className="row">
                <div className="px-3 question">
                  <p>{t("Have a special request or instruction")}</p>
                </div>
              </div>

              <div className="row">
                <div className="px-3 w-100">
                  <input
                    type="text"
                    className="answer"
                    placeholder={t("Type Here...")}
                    value={specialRequest}
                    onChange={(e) => setSpecialRequest(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          {reserveSeatError && <p className="error">{reserveSeatError}</p>}
          {/* {totalSeatBooked === selected_seats.length && ( */}
          <button
            className={`btn-main btn-main-lg mx-auto ${
              loader && "loader-text"
            } ${totalSeatBooked === selected_seats.length ? "" : "inactive"}`}
            onClick={() =>
              totalSeatBooked == selected_seats.length &&
              onProceedRateCardGroup()
            }
          >
            {t("Confirm")}
          </button>
          {/* )} */}
        </section>
      </Modal>

      <div className="container-fluid container-xl header-space">
        {category_id === "1" && (
          <section className="row justify-content-center for-prev-step">
            <div className="prev-step">
              <button
                className="btn-main mx-auto mt-3 mt-xl-4"
                onClick={() => history.go(-1)}
                onMouseOver={() => setBack(true)}
                onMouseOut={() => setBack(false)}
              >
                {/* <img
                  src={back ? arrowBackActive : arrowBack}
                  className="mr3"
                  alt=""
                /> */}
                {/* Back */}
                {back ? <ArrowBackActive /> : <ArrowBack />}
              </button>
            </div>

            <div className="col-sm-9 mx-auto">
              <div className="steps">
                <div className={`step ticket complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step screen complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step seat complete`}>
                  {/* <img src={ticketRed} /> */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
        )}

        <section className="row">
          <div className="px-3 mx-auto">
            <div className="seat-selection-heading">
              {/* {(!minSeatsCompulsory) && <p className="">{t("How many Seats")}</p>} */}
              {minSeatsCompulsory && (
                <p className="">
                  {t("Min.")} {numberOfSeats} {t("seats required")}
                </p>
              )}
            </div>
          </div>
        </section>

        <section
          className={`row ${!minSeatsCompulsory ? "mb-4" : ""} for-prev-step`}
        >
          <div className="prev-step">
            <button
              className="btn-main mt-xl-1"
              onClick={() => history.go(-1)}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
              {/* Back */}
              {back ? <ArrowBackActive /> : <ArrowBack />}
            </button>
          </div>
          {/* <div className="col mx-auto">
            <div
              className={`seat-selection-row list-inline ${
                minSeatsCompulsory && "seat-disabled"
              }`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                <div className="list-inline-item" key={index}>
                  <div
                    className={
                      numberOfSeats === index
                        ? "seat-selection-btn selected"
                        : "seat-selection-btn"
                    }
                    onClick={() => onClickNumberOfSeatsSelector(index)}
                  >
                    {index}
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </section>
        {/* <section className="row">
          <div className="px-3 mx-auto">
            <div className="seat-layout-screen">
              <img src={screenSl} className="img-fluid" />
            </div>
          </div>
        </section> */}
        {seatLayoutLoading ? (
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="row">
              <div className="col-12">
                <div className="seat-layout-screen text-center mx-auto">
                  {/* <img
                    src={org_kiosk_screen_image_url || screenSl}
                    className="img-fluid"
                    alt=""
                  /> */}
                    <ScreenSl/>
                  <span>{t("All eyes this way")}</span>
                </div>
                <div
                  className={`table-responsive table-width ${
                    numberOfSeats === 0 ? "seat-disabled" : ""
                      }`}
                    ref={seatLayoutRef}
                >
                  <table className="master-table table table-borderless">
                    {seatLayout?.map((seatTypes, seatTypeIndex) => (
                      <>
                        <tr key={seatTypeIndex} className="table_category">
                          <td>
                            <tr>
                              {!isMobile && (
                                <article className="table_category_info">
                                  <div className="category-price">
                                    <p>{seatTypes?.seatType}</p>
                                    <p>
                                      {curr_code}{" "}
                                      {currencyFormatter(seatTypes?.seat_price)}
                                    </p>
                                  </div>

                                  <div className="category-range"></div>
                                </article>
                              )}
                              <td>
                                {isMobile && (
                                  <div className="seat-type">
                                    <p className="">
                                      {seatTypes?.seatType}
                                      {seatTypes?.screen_seat_type_url && (
                                        <span className="hover-image-span">
                                          <img
                                            src={infoCircle}
                                            id="hover-image"
                                          />
                                          <div className="seat-type-image">
                                            <img
                                              src={
                                                seatTypes?.screen_seat_type_url
                                              }
                                            />
                                          </div>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                                <table className="actual-table">
                                  <tr>
                                  <td>
                                      <table>
                                        {seatTypes?.seatsFinal.map(
                                          (singleRow, rowIndex) => (
                                            <tr key={rowIndex} className="seat-row">
                                              {singleRow.map(
                                                (singleSeat, seatIndex) =>
                                                  singleSeat.srt_id == 2 ||
                                                  singleSeat.srt_id === 12 ||
                                                  singleSeat.srt_id === 13 ? (
                                                    <td>
                                                      <div
                                                        className="cell"
                                                        key={seatIndex}
                                                      ></div>
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className={`${
                                                        singleSeat.sst_seat_type ===
                                                        "Sofa"
                                                          ? "lounger-container"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div
                                                        key={seatIndex}
                                                        className={`${getSeatClass(
                                                          singleSeat.sl_seat_status,
                                                          singleSeat
                                                        )} ${
                                                          singleSeat.SeatSelected
                                                            ? "selected"
                                                            : singleSeat.seat_reserve_type_id ==
                                                              `8`
                                                            ? "unavailable"
                                                            : ""
                                                        } 
                                    `}
                                                        //     ${false &&
                                                        //     singleSeat.Status ===
                                                        //     4
                                                        //       ? ""
                                                        //       : "seat"
                                                        //   }
                                                        // ${false &&
                                                        //   singleSeat.covidBlocked &&
                                                        //   singleSeat.covidBlocked ===
                                                        //     true
                                                        //     ? "covid-blocked"
                                                        //     : ""
                                                        // }
                                                        // className="cell seat"
                                                        onClick={() =>
                                                          singleSeat.seat_reserve_type_id !=
                                                          `8`
                                                            ? onSelectSeats(
                                                                seatIndex,
                                                                singleSeat,
                                                                numberOfSeats
                                                              )
                                                            : null
                                                        }
                                                      ></div>
                                                      {/* {seatIndex} */}
                                                    </td>
                                                  )
                                              )}
                                            </tr>
                                          )
                                        )}
                                      </table>
                                    </td>

                                    {/* Sample */}
                                    {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr className="seat-row">
                                    {Array(20).fill(1).map(() => (
                                      <td className=""><div className="cell seat"></div></td>
                                    ))}
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                                    {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr>
                                    <td className=""><div className="cell">{index}</div></td>
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                                  </tr>
                                  {/* <tr>
                              <td colSpan="3">
                                <p className="text-center">
                                  {seatTypes?.seatType}
                                </p>
                              </td>
                            </tr>*/}
                                </table>
                              </td>
                            </tr>
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan="3">
                            <div className="seat-type">
                              <p className="">
                                {seatTypes?.seatType}
                                {seatTypes?.screen_seat_type_url && (
                                  <span className="hover-image-span">
                                    <img src={infoCircle} id="hover-image" />
                                    <div className="seat-type-image">
                                      <img
                                        src={seatTypes?.screen_seat_type_url}
                                      />
                                    </div>
                                  </span>
                                )}
                              </p>
                            </div>
                          </td>
                        </tr> */}
                      </>
                    ))}
                  </table>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="px-3 seat-layout-icons movie-footer-space">
                <p className="">
                  <figure className="">
                    <img src={standardSeat} className="mr-3" alt="" />
                    {/* <img src={loungerSeat} className="mr-3" alt="" /> */}
                  </figure>
                  {t("Available")}
                </p>
                <p className="">
                  <figure>
                    <img src={unavailableSeat} className="mr-3" alt="" />
                    {/* <img src={unavailableLoungerSeat} className="mr-3" alt="" /> */}
                  </figure>
                  {t("Unavailable")}
                </p>
                <p>
                  <figure>
                    <img src={selectedSeat} className="mr-3" alt="" />
                    {/* <img src={selectedLoungerSeat} className="mr-3" alt="" /> */}
                  </figure>
                  {t("Selected")}
                  {seats.length > 0 && `(${seats?.length})`}
                </p>
              </div>
            </section>

            {/* {selected_seats.length > 0 &&
              selected_seats.length === numberOfSeats && ( */}
            {/* <button
              className={`btn-main mx-auto ${
                selected_seats.length > 0 &&
                selected_seats.length === numberOfSeats
                  ? ""
                  : "inactive"
              }`}
              onClick={() => {
                setModalShow(true);
                rateCardGroupsFunction();
              }}
            >
              {t("Confirm")}
            </button> */}
            {/* )} */}
          </>
        )}
      </div>
      <MovieFooter
        movie_title={
          getLangSpecificAttribute(
            scheduleDetails?.altMovieContent,
            lang_id,
            "mc_title"
          ) || scheduleDetails?.movie_title || scheduleDetails?.original_title_of_movie
        }
        image={
          getLangSpecificAttribute(
            scheduleDetails?.languageSpecificImages,
            lang_id,
            "artwork"
          ) || dummyPoster
        }
        lang={
          scheduleDetails?.is_version_apply
            ? scheduleDetails?.version_name ?? t(scheduleDetails?.lang_name)
            : scheduleDetails?.lang_translation_obj?.[iso_2?.toLowerCase()] ??
              t(scheduleDetails?.lang_name)
        }
        format={scheduleDetails?.mf_name}
        rating={scheduleDetails?.rating}
        genre={
          scheduleDetails?.genres_with_translations
            ?.map((g) => g[iso_2?.toLowerCase()])
            .filter((x) => x)
            .splice(0, 13)
            .join(", ") ||
          scheduleDetails?.g_name
        }
        cinemaName={scheduleDetails?.cine_name}
        cine_location={scheduleDetails?.cine_location}
        cine_address={scheduleDetails?.cine_address}
        date={scheduleDetails?.ss_start_date}
        time={scheduleDetails?.ss_start_show_time}
        screeningType={
          booking_type_id == 1
            ? "Confirmed Screening"
            : booking_type_id == 2
            ? "Crowdsourced Screening"
            : booking_type_id == 3
            ? "Private Screening"
            : null
        }
        category_id={category_id}
        loader={loader}
        onClickMethod={() => {
          setModalShow(true);
          rateCardGroupsFunction();
        }}
        enabled={
          selected_seats.length > 0 &&
          (numberOfSeats === undefined ||
            selected_seats.length >= numberOfSeats)
        }
        showConfirmButton={true}
      />

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="login-popup"
        centered
        // onHide={onCloseModal}
      >
        <section className="wrapper container-fluid">
          <div className="d-flex register_tabs"></div>

          <p className="title mt-4 text-center">{modalMessage}</p>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setShowModal(false)}
              type="submit text-align-center ml-2"
              className={`btn-main mt-3`}
            >
              {modalBtnMessage ? modalBtnMessage : t(`Try again`)}
            </button>
          </div>
        </section>
      </Modal>
    </div>
  );
};

// const SeatTypePopup = () => {
//   return (

//   );
// };

export default withNamespaces()(SeatLayout);
