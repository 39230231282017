import MovieService from "@apiService/MovieService";
import CinemaService from "@apiService/cinemaService";
import GenericService from "@apiService/GenericService";

import {
  FETCH_AUDITORIUM,
  FETCH_AUDITORIUM_SUCCESS,
  FETCH_AUDITORIUM_ERROR,
  FETCH_AUDI_SEAT_LAYOUT,
  FETCH_AUDI_SEAT_LAYOUT_ERROR,
  FETCH_AUDI_SEAT_LAYOUT_SUCCESS,
  FETCH_OFFERS,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_ERROR,
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_ERROR,
  FETCH_PAGE,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_ERROR,
  SET_SEARCHED_QUERY
} from "./ancilliaryTypes";

import { groupBy } from "lodash";

export const fetchAuditoriums = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_AUDITORIUM });
    const { data } = await MovieService.GetAuditoriums(payload);
    console.log("data fetchAuditoriums", data);
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_AUDITORIUM_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_AUDITORIUM_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_AUDITORIUM_ERROR, payload: error?.message });
  }
};

export const fetchAudiSeatLayout = ({ screen_id, callback }) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_AUDI_SEAT_LAYOUT });
    const { data } = await MovieService.GetAudiSeatLayout({ screen_id });
    console.log("data seatlayout", data);
    if (data.status && data.Records.screen_layouts.length > 0) {
      let seatTypes = [];
      let screenSeatTypes = [];
      let seatName = [];
      let seats = [];
      let screenDetails =
        data.Records["screenDetails"] && data.Records.screenDetails.length > 0
          ? data.Records.screenDetails[0]
          : screenDetails;

      let seatData_1 = groupBy(data.Records.screen_layouts, "sl_row_num");
      for (let key in seatData_1) {
        let count33 = 0;
        if (seatData_1.hasOwnProperty(key)) {
          let seatData_2 = seatData_1[key].map((singleSeat) => {
            singleSeat["seatName"] = singleSeat.sl_seat_name;

            var answer = singleSeat.sl_seat_name;
            answer = answer.replace(/[^a-z]/gi, "");

            let indexNew = seatName.indexOf(answer);
            if (indexNew < 0 && answer) {
              seatName.push(answer);
              count33++;
            }

            seatTypes.map((x) => {
              if (x.value == singleSeat.seat_reserve_type_id) {
                singleSeat["seatTypeImageClass"] = x.imageClass;
                singleSeat["seatType"] = x;
              }
              screenSeatTypes.map((y) => {
                if (y.sst_id == singleSeat.screen_seat_type_id) {
                  singleSeat["screenSeatType"] = y;
                }
              });
            });
            return singleSeat;
          });
          if (count33 == 0) {
            seatName.push("XXX-" + key);
          }
          seats.push(seatData_2);
        }
      }
      let Obj = {
        seatTypes,
        screenSeatTypes,
        seatName,
        seats,
        screenDetails,
      };
      dispatch({
        type: FETCH_AUDI_SEAT_LAYOUT_SUCCESS,
        payload: Obj,
      });
    } else {
      dispatch({ type: FETCH_AUDI_SEAT_LAYOUT_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_AUDI_SEAT_LAYOUT_ERROR, payload: error?.message });
  } finally {
    callback(false);
  }
};

export const fetchOffers = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_OFFERS });
    const { data } = await MovieService.GetOffers(payload);
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_OFFERS_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_OFFERS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_OFFERS_ERROR, payload: error?.message });
  }
};

export const fetchCMS = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CMS });
    const { data } = await CinemaService.GetCMSData(payload);
    console.log("data FETCH_CMS", data);
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_CMS_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_CMS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_CMS_ERROR, payload: error?.message });
  }
};

export const fetchPages = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PAGE });
    const { data } = await GenericService.GetMsPage(payload);
    console.log("data fetchPages", data);
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_PAGE_SUCCESS,
        payload: data.Records,
      });
      console.log("Page content", data.Records);
    } else {
      dispatch({ type: FETCH_PAGE_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_PAGE_ERROR, payload: error?.message });
  }
};

export const setSearchedQuery = (payload) =>  {
    return {
      type: SET_SEARCHED_QUERY,
      payload: payload
      }
  }
