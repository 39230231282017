import React, { useState, useEffect, useCallback } from 'react';

const tabletWidth= 1199;

function useIsTablet() {
    const [tablet, setTablet] = useState(window.innerWidth > 767 && window.innerWidth < 1199);

    const resizeEventHandler = useCallback(() => {
        if(window.innerWidth > 767 && window.innerWidth < 1199)  {
            setTablet(true);
        } else {
            setTablet(false);
        }
    }, []);

    useEffect(() => {
        if(window.innerWidth > tabletWidth) {
            setTablet(false);
        } else {
            setTablet(true)
        }

        window.addEventListener("resize", resizeEventHandler);

        return () => window.removeEventListener("resize", resizeEventHandler);
    }, [setTablet]);

    return tablet;
}

export default useIsTablet;