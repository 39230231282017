import React from "react";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setStage } from "../../store/movies/movieActions";
import { motion } from "framer-motion";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { BASEURL } from "../../config";
import { checkIfURLSame } from "@helper/formatting";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";

// Images
import poster from "@assets/pictures/sample-poster-new.png";


const FestivalCard = (props) => {
    const { t, slider, onClick, classes, festival } = props;
    const isMobile = useIsMobile();
    const selected_language = useSelector(
        (state) => state.movies.selected_language
      );
      const { lang_id } = selected_language;
    
      const organization = useSelector((state) => state.movies.organizationDetails);
      const {
        org_logo_url,
        org_mycinema_logo,
        org_name,
        dummy_poster: dummyPosterDynamic,
      } = organization;
    
      const dummyPoster = window.localStorage.getItem("placeholder_image")
        ? window.localStorage.getItem("placeholder_image")
        : dummyPosterStatic;

          const {
            // category_image,
            category_link_image,
            category_link_description,
            category_link_name,
            from_date,
            to_date,
            new_dates,
            total_movies,
            category_link_url,
            language_content,
          } = festival;
    
      const history = useHistory();
      const dispatch = useDispatch();
    
    return (<>
      <motion.article className={`${slider ? "slider_spacing" : ""} ${classes}`}
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}>
            <div className="festival_card">
            {/* Image */}
          <figure
          // onClick={() =>
            //   history.push(`/mini-festivals?mini_fest_id=${festival?.unique_id}`)
            // }
            onClick={onClick}
          >
            <img
            src={category_link_image}
            alt="Event Banner"
            className="cursor-pointer"
            onError={({ currentTarget }) => {
              currentTarget.onerror = () => {} // prevents looping
              currentTarget.src = dummyPosterStatic
            }}
          />
            </figure>
            {/* Details */}
            {!isMobile && <div className="festival_card_details">
                
                    {/* <article>
                        <p>{t("Movies")}</p>
                        <p>{total_movies}</p>
                    </article>
                    <span/> */}
                     <article>
                        {/* <p>{t("Dates")}</p> */}
                        <p>{moment(festival?.from_date).format("Do MMM")} -{" "}
                {moment(festival?.to_date).format("Do MMM")}</p>
              {total_movies && <p>{total_movies} {t("Movies")}</p>}
                    </article>
            </div>}
    </div>
        </motion.article>
    </>);
}

// Default Props
FestivalCard.defaultProps = {
    img: poster,
    slider: false,
    classes: "",
    onClick: () => { },
};
 
export default withNamespaces()(FestivalCard);
