import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query";

import rootReducer from './rootReducer';
import { bannersApi } from "@store/rtk/rtk.banner";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck:
        process?.env?.NODE_ENV == "development" && false
          ? {
            warnAfter: 400,
            ignoredPaths: ["seatLayout"],
          }
          : false,
      serializableCheck:
        process?.env?.NODE_ENV == "development" && false
          ? {
            warnAfter: 400,
            // ignoredPaths: ['some.nested.path']
          }
          : false,
    })
    .concat([
      bannersApi.middleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store;
