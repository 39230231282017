import React, {useEffect, useState, useRef} from "react";
import {withNamespaces} from "react-i18next";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import { useParams } from "react-router"
import _ from 'lodash'

// Images
import {ReactComponent as LeftArrow} from "@assets/pictures/svgs/slider-arrow-left.svg"

// Components
import Slider from "react-slick";
import DayPicker from "react-day-picker";
import BookingShows from "./BookingShows";
import Button from "@components/partials/Button";
import Spinner from "@components/partials/Spinner";
import {
  createOndemandShow,
  fetchScheduleDetails, fetchScheduleDetailsBrij,
  fetchScreeningTypes, resetTimer,
  setOndemandDate,
  setOndemandLang,
  setOndemandSession
} from "@store/movies/movieActions";
import useIsMobile from "@src/customHooks/useIsMobile";
import {clearSelectedVoucher} from "@store/promotions/promotionsActions";
import {
  clearSeatSelection,
  fetchSeatLayoutBrij,
  releaseRealTimeBlockedSeats,
  setTempReservationId
} from "@store/seatLayout/seatLayoutActions";


const StepOne = (props) => {
  const {
    t,
    classes,
    onClickNext,
    selectedDate: selectedDateFromParent,
    setSelectedDate: setSelectedDateFromParent,
    unavailableDateLoader,
    isPrivate,
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  let location = useLocation();

  const { movie_id, content_lang_id, selectedParamLang, md_id: param_md_id, uid } = useParams()
  let selectedLangFromParam = decodeURIComponent(selectedParamLang)

  let userSelection = useSelector(state => state.movies.userSelection)
  const selected_language = useSelector((state) => state.movies.selected_language)
  const {lang_id, lang_name, iso_2} = selected_language

  // STATE
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(userSelection.selectedDate || moment().format('YYYY-MM-DD'));
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);
  const [selectedLangWithSubs, setSelectedLangWithSubs] = useState('All');
  const [swiping, setSwiping] = useState(false);
  const [isDayPickerUsed, setDayPickerUsed] = useState(false);
  const [calDate, changeCalDate] = useState(new Date());
  const [sessions, setSessions] = useState([]);

  const slider = useRef();
  const [dateSliderIndex, setDateSliderIndex] = useState(0);
  useEffect(() => {
    if (
      slider &&
      slider.current &&
      slider.current.slickGoTo &&
      (dateSliderIndex != undefined || dateSliderIndex != null)
    )
      slider.current.slickGoTo(dateSliderIndex);
  }, [dateSliderIndex, slider, slider.current]);

  const onChangeDate = (date) => {
    console.log('onChangeDate', { unavailableDateLoader, movieSchedulesLoading, dateMovieScheduleDetailsLoading, 'userSelection.selectedDate': userSelection.selectedDate, })

    if ((unavailableDateLoader || movieSchedulesLoading || dateMovieScheduleDetailsLoading)) {
      console.log('onChangeDate', 'still loading date')
      return;
    }
    // console.log("onChangeDate :>> ", date);
    if (swiping) {
      setSwiping(false);
      return
    }
    // console.log("new Date(date.dateFormat) :>> ", new Date(date.dateFormat));
    // onChange(new Date(date.ss_actual_start_date));
    // const {ss_actual_start_date} = date;
    dispatch(setOndemandLang(null))
    setSelectedLang(null)
    if (!selectedParamLang)
      setSelectedLangWithSubs('All')
    dispatch(setOndemandSession(null))
    setSelectedSession(null)
    changeCalDate(new Date(date.ss_actual_start_date))
    setSelectedDate(date.ss_actual_start_date);
    dispatch(setOndemandDate(date.ss_actual_start_date))
  }

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const movieLanguages = useSelector((state) => state.movies.movieLanguages);
  const movieLanguagesWithSubs = useSelector((state) => state.movies.movieLanguagesWithSubs);
  const movieSchedulesLoading = useSelector((state) => state.movies.movieSchedulesLoading);
  const movieScheduleDetails = useSelector((state) => state.movies.movieScheduleDetails);
  const dateMovieScheduleDetails = useSelector((state) => state.movies.dateMovieScheduleDetails);
  const dateMovieScheduleDetailsLoading = useSelector((state) => state.movies.dateMovieScheduleDetailsLoading);
  const filterMovieScheduleDetails = useSelector((state) => state.movies.filterMovieScheduleDetails);

  const onCreateSchedule = (data) => {
    let {show, screen, movieVersion} = data
    if (!(show)) return

    if (isPrivate) {
    if (!isMobile) window.scrollTo(0, 0)

    dispatch(createOndemandShow(show, history, true))
    .then(res => {
      if (res.status) {
        dispatch(
          fetchScreeningTypes({
            mycinema_ss_d_id: res.mycinema_ss_d_id,
            payload: {stage: 2, show: null, history},
          })
        );
        dispatch(fetchScheduleDetails({mycinema_ss_d_id: res.mycinema_ss_d_id}));
      }
    })
    }
    else {
      const category_id = 2; // only for now showing
      if (!isMobile) window.scrollTo(0, 0)

      console.log('Getting seat layout now showing')
      // get seat layout for selected movie

      dispatch(clearSeatSelection())
      .then(() => {
        return dispatch(clearSelectedVoucher())
      })
      .then(() => {
        return dispatch(releaseRealTimeBlockedSeats())
      })
      .finally(() => {
        const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
        console.log("[reservation id] setting a new reservation id");
        dispatch(setTempReservationId(reservation_id));

        dispatch(fetchSeatLayoutBrij({
          screen_id: show.screen_id,
          ss_id: show.ss_id,
          md_id: show.md_id,
        }));
      });
      // console.log("ss_id :>> ",userSelection?.selectedShow.ss_id, show?.ss_id, );
      dispatch(
        fetchScheduleDetailsBrij({
          ss_id: show.ss_id,
          category_id,
        })
      );

      window.scrollTo(0, 0);
      dispatch(resetTimer());
    }
    onClickNext()
  }

  useEffect(() => {
    if (userSelection.selectedDate) {
      setSelectedDate(userSelection.selectedDate)
      changeCalDate(new Date(userSelection.selectedDate))
    }
  }, [userSelection.selectedDate])
  useEffect(() => {
    if (userSelection.selectedLang)
      setSelectedLang(userSelection.selectedLang)
  }, [userSelection.selectedLang])

  const [availLangs, setAvailLangs] = useState([])
  const [availLangsWithSubs, setAvailLangsWithSubs] = useState([])

  useEffect(() => {
    if (movieLanguages && movieLanguages?.length) {
      let langsSet = new Set(
        // availLangs.map( x =>  JSON.stringify(x) )
      )

      movieLanguages.forEach(l => {
        langsSet.add(JSON.stringify(l))
      })

      setAvailLangs(
        Array.from(langsSet.map(x => JSON.parse(x)))
      )
    }
  }, [movieLanguages])
  useEffect(() => {
    if (movieLanguagesWithSubs && movieLanguagesWithSubs?.length) {
      let langsSet = new Set(
        // isPrivate
        //   ? availLangsWithSubs.map( x =>  JSON.stringify(x) )
        //   : []
      )

      movieLanguagesWithSubs.forEach(l => {
        langsSet.add(JSON.stringify(l))
      })

      let filtered = Array.from(langsSet.map(x => JSON.parse(x)))

      if (!!selectedParamLang) {
        let uriDecodedLang = decodeURIComponent(selectedParamLang)
        setSelectedLangWithSubs(
          filtered.includes(uriDecodedLang)
            ? uriDecodedLang
            : 'All'
        )
      }

      setAvailLangsWithSubs(filtered)
    }
  }, [movieLanguagesWithSubs, isPrivate, selectedParamLang])

  useEffect(() => {
    if (movieSchedules?.sessions) {
      setSessions(movieSchedules.sessions)

      // let [firstAvailSession] = movieSchedules.sessions.filter(s => s.status === true)
      // if (!!firstAvailSession){
      //   dispatch(setOndemandSession(firstAvailSession))
      //   setSelectedSession(firstAvailSession)
      // }
    }
    else {
      let sessions = [
        {label: 'Morning', from: '06:00', to: '12:00', fromToLabel: "06:00 - 12:00", status: true},
        {label: 'Afternoon', from: '12:00', to: '16:00', fromToLabel: '12:00 - 16:00', status: true},
        {label: 'Evening', from: '16:00', to: '20:00', fromToLabel: '16:00 - 20:00', status: true},
        {label: 'Night', from: '20:00', to: '06:00', fromToLabel: '20:00 - 06:00', status: true},
      ]
      setSessions(sessions)
      // dispatch(setOndemandSession(sessions[0]))
      // setSelectedSession(sessions[0])
    }
  }, [movieSchedules])

  const getSessionLabelTranslations = (session) => {
    switch (session.label) {
      case 'Night':
        return `${t('After')} ${session.fromToLabel.split(' - ')[0]}`
      case 'Morning':
        return `${t('Before')} ${session.fromToLabel.split(' - ')[1]}`
      case 'Afternoon':
      case 'Evening':
      default:
        return session.fromToLabel
    }
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      setShowDatePicker(false);
    }
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [])

  useEffect(() => {
    return () => { setAvailLangs([]) }
  }, [])


  // Slider Settings
  const sliderSettings = {
    onSwipe: () => setSwiping(true),
    arrows: true,
    dots: false,
    infinite: !isPrivate
      ? false
      : isMobile
        ? dateMovieScheduleDetails?.dateList <= 3
        : dateMovieScheduleDetails?.dateList <= 5,
    useTransform: false,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: isMobile ? 3 : 5,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 1500,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 610,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 510,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 420,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ].reverse(),
  };

  if ((unavailableDateLoader || movieSchedulesLoading || dateMovieScheduleDetailsLoading) && userSelection.selectedDate === null)
    return <div className="row">
    <div className="col-12 text-center">
      <Spinner/>
    </div>
  </div>

  if (!isPrivate && movieSchedules?.MovieListArrayNew?.length === 0 && isDayPickerUsed === false)
    return <div className="row">
      <div className="col-12 text-center">
        <p className="no-result">
          {t("No shows found")}
        </p>
      </div>
    </div>

  return (
    <>
      <div className={`booking_accordion_step_one ${classes} ${isMobile && 'overflow-hidden'} `}>
        <section className="row">

          {/* SELECT DATE */}
          <article className="col-xl-7 mb-3 mb-xl-0 pr-0 pr-md-3">
            <section className="row align-items-center">

              {/* DATE AND DAY SLIDER */}
              <article className="col-7 col-md-8">
                <h6 className="booking_accordion_step_label">
                  {t('home_page.select-date')}
                </h6>
                <Slider {...sliderSettings} ref={slider}>
                  {dateMovieScheduleDetails?.dateList?.map(
                    (date, index) =>
                      index < Infinity && (
                        <div className="" key={index}>
                          <article
                            style={{
                              cursor: (unavailableDateLoader || movieSchedulesLoading || dateMovieScheduleDetailsLoading)
                                ? 'wait' : undefined
                            }}
                            className={`booking_date_n_day ${
                              date?.isDateAvailable === false ? "inactive" : ""
                            } ${
                              date.ss_actual_start_date === selectedDate ? "active" : ""
                            }${ isPrivate? " golden" : " "}`
                            }
                            onClick={() => onChangeDate(date)}
                          >
                            <p>{date?.momthName}</p>
                            <h5 id="date">
                              {date?.day}
                            </h5>
                            <h6 id="day">
                              {moment(date?.ss_actual_start_date??moment()).locale(iso_2?.toLowerCase()).format("ddd")}
                            </h6>
                          </article>
                        </div>
                      )
                  )}
                </Slider>
              </article>

              {/* DATE PICKER */}
              <article className={!isMobile && `col-5 col-md-4`}>
                <div className={`booking_date ${showDatePicker ? "active" : ""} ${ isPrivate? "goldarrow" : " "}`}
                  onClick={(e)=>e.stopPropagation()}
                >
                  <p
                    onClick={() => {
                      setShowDatePicker(!showDatePicker);
                    }}
                  >
                    <span>
                      {moment(selectedDate??moment()).locale(iso_2?.toLowerCase()).format("DD/MM/YY")}
                    </span>
                  <LeftArrow/>
                    </p>
                  {showDatePicker &&
                  <div className={`new-date-picker new-date-picker-mobile ${isPrivate ? ' golder-datepicker' : ''}`}
                       onClick={
                         (e) => {
                           e.preventDefault();
                           e.stopPropagation();
                         }}
                  >
                    <DayPicker
                     modifiersStyles={{
                      selected: {
                        backgroundColor: isPrivate ? '#C09E3F' : '',
                      },
                      hover: {
                        backgroundColor: isPrivate ? '#ffffff' : '',
                      }
                    }}
                      selectedDays={calDate}
                      disabledDays={[
                        {
                          before: dateMovieScheduleDetails
                            ?.dateList?.[0]?.ss_actual_start_date
                            ? new Date(
                              dateMovieScheduleDetails?.dateList?.[0]?.ss_actual_start_date
                            )
                            : new Date(),
                          after:
                          isPrivate
                            ? undefined
                            : dateMovieScheduleDetails?.dateList?.length > 0
                              ? new Date(
                                dateMovieScheduleDetails?.dateList?.[
                                  (dateMovieScheduleDetails?.dateList?.length) - 1
                                ]?.ss_actual_start_date
                              )
                              : undefined,
                        },
                        ...(dateMovieScheduleDetails?.dateList
                        ?.filter(
                          (x) => x.isDateAvailable === false
                        )
                        .map(
                          (x) => new Date(x.ss_actual_start_date)
                        ) || []),

                        ...((dateMovieScheduleDetails?.disable_date_list ?? [])?.map(x => new Date(x)) || []),
                      ]}
                      onDayClick={(date, modifiers) => {
                        setDayPickerUsed(true)
                        if (modifiers && modifiers.disabled) return;

                        setDateSliderIndex(
                          [...dateMovieScheduleDetails?.dateList].findIndex(
                            dld => dld?.ss_actual_start_date === moment(date).format("YYYY-MM-DD")
                          ) ?? 0
                        )

                        changeCalDate(date)
                        onChangeDate({
                          ss_actual_start_date: moment(date).format(
                            "YYYY-MM-DD"
                          ),
                        });
                           setShowDatePicker(false);

                      }}
                      localeUtils={MomentLocaleUtils}
                        locale={iso_2?.toLowerCase()}
                        
                    />
                  </div>
                  }
                </div>

              </article>
            </section>
          </article>

          {/* SELECT SESSION */}
          <article className="col-xl-5">
            <h6 className="booking_accordion_step_label">
              {t('Select Session')}
            </h6>
            <div className="booking_sessions">
              {sessions.map((s, index) => (
                <article
                  key={index}
                  className={
                    `${selectedSession?.label === s.label ? "active" : ""} ${s.status === false ? "inactive" : ""} 
                     ${ isPrivate? " golden" : " "}`
                  }
                  onClick={() => {
                    if (!s.status) return
                    if (selectedSession?.label === s.label) {
                      dispatch(setOndemandSession(null))
                      setSelectedSession(null)
                      return
                    }

                    dispatch(setOndemandSession(s))
                    setSelectedSession(s)
                  }}
                >
                  {!isMobile && <h5>{t(s.label)}</h5>}
                 <hr  className="zeeb_line"></hr>
                  <h6>{getSessionLabelTranslations(s)}</h6>
                </article>
              ))}
            </div>
          </article>
        </section>

        {
          (movieSchedules?.MovieListArray?.[0]?.CinemaList?.flatMap(
              cinema => cinema?.format_list?.filter(f => {
                if (!selectedLangWithSubs || selectedLangWithSubs == "All") return true;
                return f?.version_full_name == selectedLangWithSubs;
              })
          ).length > 0
          ||
          movieSchedules?.MovieListArrayNew?.[0]?.CinemaList?.flatMap(
              cinema => cinema?.format_list?.filter(f => {
                if (!selectedLangWithSubs || selectedLangWithSubs == "All") return true;
                return f?.version_full_name == selectedLangWithSubs;
              })
          ).length > 0) && (
            <section className="row">
              <article className="col-12">
                {/* SELECT LANGUAGE */}
                <h6 className="booking_accordion_step_label">
                  {/*{availLangs?.length > 1 ? t('Select Language') : t('Available Language')}*/}
                  {availLangsWithSubs?.length > 1 ? t('Select Language') : t('Available Language')}

                  {
                    selectedLangWithSubs !== 'All' && (
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: isPrivate ? 'var(--yellow-shade)' : 'var(--primary)',
                          cursor:"pointer",
                        }}
                        onClick={() => {
                          dispatch(setOndemandSession(null))
                          setSelectedSession(null)
                          setSelectedLangWithSubs('All')
                          let to = isPrivate ? 'private-booking-flow' : 'now-showing-booking'

                          if (
                            !!selectedParamLang && !!uid && !!param_md_id
                          )
                            history.push(`/${to}/${movie_id}/${content_lang_id}/-/-/-`)
                          else
                            history.push(`/${to}/${movie_id}/${content_lang_id}`)
                        }}
                      > ({t("Clear")})
                      </span>
                    )
                  }
                </h6>
                <div className="booking_languages" style={{ gridTemplateColumns: isMobile ? 'repeat(3,1fr)' : undefined }}>
                  {
                    false &&
                    availLangs?.map((lang, index) => (
                    <article
                      key={`lang${index}`}
                      className={`${selectedLang?.lang_id === lang.lang_id ? "active" : ""} ${lang.status === false ? "inactive" : ""}${ isPrivate? "goldenbtn" : " "}`}
                      onClick={() => {
                        dispatch(setOndemandSession(null))
                        setSelectedSession(null)

                        if (selectedLang?.lang_id === lang.lang_id) {
                          setSelectedLang(null)
                          dispatch(setOndemandLang(null))
                          return
                        }

                        setSelectedLang(lang)
                        dispatch(setOndemandLang(lang))
                      }}
                    >
                      {
                        `${ !!t(lang.lang_name) ? t(lang.lang_name) : lang.lang_name }${
                          lang?.sub_1_iso_2 ? `/${lang?.sub_1_iso_2}` : ''
                        }${
                          lang?.sub_1_iso_2 && lang?.sub_2_iso_2 ? `-${lang?.sub_2_iso_2}` : ''
                        }`
                      }
                    </article>
                  ))
                  }

                  {
                    availLangsWithSubs?.map((lang, index) => (
                    <article
                      key={`lang${index}`}
                      className={`${
                        selectedLangWithSubs === lang ? "active" : ""
                      } ${
                        isPrivate ? " goldenbtn" : " "}`
                      }
                      onClick={() => {
                        dispatch(setOndemandSession(null))
                        setSelectedSession(null)
                        setSelectedLangWithSubs(lang)
                      }}
                    >
                      {lang?.split('/').map((lang, index) => !!t(lang) ? t(lang) : lang).join('/')}
                    </article>
                  ))
                  }
                </div>
              </article>
            </section>
          )
        }

        {/* SELECT SHOW AND SCREEN FOR ONDEMAND */}
        {!!isPrivate && (
        <section className="row">
          <article className="col-12">
            {/* SELECT SHOW AND SCREEN */}
            {
              movieSchedules?.MovieListArray?.[0]?.CinemaList?.flatMap(
                (cinema) =>
                  cinema?.format_list?.filter(f => {
                    if (!selectedLangWithSubs || selectedLangWithSubs == 'All') {
                      return true;
                    }
                    else {
                      return f?.version_full_name == selectedLangWithSubs
                    }
                  })
              ).length === 0 && (
                <div className="row">
                  <div className="col-12 text-center">
                    {/* <h4>{t("No shows found")}</h4> */}
                    <p className="no-result">
                      {t("sorry-on-this-date-private-ondemand-not-available")}
                    </p>
                  </div>
                </div>
              )
            }
            {
              (unavailableDateLoader || movieSchedulesLoading) ? (
                <div className="row">
                  <div className="col-12 text-center">
                    <Spinner/>
                  </div>
                </div>
              ) : movieSchedules?.MovieListArray.length === 0 ? (
                <div className="row">
                  <div className="col-12 text-center">
                    {/* <h4>{t("No shows found")}</h4> */}
                    <p className="no-result">
                      {t("sorry-on-this-date-private-ondemand-not-available")}
                    </p>
                  </div>
                </div>
              ) : movieSchedules &&
              movieSchedules?.MovieListArray &&
              movieSchedules?.MovieListArray?.[0] &&
              movieSchedules?.MovieListArray?.[0]?.CinemaList[0] &&
              movieSchedules?.MovieListArray?.[0]?.CinemaList[0]?.format_list[0] &&
              movieSchedules?.MovieListArray?.[0]?.CinemaList[0]?.format_list[0]?.schedule_list &&
              movieSchedules?.MovieListArray?.[0]?.CinemaList[0]?.format_list[0]?.schedule_list?.length === 0 ? (
                  <div className="row">
                    <div className="col-12 text-center">
                      {/* <h4>{t("No shows found")}</h4> */}
                      <p className="no-result">
                        {t("sorry-on-this-date-private-ondemand-not-available")}
                      </p>
                    </div>
                  </div>
                ) :
                (
                  movieSchedules &&
                  movieSchedules?.MovieListArray &&
                  movieSchedules?.MovieListArray?.[0]?.CinemaList &&
                  movieSchedules?.MovieListArray?.[0]?.CinemaList?.map(
                    (cinema, cinemaIndex) =>
                      cinema?.format_list?.filter(f => {
                        if (!selectedLangWithSubs || selectedLangWithSubs == 'All') {
                          return true;
                        }
                        else {
                          return f?.version_full_name == selectedLangWithSubs
                        }
                      }).map((format, formatIndex) => {
                      return format?.screen_list?.map((screen, screenIndex) => {
                        return (
                          <>
                            <BookingShows
                              isPrivate={!!isPrivate}
                              key={`${cinemaIndex}-${screenIndex}-${formatIndex}`}
                              screen={screen}
                              format={format}
                              cinema={cinema}
                              formatIndex={formatIndex}
                              screenIndex={screenIndex}
                              cinemaIndex={cinemaIndex}
                              onClickShowMobile={(data) => {
                                onCreateSchedule(data)
                              }}
                              allLangWithSubs={selectedLangWithSubs == 'All'}
                            />
                          </>
                        )
                      })
                    })
                  )
                )
            }
          </article>
        </section>
        )}

        {/* SELECT SHOW AND SCREEN FOR NOW SHOWING */}
        {!isPrivate && (
          <section className="row">
            <article className="col-12">
              {/* SELECT SHOW AND SCREEN */}
              {
                movieSchedules?.MovieListArrayNew?.[0]?.CinemaList?.flatMap(
                  (cinema) =>
                    cinema?.format_list?.filter(f => {
                      if (!selectedLangWithSubs || selectedLangWithSubs == 'All') {
                        return true;
                      }
                      else {
                        return f?.version_full_name == selectedLangWithSubs
                      }
                    })
                ).length === 0 && (
                  <div className="row" key={'noshows'}>
                    <div className="col-12 text-center">
                      {/* <h4>{t("No shows found")}</h4> */}
                      <p className="no-result">
                        {t("No shows found")}
                      </p>
                    </div>
                  </div>
                )
              }
              {
                (unavailableDateLoader || movieSchedulesLoading) ? (
                  <div className="row">
                    <div className="col-12 text-center">
                      <Spinner/>
                    </div>
                  </div>
                ) : movieSchedules?.MovieListArrayNew?.length === 0 ? (
                  <div className="row">
                    <div className="col-12 text-center">
                      {/* <h4>{t("No shows found")}</h4> */}
                      <p className="no-result">
                        {t("No shows found")}
                      </p>
                    </div>
                  </div>
                ) : movieSchedules &&
                movieSchedules?.MovieListArrayNew &&
                movieSchedules?.MovieListArrayNew?.[0] &&
                movieSchedules?.MovieListArrayNew?.[0]?.CinemaList[0] &&
                movieSchedules?.MovieListArrayNew?.[0]?.CinemaList[0]?.format_list[0] &&
                movieSchedules?.MovieListArrayNew?.[0]?.CinemaList[0]?.format_list[0]?.schedule_list &&
                movieSchedules?.MovieListArrayNew?.[0]?.CinemaList[0]?.format_list[0]?.schedule_list?.length === 0 ? (
                    <div className="row">
                      <div className="col-12 text-center">
                        {/* <h4>{t("No shows found")}</h4> */}
                        <p className="no-result">
                          {t("No shows found")}
                        </p>
                      </div>
                    </div>
                  ) :
                  (
                    movieSchedules &&
                    movieSchedules?.MovieListArrayNew &&
                    movieSchedules?.MovieListArrayNew?.[0]?.CinemaList &&
                    movieSchedules?.MovieListArrayNew?.[0]?.CinemaList?.map(
                      (cinema, cinemaIndex) =>
                        cinema?.format_list?.filter(f => {
                          if (!selectedLangWithSubs || selectedLangWithSubs == 'All') {
                            return true;
                          }
                          else {
                            return f?.version_full_name == selectedLangWithSubs
                          }
                        }).map((format, formatIndex) => {
                        return format?.screens?.map((screen, screenIndex) => {
                          return (
                            <React.Fragment  key={`${cinemaIndex}-${screenIndex}-${formatIndex}bookingShow`}>
                              <BookingShows
                                isPrivate={!!isPrivate}
                                key={`${cinemaIndex}-${screenIndex}-${formatIndex}`}
                                screen={screen}
                                format={format}
                                cinema={cinema}
                                formatIndex={formatIndex}
                                screenIndex={screenIndex}
                                cinemaIndex={cinemaIndex}
                                onClickShowMobile={(data) => {
                                  console.log('onClickShowMobile Now Showing')
                                  onCreateSchedule(data)
                                }}
                              />
                            </React.Fragment>
                          )
                        })
                      })
                    )
                  )
              }
            </article>
          </section>
        )}
      </div>
    </>
  );
}

// Default Props
StepOne.defaultProps = {
  classes: "",
  onClickNext: () => {
  }
}

export default withNamespaces()(StepOne);
