import React from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";
const AgeRating = ({ t }) => {

  let categories = [
    { age: "3", header: "Rating.Category3Header", description: "Rating.Category3Text" },
    { age: "6", header: "Rating.Category6Header", description: "Rating.Category6Text" },
    { age: "8", header: "Rating.Category8Header", description: "Rating.Category8Text" },
    { age: "10", header: "Rating.Category10Header", description: "Rating.Category10Text" },
    { age: "12", header: "Rating.Category12Header", description: "Rating.Category12Text" },
    { age: "14", header: "Rating.Category14Header", description: "Rating.Category14Text" },
    { age: "16", header: "Rating.Category16Header", description: "Rating.Category16Text" },
    { age: "TBA", header: "TBA", description: "Rating.CategoryTBAText" }
  ];
  return (
    <>
      <section className="about_us_age_rating_wrapper align_new">
        <div className="left_right_align">
      <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Rating.AgeRatingAt") + " Stuessihof"}/>
        <CenteredTitle firstHalf={t("Rating.AgeRatingAt") + " Stuessihof"} secondHalf="" />
        </div>
        <div className="row age_back">
          <div className="col-xl-10 mx-auto">
            <div className="mb-4 mb-md-5">
              <p>
                {t("Rating.AgeRatingAtText1")}
              </p>
              <p>
                {t("Rating.AgeRatingAtText2")}
              </p>
              <p>
                {t("Rating.AgeRatingAtText3")}
              </p>
            </div>
          </div>
          {categories.map((item, index) => (
            <article className="col-md-6 col-xl-5 ml-xl-auto" key={index}>
              <div className="about_us_age_rating">
                <article className="about_us_spacing">
                  <span style={{marginRight:"10px"}}>
                    {item.age}
                  </span>
                  <p>
                    {t(item.header)}
                  </p>
                </article>
                <p>
                  {t(item.description)}
                </p>
              </div>
            </article>
          ))}
          <div className="col-xl-10 mx-auto">
            <p className="mt-4 mt-md-5">
              <span className="age_rating_highlight">{t("Rating.Note")}</span>
            </p>
          </div>
        </div>

      </section>
    </>
  );
};

export default withNamespaces()(AgeRating);
