import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import {Switch, Route, useLocation, useHistory} from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
// //import components
import moviesList from "@components/moviesList.js";
import miniFestivals from "@components/miniFestivals.jsx";
import SeatLayout from "@components/SeatLayout.jsx";
import createScreen from "@components/createScreen.js";
import confirmedShowList from "@components/confirmedShowList.js";
import mainHomeContainer from "@components/mainHomeContainer.jsx";
import MovieDetails from "@components/MovieDetails.jsx";
import CreateShowDetails from "@components/CreateShowDetails.jsx";
// import MovieDetail from "@components/movieDetails.js";
import bookingDetail from "@components/bookingDetail.js";
import confirmedBooking from "@components/confirmedBooking.js";
import checkout from "@components/checkout.jsx";
import Feedback from "@components/Feedback.jsx";
import paymentFlow from "@components/paymentFlowV2.jsx";
import finalTicket from "@components/FinalTicket.jsx";
import finalTicketUnpaidBooking from "@components/FinalTicketUnpaidBooking.jsx";
import CinemaListing from "@components/CinemaListing.jsx";
import AuditoriumListing from "@components/AuditoriumListing.jsx";
import ScreeningType from "@components/ScreeningType.jsx";
import FAQs from "@components/FAQ.jsx";
import { } from "./";
import PromotionsAndOffers from "@components/PromotionsAndOffers.jsx";
import HowItWorks from "@components/HowItWorks.jsx";
import userProfile from "@components/user-profile-screens/Navigation.jsx";
import MobileMenu from "@components/MobileMenu.jsx";
import Fnb from "@components/Fnb.jsx";
import CinemaDetails from "@components/CinemaDetails.jsx";
import TermsAndConditions from "@components/TermsAndConditions.jsx";
import PrivacyPolicy from "@components/PrivacyPolicy.jsx";
import Cookies from "@components/Cookies.jsx";
import Documentation from "@components/Documentation.jsx";
import Schedules from "@components/Schedules.jsx";
import i18n from "./plugins/i18n";
import AboutUs from "@components/AboutUs1.jsx";
import OnDemand from "@components/OnDemand.jsx";
import LabelledMovies from "@components/LabelledMovies.jsx";
import Page404 from "@components/Page404.jsx";
import { headerBg, titleCase, getLangISOCode } from "./helper/formatting";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { releaseRealTimeBlockedSeats } from "./store/seatLayout/seatLayoutActions";
import {
  fetchStripePublisherKey,
  fetchGlobalOptions,
  getCinemas,
  getOrganizationDetails,
} from "./store/movies/movieActions";
import CreateShowSeatLayout from "./components/CreateShowSeatLayout";
import BookEvent from "@components/BookEvent";
import PrivateBookingFlowContainer from "@components/BookingFlow/PrivateBookingFlowContainer"
import NowShowingBookingFlowContainer from "@components/BookingFlow/NowShowingBookingFlowContainer.jsx"
import NowShowingSingleShowBookingFlowContainer from "@components/BookingFlow/NowShowingSingleShowBookingFlowContainer.jsx"
import Search from "@components/SearchContainer"
import NowShowing from "@components/NowShowingContainer"
import NewOnDemand from "@components/NewOnDemandContainer"
import ShowMovieList from "@components/ShowMovieList"
import NewMiniFestivals from "@components/NewMiniFestivals";
import NewMiniFestivalsList from "@components/NewMiniFestivalsList";
import { fetchSectionCMS } from "./store/homepage/homepageActions";
import UpcomingMovies from "@components/upcomingShows";
import Spinner from "./components/partials/Spinner";
import AboutUsNew from "@components/AboutUs/AboutUsContainer";
import FAQNew from "@components/FAQNewContainer";
import TnCNew from "@components/TnCNewContainer";
import ContactUsNew from "@components/ContactUsNewContainer";
import OpeningHours from "@components/OpeningHoursContainer";
import OurTariff from "@components/AboutUs/helper/TariffsAndVouchers";
import AuditoriumPlan from "@components/AboutUs/helper/AuditoriumPlan";
import AgeRating from "@components/AboutUs/helper/AgeRating";
import LanguageVersions from "@components/LanguageVersions";
import Jobs from "@components/Jobs";
import Events from "@components/AboutUs/helper/EventsAndAppetisers";
import BarOffers from "@components/AboutUs/helper/BarOffers";
import Advertise from "@components/Advertise";
import RentOurSpace from "@components/RentOurSpace";
import CashCardsContainer from "@components/GiftCards/CashCardsContainer";
import CashCardDetails from "@components/GiftCards/CashCardDetailsContainer";
import CashCardPayment from "@components/GiftCards/CashCardPayment";
import CashCardTopup from "@components/GiftCards/CashCardTopup";
import CashCardBuySuccess from "@components/GiftCards/CashCardBuySuccess";
import CashCardTopupSuccess from "@components/GiftCards/CashCardTopupSuccess";
import PaymentFail from "@components/PaymentFail";
import { getMomentLocalLang } from '@helper/languages';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}
// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}
// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};
const Router = () => {
  let globalOptions = useSelector((state) => state.movies.globalOptions);
  let location = useLocation();

  useEffect(() => {
    // console.log("global options changed: ", globalOptions);
  }, [globalOptions]);

  useEffect(() => {
    const existingGuestUserId = localStorage.getItem(`guest_user_id`);
    if (!existingGuestUserId) {
      const guest_user_id = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(`guest_user_id`, guest_user_id);
    }
  }, []);

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  useEffect(() => {
    // console.log("this use effect is triggered");
    let pathname = location.pathname;
    if (
      pathname &&
      !["seat-layout", "payment", "final-ticket", "fnb"].includes(
        pathname.split("/")?.[1]
      )
    ) {
      dispatch(releaseRealTimeBlockedSeats());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selected_language) {
      // console.log(
      //   "🚀 ~ file: Router.js ~ line 106 ~ useEffect ~ selected_language",
      //   selected_language
      // );
      let lang = selected_language.iso_2
        ? selected_language.iso_2.toLowerCase()
        : getLangISOCode(selected_language.lang_name);
      // console.log("found lang = ", lang);
      i18n.changeLanguage(lang);
      moment.locale(getMomentLocalLang(lang.toLowerCase()));
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStripePublisherKey());
    dispatch(fetchGlobalOptions());
    dispatch(getCinemas());
    dispatch(getOrganizationDetails());
    dispatch(fetchSectionCMS());
  }, [dispatch]);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    console.log({ "process.env.NODE_ENV": process.env.NODE_ENV })
    window.scrollTo(0, 0);
  }, [location.pathname]);
  // Scroll to top if path changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    //define path and use component
    <React.Suspense fallback={(<div className="row">
      <div className="col-12 text-center">
        <Spinner/>
      </div>
    </div>)}>

    <Switch>
      {/* <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"> */}
      <Route exact path="/" component={mainHomeContainer} />
      <Route exact path="/movies-list" component={moviesList} />
      <Route
        exact
        path="/movie-details/:movie_id/:content_lang_id/:type?/:isPrivate?"
        component={MovieDetails}
      />
      <Route
        exact
        path="/create-show-details/:movie_id/:content_lang_id/:isPrivate?"
        component={CreateShowDetails}
      />
      {/*<Route exact path="/movie-details" component={MovieDetail} />*/}
      <Route
        exact
        path="/seat-layout/:ss_id/:screen_id/:md_id"
        component={SeatLayout}
      />
      <Route
        exact
        path="/create-show-seat-layout/:mycinema_ss_d_id/:booking_type_id"
        component={CreateShowSeatLayout}
      />
      <Route exact path="/create-screen" component={createScreen} />
      <Route exact path="/confirmed-show-list" component={confirmedShowList} />
      <Route exact path="/booking-details" component={bookingDetail} />
      <Route
        exact
        path="/screening-type/:mycinema_ss_d_id"
        component={ScreeningType}
      />
      <Route exact path="/checkout" component={checkout} />
      <Route exact path="/feedback" component={ContactUsNew} />
      <Route exact path="/contact-us" component={Feedback} />
      <Route exact path="/confirmed-booking" component={confirmedBooking} />
      <Route exact path="/mini-festivals/:mini_fest_id?" component={NewMiniFestivals} />
      <Route exact path="/mini-festivals-list" component={NewMiniFestivalsList} />
      <Route
        exact
        path="/payment/:reservation_id/:category_id"
        // /:category_id/:movie_id/:content_lang_id/:genre_id/:isPrivate/
        component={paymentFlow}
      />
      <Route
        exact
        path="/final-ticket/:show_booking_id/:category_id"
        component={finalTicket}
      />
      <Route
        exact
        path="/unpaid-ticket/:reservation_id/:category_id"
        component={finalTicketUnpaidBooking}
      />
      <Route exact path="/cinema-listing" component={CinemaListing} />
      <Route exact path="/auditorium-listing" component={AuditoriumListing} />
      {/* <Route exact path="/faqs" component={FAQs} /> */}
      <Route exact path="/faqs" component={FAQNew} />
      <Route
        exact
        path="/promotions-and-offers"
        component={PromotionsAndOffers}
      />
      <Route exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/user-profile" component={userProfile} />
      <Route exact path="/guest-user-profile" component={userProfile} />
      <Route exact path="/menu" component={MobileMenu} />
      {/* <Route exact path="/fnb" component={Fnb} /> */}
      <Route exact path="/fnb/:ss_id/:screen_id/:md_id/:fnb_item_id?" component={Fnb} />
      <Route
        exact
        path="/fnb/:mycinema_ss_d_id/:booking_type_id/:fnb_item_id?"
        component={Fnb}
      />
      <Route
        exact
        path="/cinema-details/:cinema_id"
        component={CinemaDetails}
      />
      {/* <Route exact path="/tnc" component={TermsAndConditions} /> */}
      <Route exact path="/tnc" component={TnCNew} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        {/* <Route exact path="/about-us" component={AboutUs} /> */}
        <Route exact path="/about-us" component={AboutUsNew}/>
      <Route exact path="/documentation" component={Documentation} />
      <Route exact path="/cookies" component={Cookies} />
      <Route exact path="/book-event" component={BookEvent} />
      <Route exact path="/on-demand" component={OnDemand} />
      <Route exact path="/schedules" component={Schedules} />
      <Route
        exact
        path="/filtered-movie-list/:label_id"
        component={LabelledMovies}
      />
      <Route exact path="/home" component={mainHomeContainer} />
      <Route
        exact
        path="/private-booking-flow/:movie_id/:content_lang_id/:selectedParamLang?/:md_id?/:uid?"
        component={PrivateBookingFlowContainer}
      />
      <Route
        exact
        path="/now-showing-booking/:movie_id/:content_lang_id/:selectedParamLang?/:md_id?/:uid?"
        component={NowShowingBookingFlowContainer}
      />
      <Route
        exact
        path="/now-showing-single-show-booking/:ss_id/:screen_id/:md_id/:movie_id/:content_lang_id?/:uid?"
        component={NowShowingSingleShowBookingFlowContainer}
      />
      <Route exact path="/search" component={Search} />
      <Route exact path="/now-showing" component={NowShowing} />
      <Route exact path="/new-on-demand" component={NewOnDemand} />
      <Route exact path="/show-list-movies/:category_id/:section_id?" component={ShowMovieList} />
      <Route exact path="/upcoming" component={UpcomingMovies} />
        <Route exact path="/new-mini-festivals" component={NewMiniFestivals} />
        <Route exact path="/opening-hours" component={OpeningHours} />
        <Route exact path="/our-tariff" component={OurTariff} />
        <Route exact path="/auditorium-plan" component={AuditoriumPlan} />
        <Route exact path="/age-rating" component={AgeRating} />
        <Route exact path="/language-versions" component={LanguageVersions} />
        <Route exact path="/jobs" component={Jobs} />
        <Route exact path="/rent-our-space" component={RentOurSpace} />
        <Route exact path="/advertise" component={Advertise} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/bar-offers" component={BarOffers} />

        <Route exact path="/cash-cards/:active?" component={CashCardsContainer} />
        <Route exact path="/cash-card-details/:w_cash_card_unique_id" component={CashCardDetails} />
        <Route exact path="/cash-card-payment/:reservation_id" component={CashCardPayment} />
        <Route exact path="/cash-card-topup/:cash_card_number/:topup_id?" component={CashCardTopup} />
        <Route exact path="/cash-card-buy-success/:reservation_id" component={CashCardBuySuccess} />
        <Route exact path="/cash-card-topup-success/:topup_code" component={CashCardTopupSuccess} />

        <Route exact path="/payment-failed/:payment_for?" component={PaymentFail} />

      <Route path="*" component={Page404} />

      {/* </AnimatedSwitch> */}
    </Switch>

    </React.Suspense>
  );
};
export default Router;
