import React from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";
const TnCNewContainer = ({ t }) => {
  return (
    <>
      <div className="tnc_new align_new">
      <div className="left_right_align">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={`${t("Terms")} & ${t("Conditions")}`}/>
        <CenteredTitle firstHalf={`${t("Terms")} & ${t("Conditions")}`} secondHalf={''} />
        </div>
        <section className="row">
        
          <article className="col-lg-10 col-xl-12 tnc_back" >
            
            <div>
              <p>
                <span className="tnc_new_text_highlighted">
                  {t("generalTermsAndConditionsLine1Text1")}
                </span>
                {" "}
                {t("generalTermsAndConditionsLine1Text2")}
              </p>
            </div>

            <div>
              <p>
                <span className="tnc_new_text_highlighted">
                  {t("generalTermsAndConditionsLine2Text1")}
                </span>
                {" "}
                {t("generalTermsAndConditionsLine2Text2")}
              </p>
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t("generalProvisions")}
            </p>
            <div>
              <ul>
                <li>
                  {t("generalProvision1")}
                </li>
                <li>
                  {t("generalProvision2")}
                </li>
                <li>
                  {t("generalProvision3")}
                </li>
                <li>
                  {t("generalProvision4")}
                </li>
                <li>
                  {t("generalProvision5")}
                </li>
                <li>
                  {t("generalProvision6")}
                </li>
              </ul>
            </div>

            <p className="text-primary">
              {t("ArtMediaProductionGmbH")}
            </p>

          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
