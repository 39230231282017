import React, { useState, useRef } from "react";
import { withNamespaces } from "react-i18next";

// Components
import Slider from "react-slick";
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";
import useIsMobile from "@src/customHooks/useIsMobile";

// Images
import seatsImg from "@assets/pictures/about-us.png";
import seatsImg1 from "@assets/pictures/about-us-1.png";
import seatsImg2 from "@assets/pictures/about-us-2.png";
import seatsImg3 from "@assets/pictures/about-us-3.png";
import seatsImg4 from "@assets/pictures/about-us-4.png";
import { ReactComponent as TrailerIcon } from "@assets/pictures/svgs/play_new.svg";
import ModalVideo from 'react-modal-video';

import { RenderInWindow } from '@components/partials/RenderInWindow';

const AboutUsHowItWorks = (props) => {
  const { t, classes = "" } = props;
  const [swiping, setSwiping] = useState(false);
  const howItWorksSliderRef = useRef();
  const stepsLength = 4;

  const [isOpen, setOpen] = useState(false);

  const handleSwipe = () => {
    setSwiping(true);
  };
  const isMobile = useIsMobile();

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let images = [seatsImg1, seatsImg2, seatsImg3, seatsImg4];

  return (
    <>
      {/*{isOpen && <RenderInWindow url={'https://vimeo.com/705787188/338ebd82b9'} />}*/}

      <section className={`container-fluid about_us_how_it_works ${classes}`}>
        <BreadCrumb
          firstHalf={`${t("More")} >`}
          secondHalf={t("AboutUs.howItWorksHeader")}
        />
        <div
          className="d-flex"
          style={{
            alignItems: "center",
            flexWrap: isMobile ? "wrap" : undefined,
          }}
        >
          <CenteredTitle
            firstHalf={t("AboutUs.howItWorksHeader")}
            secondHalf={""}
          />
          <ModalVideo
            autoplay
            channel={'vimeo'}
            isOpen={isOpen}
            videoId={
              '705787188'
            }
            onClose={() => setOpen(false)}
          />
          <div
            onClick={() => {
              setOpen(!isOpen);
            }}
            className="d-flex slide-animation trans sch_trailer_icon"
            style={{
              alignItems: "center",

              gap: "8px",
              marginLeft: isMobile ? "0px" : "10px",
              marginBottom: isMobile ? "10px" : "20px",
            }}
          >
            <TrailerIcon width={25} style={{ path: "white" }} />

            <h5 style={{ marginBottom: "0px" }}>{t('Watch Clip')}</h5>
          </div>
        </div>
        <div className="row">
          <article className="col-xl-10 ml-auto">
            <section className="row">
              <article className="col-md-6 mb-4 mb-md-0">
                <p className="about_us_how_it_works_synopsis">
                  {t("AboutUs.howItWorksText")}
                </p>
                <Slider {...heroSlider} ref={howItWorksSliderRef}>
                  {images.map((item, index) => (
                    <article key={index}>
                      <figure onClick={handleClick}>
                        <img src={item} alt="" />
                      </figure>
                    </article>
                  ))}
                </Slider>
              </article>
              <article className="col-md-6">
                <ul className="about_us_how_it_works_steps list-unstyled">
                  <li>
                    <h6
                      onClick={() => howItWorksSliderRef.current?.slickGoTo(0)}
                    >
                      {t("common.Select Movie")}
                    </h6>
                    <p>{t("AboutUs.SelectMoviesMessage")}</p>
                  </li>
                  <li>
                    <h6
                      onClick={() => howItWorksSliderRef.current?.slickGoTo(1)}
                    >
                      {t("Select Date, Language & Time Slots")}
                    </h6>
                    <p>{t("AboutUs.SelectDateTimeMessage")}</p>
                  </li>
                  <li>
                    <h6
                      onClick={() => howItWorksSliderRef.current?.slickGoTo(2)}
                    >
                      {t("AboutUs.PreOrderBeverages")}
                    </h6>
                    <p>{t("AboutUs.PreOrderBeveragesMessage")}</p>
                  </li>
                  <li>
                    <h6
                      onClick={() => howItWorksSliderRef.current?.slickGoTo(3)}
                    >
                      {t("Checkout")}
                    </h6>
                    <p>{t("AboutUs.CheckoutMessage")}</p>
                  </li>
                </ul>
              </article>
            </section>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHowItWorks);
