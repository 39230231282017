import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
// Components
import AboutUsHeroText from "./helper/AboutUsHeroText";
import AboutUsHeroSlider from "./helper/AboutUsHeroSlider";
import AboutUsHowItWorks from "./helper/AboutUsHowItWorks";
import AboutUsBookingType from "./helper/AboutUsBookingType";
import BarOffers from "./helper/BarOffers";
import AuditoriumPlan from "./helper/AuditoriumPlan";
import EventsAndAppetisers from "./helper/EventsAndAppetisers";
import TariffsAndVouchers from "./helper/TariffsAndVouchers";
import AgeRating from "./helper/AgeRating";
//Images
import map from "@assets/pictures/map.png";
import { ReactComponent as MapIcon } from "@assets/pictures/svgs/map-new.svg";

import useIsMobile from "../../customHooks/useIsMobile";

class ErrorBoundary extends React.Component {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  // A fake logging service.
  logErrorToServices = console.log;

  render() {
    if (this.state.errorMessage) {
      return <p>{this.state.errorMessage}</p>;
    }
    return this.props.children;
  }
}

const Maps = () => {
  const isMobile = useIsMobile();

  return <div>
    <iframe
      style={{ border: 0 }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.030464761232!2d8.541581415622394!3d47.3723238791699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0a9eb55d7a3%3A0xa9667931be870f79!2sCinema%20St%C3%BCssihof%2C%20Bar%2C%20Take-Out!5e0!3m2!1sen!2sin!4v1644921382599!5m2!1sen!2sin" 
      allowfullscreen="" loading="lazy"
      className={isMobile ? "map_mob_sizing" : "map_sizing"}
    />
  </div>
}

const AboutUsContainer = ({t}) => {
  const history = useHistory();
  useEffect(() => { window.scrollTo(0, 0) }, []);

  let content = [
    {
      header: [
        'ContactUs.reservations',
      ],
      text: [
        'ContactUs.reservationsBoxoffice',
      ],
      phone: [
        '044 542 49 34',
      ],
      email: [
        '',
      ],
    },
    {
      header: [
        'ContactUs.events',
      ],
      text: [
        'Zoë Stähli',
      ],
      phone: [
        '+41 (0)44 296 90 78',
      ],
      email: [
        'zoe@stuessihof.ch',
      ],
    },
    {
      header: [
        'ContactUs.bar',
      ],
      text: [
        'Caroline Stirnemann',
      ],
      phone: [
        '',/*+41 (0)79 286 70 42 */
      ],
      email: [
        'caroline@stuessihof.ch',
      ],
    },
    {
      header: [
        'ContactUs.administration',
      ],
      text: [
        'Peter Preissle',
      ],
      phone: [
        '',/*+41 (0)44 296 90 73 */
      ],
      email: [
        'p.preissle@cinebox.ch', //'preissle@stuessihof.ch',
      ],
    },
    {
      header: [
        'Address',
      ],
      text: [
        'Kino Stüssihof',
        'Stüssihofstatt 13',
        '8001 Zürich',
      ],
      phone: [
        '',
      ],
      email: [
        '',
      ],
    },
  ]

  return (
    <>
      <div className="about_us_new">
        <AboutUsHeroText />

        <AboutUsHeroSlider />

        {/*<AboutUsHowItWorks />*/}

        {/*<AboutUsBookingType
          type="private" titleFirstHalf={t('Private Cinema')} titleSecondHalf="" description={t('PrivateCinemaMessage')}
          onGetStarted={() => history.push("/new-on-demand")}
        />

        <AboutUsBookingType
          type="watch_party" titleFirstHalf={t('Watch party')} titleSecondHalf="" description={t('WatchPartyMessage')}
          onGetStarted={() => history.push("/new-on-demand")} reverse
        />

        <AboutUsBookingType
          type="crowdsourced" titleFirstHalf={t("Crowdsourced Cinema")} titleSecondHalf=""
          description={t('CrowdsourcedCinemaMessage')} onGetStarted={() => history.push("/new-on-demand")}
        />*/}

        {/* <BarOffers />

        <AuditoriumPlan />

        <EventsAndAppetisers />

        <TariffsAndVouchers />

        <AgeRating /> */}
        <div className="about_us_back">
        <section className="about_us_grid">
          {content.map(c => {
            return <div>
              <p className="about_us_heading">
                {!!t(c.header[0]) ? t(c.header[0]).split('/').join(' / ') : c.header[0]}
              </p>
              <p className="about_us_para">
                {c.text.map((txt, i) => (
                  !!txt && (<React.Fragment key={i}>
                    <p>
                      {!!t(txt) ? t(txt) : txt}
                    </p>
                  </React.Fragment>)
                ))}
              </p>
              <p className="about_us_tele">
                {c.phone[0] && t('Telephone')}{' '}{c.phone[0]}
              </p>
              <p style={{marginTop:"8px"}}>
                {!!c.email && (
                  <a href={`mailto:${c.email[0]}`}>
                    {c.email[0]}
                  </a>
                )}
              </p>
            </div>
          })}

          {/*<div>
            <p className="about_us_heading">Events</p>
            <p className="about_us_para">Cinema tickets can be reserved by phone daily from 8 am to 9.30 pm</p>
            <p className="about_us_tele mt-5">Telefon 044 542 49 34</p>
            <p> <a href="mailto:zoe@stuessihof.ch">zoe@stuessihof.ch</a></p>
          </div>

          <div>
            <p className="about_us_heading">Events</p>
            <p className="about_us_para">Cinema tickets can be reserved by phone daily from 8 am to 9.30 pm</p>
            <p className="about_us_tele mt-5">Telefon 044 542 49 34</p>
            <p> <a href="mailto:zoe@stuessihof.ch">zoe@stuessihof.ch</a></p>
          </div>

          <div>
            <p className="about_us_heading">Events</p>
            <p className="about_us_para">Cinema tickets can be reserved by phone daily from 8 am to 9.30 pm</p>
            <p className="about_us_tele mt-5">Telefon 044 542 49 34</p>
            <p> <a href="mailto:zoe@stuessihof.ch">zoe@stuessihof.ch</a></p>
          </div>


          <div>
            <p className="about_us_heading">Events</p>
            <p className="about_us_para">Cinema tickets can be reserved by phone daily from 8 am to 9.30 pm</p>
            <p className="about_us_tele mt-5">Telefon 044 542 49 34</p>
            <p> <a href="mailto:zoe@stuessihof.ch">zoe@stuessihof.ch</a></p>
          </div>
          <div>
            <p className="about_us_heading">Events</p>
            <p className="about_us_para">Cinema tickets can be reserved by phone daily from 8 am to 9.30 pm</p>
            <p className="about_us_tele mt-5">Telefon 044 542 49 34</p>
            <p> <a href="mailto:zoe@stuessihof.ch">zoe@stuessihof.ch</a></p>
          </div>*/}

          <div className="img_about_us_space">
            
            <ErrorBoundary >
              <Maps />
            </ErrorBoundary>
          
            <p style={{ display: "flex", alignContent: "center", justifyContent: "center" }} className="mt-3">
              <Link
                to={{ pathname: "https://www.google.ch/maps/place/Kino+St%C3%BCssihof/@47.372348,8.543828,17z/data=!3m1!4b1!4m2!3m1!1s0x479aa0a9eb55d7a3:0xa9667931be870f79" }}
                target="_blank"
              >

                <MapIcon />
                <span className="px-1" style={{ color: "var(--primary)" }}>{t("Get Directions")}</span>
              </Link>
            </p>
          </div>

        </section>
    
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(AboutUsContainer);
