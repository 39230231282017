import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import useIsMobile from "../../customHooks/useIsMobile";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";
import { SET_MOVIE_LANGUAGES } from "@store/movies/movieTypes";
import { clearMovieDetails, clearMovieSchedules, clearNewOndemandState } from "@store/movies/movieActions";
import { clearSeatSelection } from "@store/seatLayout/seatLayoutActions";
import { clearFnbState } from "@store/foodAndBeverages/foodAndBeveragesActions";
import { movieLanguagesWithSubs } from "@helper/formatting";
import { v4 as uuidv4 } from 'uuid';

const HorizontalCard = ({
  // type = "yellow",
  onChangeSearch,
  setSearchBar,
  t,
  onClick: onClickSearchResult,
  movie,
  showType = 'now_showing',
  isUpcoming= false,
}) => {
  const dispatch = useDispatch();
  let NowShowing = movie['NowShowing']?.[0] || undefined
  let OnDemand = movie['OnDemand']?.[0] || undefined
  let Upcoming = movie['Upcoming']?.[0] || undefined
  let OnDemandLabeledMovies = movie['OnDemandLabeledMovies']?.[0] || undefined

  let {
    md_thumbnail_url,
    movie_title,
    genre_name,
    mf_name,
    rating,
    movie_id,
    content_lang_id,
    genre_id,
    category_id,
    now_demand_count,
    now_showing_count,
    genres_with_translations,
    languages_with_translations = [],
    altMovieContent,
    is_upcomimg,
    original_release_date,
    original_title_of_movie,
    lang_name,
    format_name,
    label_to_show,
  } = NowShowing || OnDemand || Upcoming || OnDemandLabeledMovies || {};

  const selected_language = useSelector((state) => state.movies.selected_language);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image") ? window.localStorage.getItem("placeholder_image") : dummyPosterStatic;

  const { iso_2, lang_id } = selected_language;
  const history = useHistory();
  const isMobile = useIsMobile();

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, } = loginDetails;

  // let type1 = "yellow"; // now showing
  // let type2 = "yellow"; // on demand
  // let type3 = "yellow"; // coming soon
  //   if (showType === "Book Now") {
  //   type = "green";
  // }
  // if (now_showing_count > 0) {
  //   type1 = "red";
  // }
  // if (now_demand_count > 0) {
  //   type2 = "yellow";
  // }

  useEffect((OnDemand) => {}, [movie]);
  const getFormat = (
    NowShowing,
    OnDemand,
    Upcoming,
    OnDemandLabeledMovies
  ) => {
    return OnDemand?.format_name || NowShowing?.format_name || Upcoming?.format_name || OnDemandLabeledMovies?.format_name ||
      OnDemand?.format_name || NowShowing?.format_name || Upcoming?.format_name || OnDemandLabeledMovies?.format_name || ''
  }

  // if (!movie_title) return <>Loading...</>
  return (<div className="searched-movie-link">
    <div
      className={`searched-movie ${false ? "searched-movie-on-demand" : ""}`}
      // onClick={() => {
      //   onClickSearchResult && onClickSearchResult();
      // }}
    >
      <div className="img-wrapper position-relative movie_info_wrapper">
        <img
          src={getLangSpecificAttribute(altMovieContent, lang_id, "artwork") || dummyPoster}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = () => {} // prevents looping
            currentTarget.src = dummyPosterStatic
          }}
        />
        {label_to_show?.length > 0 && (
          <article className="movie_info_label_wrapper">
            <span className="movie_info_label_background" />
            <span className="movie_info_label">
                      {getLangSpecificAttribute(
                        label_to_show,
                        lang_id,
                        "label_name",
                        "lang_id"
                      )  || label_to_show?.[0].label}
                    </span>
          </article>
        )}
      </div>

      <div className="movie-content">
        <div>

          <p className="movie-name">
            {getLangSpecificAttribute(altMovieContent, lang_id, "mc_title") || movie_title || original_title_of_movie}
          </p>

          <div style={{ display: "flex" }}>
            <span style={{ fontSize: "12px", fontWeight: "300",wordBreak:"break-all",paddingBottom:"10px" }}>
              {!!(getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)) && `${getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)}`}
              {genres_with_translations?.map((genre, index) => (
                <React.Fragment key={index}>
                  {genre[iso_2?.toLowerCase()]}
                  {genres_with_translations?.length - 1 !== index && ","}
                  &nbsp;
                </React.Fragment>
              )).length > 0
                ? <> {!!(getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)) && ` | `} {
                  genres_with_translations?.map((genre, index) => (
                    <React.Fragment key={index}>
                      {genre[iso_2?.toLowerCase()]}
                      {genres_with_translations?.length - 1 !== index && ","}
                      &nbsp;
                    </React.Fragment>
                  ))
                }</>
                : <></>
              }
              {!!rating && ` | ${rating}`}
              {original_release_date && (` | ${moment(original_release_date).format("YYYY")}`)}
            </span>
          </div>
        </div>

        <div className="">
          <div className="tens">
          {!!OnDemand && <button
            
            className={`btn-main tag yellow`}
            onClick={() => {
              dispatch(clearMovieSchedules())
              .then(() => {
                dispatch(clearMovieDetails())  // not async
                dispatch(clearNewOndemandState()) // not async
                return dispatch(clearSeatSelection())
              })
              .then(() => {
                return dispatch(clearFnbState())
              })
              .then(() => {
                history.push(`/private-booking-flow/${OnDemand?.movie_id}/${OnDemand?.content_lang_id}/-/-/${uuidv4()}`)
              })
            }}
          >
            {t(`Private Cinema`)}
          </button>}
          {
            !!OnDemand && (
              <p className="genre text-limit-2">
                {OnDemand?.languages_with_translations?.filter(l => l?.md_is_active == 'Y')?.filter(l => {
                  if (loggedInUser)
                    return true
                  else
                    return l?.is_movie_club == 'N'
                })?.map((l, index) => (
                  <button
                    key={index}
                    className="btn_change mx-1 mb-1"
                    onClick={() => {
                      console.log(movieLanguagesWithSubs(t(l?.lang_name) || l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2))

                      dispatch(clearMovieSchedules())
                      .then(() => {
                        dispatch(clearMovieDetails())  // not async
                        dispatch(clearNewOndemandState()) // not async
                        return dispatch(clearSeatSelection())
                      })
                      .then(() => {
                        return dispatch(clearFnbState())
                      })
                      .then(() => {
                        history.push(`/private-booking-flow/${OnDemand?.movie_id}/${OnDemand?.content_lang_id}/${
                          encodeURIComponent(
                            movieLanguagesWithSubs(l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2)
                          )
                        }/${l?.md_id ? l?.md_id : '-'}/${uuidv4()}`)
                      })
                    }}
                  >
                    {/*{l?.iso_2?.slice(0, 2) || l?.iso_3?.slice(0, 2) || l?.lang_name.slice(0, 2)}*/}
                    {movieLanguagesWithSubs(l?.iso_3 || l?.iso_2 || t(l?.lang_name), l?.sub_1_iso_2, l?.sub_2_iso_2)}
                  </button>))}
              </p>
            )
          }
            {
              !!NowShowing && (<>
                <div>
                  <button
                    className="btn-main tag red"
                    onClick={() => {
                      dispatch(clearMovieSchedules())
                      .then(() => {
                        dispatch(clearMovieDetails())  // not async
                        dispatch(clearNewOndemandState()) // not async
                        return dispatch(clearSeatSelection())
                      })
                      .then(() => {
                        return dispatch(clearFnbState())
                      })
                      .then(() => {
                        history.push(`/now-showing-booking/${NowShowing?.movie_id}/${NowShowing?.content_lang_id}/-/-/${uuidv4()}`)
                      })
                    }}
                  >
                    {
                      (
                        !original_release_date ||
                        (!!original_release_date && moment(original_release_date).isSameOrBefore(moment()))
                      )
                        ? t(`Now Showing`)
                        : t(`Bookings Open`)
                    }
                  </button>
                </div>

                <p className="" style={{marginBottom:"8px"}}> {/*class name should be genre text-limit-2 */}
                  {NowShowing?.languages_with_translations?.filter(l => l?.ss_id != null)?.map((l, index) => (
                    <button
                      key={index}
                      className="btn_change mx-1 mb-1"
                      onClick={() => {
                        console.log(movieLanguagesWithSubs(t(l?.lang_name) || l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2))

                        dispatch(clearMovieSchedules())
                        .then(() => {
                          dispatch(clearMovieDetails())  // not async
                          dispatch(clearNewOndemandState()) // not async
                          return dispatch(clearSeatSelection())
                        })
                        .then(() => {
                          return dispatch(clearFnbState())
                        })
                        .then(() => {
                          history.push(`/now-showing-booking/${NowShowing?.movie_id}/${NowShowing?.content_lang_id}/${
                            encodeURIComponent(
                              movieLanguagesWithSubs(l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2)
                            )
                          }/${l?.md_id ? l?.md_id : '-'}/${uuidv4()}`)
                        })
                      }}
                    >
                      {movieLanguagesWithSubs(l?.iso_3 || l?.iso_2 || t(l?.lang_name), l?.sub_1_iso_2, l?.sub_2_iso_2)}
                    </button>))}
                </p>
              </>)
            }
            {
              !!Upcoming && !NowShowing && (<>
                <div>
                  <button
                    className="btn-main tag red"
                    onClick={() => {
                      dispatch(clearMovieSchedules())
                      .then(() => {
                        // dispatch(clearMovieDetails())  // not async
                        dispatch(clearNewOndemandState()) // not async
                        return dispatch(clearSeatSelection())
                      })
                      .then(() => {
                        return dispatch(clearFnbState())
                      })
                      .then(() => {
                        if (Upcoming?.now_demand_count > 0)
                          history.push(`/private-booking-flow/${Upcoming?.movie_id}/${Upcoming?.content_lang_id}/-/-/${uuidv4()}`)
                        else
                          history.push(`/movie-details/${Upcoming?.movie_id}/${Upcoming?.content_lang_id}/3`)
                      })
                    }}
                  >
                    {t(`Upcoming`)}
                  </button>
                </div>

                <p className=""> {/*class name should be genre text-limit-2 */}
                  {Upcoming?.languages_with_translations?.map((l, index) => (
                    <button
                      key={index}
                      className="btn_change mx-1 mb-1"
                      onClick={() => {
                        console.log(movieLanguagesWithSubs(t(l?.lang_name) || l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2))

                        dispatch(clearMovieSchedules())
                        .then(() => {
                          // dispatch(clearMovieDetails())  // not async
                          dispatch(clearNewOndemandState()) // not async
                          return dispatch(clearSeatSelection())
                        })
                        .then(() => {
                          return dispatch(clearFnbState())
                        })
                        .then(() => {
                          if (Upcoming?.now_demand_count > 0)
                            history.push(`/private-booking-flow/${Upcoming?.movie_id}/${Upcoming?.content_lang_id}/${
                              encodeURIComponent(
                                movieLanguagesWithSubs(l?.lang_name, l?.sub_1_iso_2, l?.sub_2_iso_2)
                              )
                            }/${l?.md_id ? l?.md_id : '-'}/${uuidv4()}`)
                          else
                            history.push(`/movie-details/${Upcoming?.movie_id}/${Upcoming?.content_lang_id}/3`)
                        })
                      }}
                    >
                      {movieLanguagesWithSubs(l?.iso_3 || l?.iso_2 || t(l?.lang_name), l?.sub_1_iso_2, l?.sub_2_iso_2)}
                    </button>))}
                </p>
              </>)
            }
          </div>         
        </div>
      </div>
    </div>
  </div>);
};

export default withNamespaces()(HorizontalCard);
