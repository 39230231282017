import React from "react";
import { useHistory,Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setStage } from "../../store/movies/movieActions";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { BASEURL } from "../../config";
import { checkIfURLSame, getLabelToshow } from "@helper/formatting";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";
import { OverlayTrigger,Popover,Button } from "react-bootstrap";

// Images
import poster from "@assets/pictures/sample-poster-new.png";
const _ = require("lodash");

const MovieCardNew = (props) => {
  const { slider, onClick, classes, isUpcoming = false, now_demand_count = 0 } = props;
  const { pathname } = useLocation();
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_id } = selected_language;

  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const abc = () => { };
  const { iso_2 } = selected_language;
  const history = useHistory();
  const t = props.t;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const labelsToShow = getLabelToshow(props?.label_to_show);

  return (<>
    <motion.article
      className={`${slider ? "slider_spacing" : ""} ${classes}`}
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
      title={
        getLangSpecificAttribute(
          props?.altMovieContent,
          lang_id,
          "mc_title"
        ) || props?.movie_title || props?.mc_title
      }
    >
      <div className={`${"movie_card_new"} `} onClick={onClick}>{/*has-tooltip previous class for on hover button*/}
        {/* Image */}
      
        <figure>
          {isUpcoming && props?.mrrdr_release_date && <div className="movie_card_new_label_wrapper">
            <span className="movie_card_new_label_background" />
            <span className="movie_card_new_label">
              {!isMobile && t("Releasing")} {moment(props?.mrrdr_release_date).format("DD MMM YYYY")}
            </span>
          </div>
          }
          <img
            src={
              getLangSpecificAttribute(
                props?.languageSpecificImages || props?.altMovieContent,
                lang_id,
                "artwork"
              ) || dummyPoster
            }
            className="img-fluid"
            alt={t("alt.Movie Poster")}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = dummyPosterStatic
            }
          }
          />
        </figure>
      
        {/*Tool tip on hover */}
        {/*{
          !!isUpcoming && (
          <p className="tooltip-new">
           
       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

        {/* Label */}
        {!isUpcoming && labelsToShow?.length > 0 && (
          <div className="movie_card_new_label_wrapper">
          <span className="movie_card_new_label_background" />
          <span className="movie_card_new_label">{getLangSpecificAttribute(
            labelsToShow,
            lang_id,
            "label_name",
            "lang_id"
          ) || labelsToShow[0]?.label || ''}</span>
          </div>
         )}
      </div>
      {isUpcoming && now_demand_count > 0 &&
        <div className="parent_up_btn">
          <button
            className="up_btn"
            onClick={onClick}
          >
            {t('Create private screening')}
          </button>
        </div>
      }
    </motion.article>
  </>);
}

// Default Props
MovieCardNew.defaultProps = {
  img: poster,
  slider: false,
  classes: "",
  onClick: () => { },
};

export default withNamespaces()(MovieCardNew);
