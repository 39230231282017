import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useLayoutEffect,
} from "react";
import {
  fetchFormatList,
  fetchLanguageList,
  quickBook,
  quickBookMovieList,
  fetchFilters,
  setQbStage,
  setFilter,
} from "@store/homepage/homepageActions";
import { setStage, createShow } from "@store/movies/movieActions";
import { Link } from "react-router-dom";
import search from "@assets/pictures/svgs/search.svg";
import { withNamespaces } from "react-i18next";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import filterImg from "@assets/pictures/svgs/filter-solid.svg";
import debounce from "lodash/debounce";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useIsMobile from "../customHooks/useIsMobile";
import close from "@assets/pictures/svgs/close.svg";
import { getLangSpecificAttribute } from "@helper/languages";
import MoviesNFestsNew from "@components/partials/MoviesNFestsNew";

import SpinnerCompo from "../components/partials/Spinner";
import OnDemandInfo from "@components/partials/OnDemandInfo"
import { useGetBannersQuery  } from "@store/rtk/rtk.banner";
import {
  useGetNowShowingQuery,
  useGetOnDemandQuery,
  useGetUpcomingQuery,
  useGetMiniFestivalQuery,
  useGetFestivalListQuery,
} from "@store/rtk/rtk.movieLists";

// const LazyOnDemandInfo = React.lazy(() => import('../components/partials/OnDemandInfo'))
// import HomeBanner from "@components/partials/Banner";
const LazyHomeBanner = React.lazy(() => import('../components/partials/Banner'))

const HomeContainer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, } = loginDetails;

  const { data: banners = [] } = useGetBannersQuery()
  const { data: comingSoonMovies, isLoading: comingSoonLoading } = useGetUpcomingQuery()
  const { data: createOwnShowMovies, isLoading: onDemandLoading } = useGetOnDemandQuery({ limit: 1000, currentPage: 1, isUserLogged: loggedInUser ? 'Y' : 'N' })
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } = useGetNowShowingQuery({ limit: 1000, currentPage: 1, })
  const { data: miniFestivalsData, isLoading: miniFestivalsLoading } = useGetMiniFestivalQuery()
  const { data: festivals, isLoading: festivalsLoading } = useGetFestivalListQuery()

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };
  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const cms_settings = useSelector((state) => state.homepage.cms_settings);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectFilter = (filter) => {
    dispatch(setFilter(filter));
  };
  const onSubmitFilter = () => {};
  const qbStage = useSelector((state) => state.homepage.qbStage);

  const filters = useSelector((state) => state.homepage.filters);
  const [qbSelectedLanguage, setQbSelectedLanguage] = useState(null);
  const [qbSelectedMovie, setQbSelectedMovie] = useState(null);
  const [qbSelectedFormat, setQbSelectedFormat] = useState(null);
  const [qbSelectedDate, setQbSelectedDate] = useState(null);
  const [qbSelectedTime, setQbSelectedTime] = useState(null);
  const [qbSelectedCinema, setQbSelectedCinema] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    dispatch(fetchFormatList());
    dispatch(fetchLanguageList());
    dispatch(quickBookMovieList({ type: `quickBook` }));
    dispatch(fetchFilters());
  }, []);

  const onClickQbFormat = (format) => {
    dispatch(setQbStage(3));
    setQbSelectedFormat(format);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: format.mf_id,
        qbStage,
      })
    );
  };
  const onClickQbLanguage = (language) => {
    dispatch(setQbStage(2));
    setQbSelectedLanguage(language);
    setQbSelectedFormat(null);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: language.lang_id,
        qbStage,
      })
    );
  };
  const onClickQbCinema = (cinema) => {
    dispatch(setQbStage(4));

    setQbSelectedCinema(cinema);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: qbSelectedFormat.mf_id,
        cinema_id: cinema.cine_id,
        qbStage,
      })
    );
  };

  const onClickQuickBook = () => {
    // console.log(qbSelectedTime);
    // console.log(qbSelectedDate);
    // console.log(qbSelectedMovie);
    const movie = quickBookMovies.find(
      (movie) => movie.movie_id === qbSelectedMovie?.movie_id
    );
    // console.log(
    //   "🚀 ~ file: mainHomeContainer.jsx ~ line 310 ~ onClickQuickBook ~ movie",
    //   movie
    // );
    const payload = {
      mrrdr_runtime: movie?.mrrdr_runtime,
      cinema_ss_id: qbSelectedTime?.cinema_ss_id,
      start_time: qbSelectedTime?.dateShow,
      schedule_date: qbSelectedDate?.dateShow,
    };
    dispatch(createShow(payload, history));
  };
  const onClickQbDate = (date) => {
    dispatch(setQbStage(5));
    setQbSelectedDate(date);
    setQbSelectedTime(null);
    dispatch(
      quickBook({
        movie_id: qbSelectedMovie.movie_id,
        lang_id: qbSelectedLanguage.lang_id,
        mf_id: qbSelectedFormat.mf_id,
        cinema_id: qbSelectedCinema.cine_id,
        fromDate: date.dateShow,
        qbStage,
      })
    );
  };
  const onClickQbTime = (time) => {
    dispatch(setQbStage(6));
    setQbSelectedTime(time);
    // qbStage
  };

  const isMobile = useIsMobile();
  const searchResult = useSelector((state) => state.homepage.searchMovies);

  const onChangeSearch = (search) => {
    setSearchedTerm(search);
    searchMovieCallback(search);
  };
  const moviesState = useSelector((state) => state.homepage);
  const {
    quickBookMovies,
    quickBookLanguages,
    quickBookFormats,
    quickBookCinemas,
    quickBookDates,
    quickBookTimes,
  } = moviesState;
  /************************* */
  const [searchTerm, setSearchedTerm] = useState("");
  const searchMoviesLoading = useSelector(
    (state) => state.homepage.searchMoviesLoading
  );
  const searchMovieCallback = useCallback(
    debounce((searchTerm2) => {
      dispatch(quickBookMovieList({ search: searchTerm2, type: `quickBook` }));
    }, 1000),
    []
  );

  const onClickQbMovie = (movie) => {
    dispatch(setQbStage(1));
    setQbSelectedMovie(movie);
    setQbSelectedLanguage(null);
    setQbSelectedFormat(null);
    setQbSelectedCinema(null);
    setQbSelectedDate(null);
    setQbSelectedTime(null);
    dispatch(quickBook({ movie_id: movie.movie_id, qbStage }));
  };
  const [showFilterModal, setShowFilterModal] = useState(false);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;

  // useEffect for setting quickbook cinema if only one cinema is there
  useEffect(() => {
    if (qbStage === 3 && quickBookCinemas && quickBookCinemas.length === 1) {
      onClickQbCinema(quickBookCinemas[0]);
    }
  }, [quickBookCinemas]);

  function handleInputFocus() {
    if (!isMobile) {
      let el = document.querySelector(".inputWrapper.qb_container > aside");
      if (el) {
        el.addEventListener("click", function (e) {
          document.activeElement.blur();
        });
      }
      // document
      //   .querySelector(".inputWrapper.qb_container > aside")
      //   .addEventListener("click", function (e) {
      //     console.log("event listener");
      //     document.activeElement.blur();
      //   });
    }
  }

  useEffect(() => {
    handleInputFocus();
  }, []);

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (cms_settings && cms_settings.length > 0) {
        if (cms_settings[0][item] == "Y") {
          return true;
        } else if (cms_settings[0][item] == "N") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  function btnMarginHandler() {
    if (window.innerWidth > 767) {
      const btn = document.querySelector(".qb_box .btn-main");
      const box = document.querySelector(".qb_box");
      if (btn && box) {
        if (box.clientHeight > 100) {
          // console.log("height", box.scrollHeight);
          btn.style.margin = "0 auto";
        } else {
          btn.style.margin = "";
        }
      }
    }
  }

  useEffect(btnMarginHandler, []);
  window.addEventListener("resize", function () {
    btnMarginHandler();
  });

  useEffect(() => {
    if (filters) {
      filters.forEach((filterCategory) => {
        if (
          filterCategory.filter_key &&
          filterCategory.filter_key === "movie_format"
        ) {
          if (filterCategory.all_filter.length > 2) {
            //assuming first filter will be "All"
            setShowFilters(true);
          } else {
            setShowFilters(false);
          }
        } else {
          setShowFilters(false);
        }
      });
    } else {
      setShowFilters(false);
    }
  }, [filters]);

  return (
    <div>
      <div className="px-0">
        {/* FILTER BUTTON */}
        {false && isItemActive("Show Filters") && showFilters && (
          <div className="filter_btn" onClick={() => setShowFilterModal(true)}>
            <img src={filterImg} height="30px" width="30px" alt="" />
          </div>
        )}
        {/* Hero Section Starts */}

        <div className="">
          {/*<HomeBanner banners={banners} />*/}
          <React.Suspense fallback={(<div className="row">
            <div className="col-12 text-center">
              <SpinnerCompo/>
            </div>
          </div>)}>
            <LazyHomeBanner banners={banners} />
          </React.Suspense>

          {/* HIDE QUICK BOOK */}
          {false && <div className="col-md-12 col-lg-12 ">
            {isItemActive("show_quick_book") && !isMobile ? (
              <>
                <div className="qb_box">
                  <article>
                    {/* <div className="dropdown-bg">
                        <Dropdown className="">
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            id="dropdown-basic"
                          >
                            Enter Movie Name
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdownItems">
                            <Dropdown.Item href="#/action-1" className="">
                              ENGLISH
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2" className="">
                              SANSKRIT
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              ARAB
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}

                    {/* <div className="dropdown-bg"> */}
                    <div className="inputWrapper qb_container">
                      <img
                        src={search}
                        alt="search"
                        className="search-svg"
                        onClick={(e) => e.target.nextElementSibling.focus()}
                      />


                      {/* <div className="dropdown-toggle-icon position-relative h-100"> */}
                      <input
                        type="text"
                        className=""
                        placeholder={
                          qbSelectedMovie
                            ? qbSelectedMovie.movie_title
                            : t("Search Movie")
                        }
                        value={searchTerm}
                        onChange={(e) => {
                          onChangeSearch(e.target.value);
                        }}
                      />
                      <img
                        src={close}
                        alt="Clear"
                        className={`clear-svg ${searchTerm ? "show" : ""}`}
                        onClick={() => setSearchedTerm("")}
                      />
                      {/* </div> */}

                      <aside className="">
                        {quickBookMovies?.length > 0 ? (
                          quickBookMovies.map((search, index) => (
                            <SearchedMovie
                              type="yellow"
                              movie={search}
                              key={index}
                              onChangeSearch={onChangeSearch}
                              onClick={(movie) => onClickQbMovie(movie)}
                              quickBook={true}
                            />
                          ))
                        ) : searchTerm && searchResult?.length === 0 ? (
                          <h6 className="mx-auto mb-0">
                            {t("common.No movies found")}
                          </h6>
                        ) : searchMoviesLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="mx-auto"
                          />
                        ) : null}
                      </aside>
                    </div>
                    {/* </div> */}
                  </article>

                  <article>
                    <div className="dropdown-bg ">
                      <Dropdown>
                        <Dropdown.Toggle
                          className={`dropdownClassic ${!qbSelectedMovie && "inactive"
                            }`}
                          id="dropdown-basic "
                        >
                          {qbSelectedLanguage
                            ? qbSelectedLanguage.lang_name
                            : t("common.Language")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdownItems ">
                          {quickBookLanguages?.map((language, index) => (
                            <Dropdown.Item
                              className={
                                qbSelectedLanguage?.lang_id ===
                                language.lang_id && "selected"
                              }
                              key={index}
                              onClick={() => onClickQbLanguage(language)}
                            >
                              {language.lang_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>
                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${!qbSelectedLanguage && "inactive"
                            }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedFormat
                            ? qbSelectedFormat.mf_name
                            : t("home_page.Version")}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdownItems align-right">
                          {quickBookFormats?.map((format, index) => (
                            <Dropdown.Item
                              className={`${qbSelectedFormat?.mf_id === format.mf_id &&
                                "selected"
                                }`}
                              key={index}
                              onClick={() => onClickQbFormat(format)}
                            >
                              {format.mf_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${!qbSelectedFormat && "inactive"
                            }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedCinema
                            ? qbSelectedCinema.cine_name
                            : t("Cinema")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems">
                          {quickBookCinemas?.map((cine, index) => (
                            <Dropdown.Item
                              onClick={() => onClickQbCinema(cine)}
                              key={index}
                              className={`${qbSelectedCinema?.cine_id === cine.cine_id &&
                                "selected"
                                }`}
                            >
                              {cine.cine_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${!qbSelectedCinema && "inactive"
                            }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedDate
                            ? moment(
                              qbSelectedDate.dateFormat,
                              `YYYY-MM-DD`
                            ).format(`ll`)
                            : t("Date")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems">
                          {quickBookDates?.map((date, index) => (
                            <Dropdown.Item
                              onClick={() => onClickQbDate(date)}
                              key={index}
                              className={`${qbSelectedDate?.ss_start_date ===
                                date.ss_start_date && "selected"
                                }`}
                            >
                              {moment(date.dateFormat, `YYYY-MM-DD`).format(
                                `ll`
                              )}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article>
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className={`dropdownClassic ${!qbSelectedDate && "inactive"
                            }`}
                          id="dropdown-basic"
                        >
                          {qbSelectedTime
                            ? qbSelectedTime.dateRange
                            : t("Time")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownItems align-right">
                          {quickBookTimes?.map(
                            (time, index) =>
                              time.dateRange > moment().format("HH:mm") && (
                                <Dropdown.Item
                                  onClick={() => onClickQbTime(time)}
                                  key={index}
                                  className={`${qbSelectedTime?.dateRange ===
                                    time.dateRange && "selected"
                                    }`}
                                >
                                  {time.dateRange}
                                </Dropdown.Item>
                              )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </article>

                  <article className="d-flex">
                    <button
                      onClick={onClickQuickBook}
                      className={`btn-main btn-main-lg ${!qbSelectedTime && `inactive`
                        }`}
                    >
                      {t("quick-book")}
                    </button>
                  </article>
                </div>
              </>
            ) : (
              <>
                {/* <div className="mx-auto">
                    <p className="mbl-filter">
                      <svg
                        className="mr-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.626 3.60938H3.37128C2.79707 3.60938 2.43847 4.23516 2.72675 4.73438L8.17832 14.0016V19.6406C8.17832 20.0555 8.51113 20.3906 8.92363 20.3906H15.0736C15.4861 20.3906 15.8189 20.0555 15.8189 19.6406V14.0016L21.2728 4.73438C21.5588 4.23516 21.2002 3.60938 20.626 3.60938ZM14.1408 18.7031H9.85644V15.0469H14.1432V18.7031H14.1408ZM14.3658 13.1578L14.1432 13.5469H9.8541L9.63144 13.1578L4.98378 5.29688H19.0135L14.3658 13.1578Z"
                          fill="white"
                        />
                      </svg>
                      Filter
                    </p>
                  </div> */}
              </>
            )}
          </div>}
          {/* <div className="container-fluid container-xl">
            <div className="row selects-row">
              
            </div>
          </div> */}
        </div>
        {/* Hero Section Ends */}

        <section className="container-fluid container-xl pt-2" style={{overflowX:isMobile ? "hidden" : undefined}}>
          {isItemActive("show_mini_festivals") && festivals?.data?.length > 0 && (
            <MoviesNFestsNew  section={3} moviesArray={festivals?.data} subCategory={''} category={t("Mini Festivals and Events")} movieCount={festivals?.data?.length} slider
              type="mini_festival" showFestivalList={{}}
            />
          )}

          {todaysScreeningsMovies?.data?.length > 0 && (
            <MoviesNFestsNew
              section={1}
              moviesArray={todaysScreeningsMovies?.data}
              subCategory={""}
              category={t("Now Showing")}
              movieCount={todaysScreeningsMovies?.data?.length}
              type="now_showing"
              // slider
              showMovieList={{category_id: 1}}
            />
          )}

          {isItemActive("show_on_demand") && uniqueCreateOwnShowMovies?.data?.length > 0 && (
            <OnDemandInfo />
          )}

          {/*<React.Suspense fallback={(<div className="row">
            <div className="col-12 text-center">
              <SpinnerCompo/>
            </div>
          </div>)}>
            <LazyOnDemandInfo />
          </React.Suspense>*/}

          {isItemActive("show_on_demand") && miniFestivalsData?.Records?.length > 0 && miniFestivalsData?.Records?.map((category, index) => (
            category?.movie_data?.data?.length > 0 && (
              <MoviesNFestsNew
                key={index}
                section={2}
                moviesArray={category?.movie_data?.data}
                subCategory={
                  getLangSpecificAttribute(
                  category?.language_content,
                  lang_id,
                  "event_name",
                  "lang_id"
                ) || category?.category_link_name
                }
                category={t("On Demand")} type="on_demand"
                movieCount={category?.movie_data?.data?.length}
                slider
                showMovieList={{category_id: 4, section_id: category?.unique_id}}
              />
            )
          ))}

          {isItemActive("show_on_demand") && uniqueCreateOwnShowMovies?.data?.length > 0 && (
            <MoviesNFestsNew
              section={2}
              type="on_demand"
              moviesArray={uniqueCreateOwnShowMovies?.data}
              subCategory={"All"}
              category={t("On Demand")}
              movieCount={uniqueCreateOwnShowMovies?.data?.length}
              slider
              showMovieList={{category_id: 2}}
            />
          )}

          {isItemActive("show_upcoming") && uniqueComingSoonMovies?.data?.length > 0 && (
            <MoviesNFestsNew
              section={4}
              moviesArray={uniqueComingSoonMovies?.data}
              subCategory={""}
              category={t("Upcoming")}
              movieCount={uniqueComingSoonMovies?.data?.length}
              type="show_upcoming"
              slider
              showMovieList={{category_id: 3}}
            />
          )}
        </section>
      </div>

      {/* POPUP START */}
      <Modal
        show={showFilterModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="show-details-popup"
        centered
        onHide={() => setShowFilterModal(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => {
              setShowFilterModal(false);
              // if (!selectedFilterLanguage) {
              //   setSelectedFilterLanguage2(null);
              // }
              // if (!selectedFilterFormat) {
              //   setSelectedFilterFormat2(null);
              // }
            }}
            onHide={() => setShowFilterModal(false)}
            alt=""
          />

          {filters?.map((filter, index) => (
            <div className="row" key={index}>
              {filter?.show && (
                <div className="px-3 w-100">
                  <h6 className="sub-heading">
                    {filter?.query_id === "lang_id"
                      ? t("Choose Language")
                      : filter?.query_id === "mf_id"
                        ? t("Choose Format")
                        : t(filter?.title)}
                  </h6>
                  <div className="btn-main-group">
                    {filter?.typeArray?.map((type, typeIndex) => (
                      // add class active in button element when selected
                      t(type?.type_name) && <button
                        className={`tile ${type?.selected && "active"}`}
                        key={typeIndex}
                        onClick={() =>
                          onSelectFilter({
                            query_id: filter.query_id,
                            type_id: type.type_id,
                            index,
                            typeIndex,
                          })
                        }
                      >
                        {t(type?.type_name)}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="row pt-2">
            <div className="px-3 mt-4 mx-auto">
              {/* add class inactive when it is not selectable */}
              <button
                className="btn-main btn-main-lg"
                onClick={() => onSubmitFilter()}
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </section>
      </Modal>
      {/* POPUP END */}
    </div>
  );
};

const SearchedMovie = ({
  // type = "yellow",
  onChangeSearch,
  onClick,
  movie: {
    movie_title,
    movie_id,
    content_lang_id,
    genre_id,
    category_id,
    showType,
  },
}) => {
  let type = "yellow";
  if (showType === "On Demand") {
    type = "yellow";
  } else if (showType === "Book Now") {
    type = "green";
  } else if (showType === "Now Showing") {
    type = "red";
  }
  return (
    <Link
      // to={`/movie-details/${movie_id}/${content_lang_id}/${category_id}/${genre_id}`}
      className="searched-movie-link"
    >
      <div
        className="searched-movie py-1 mb-0"
        onClick={() => {
          onChangeSearch("");
          // history.push();
          onClick({ movie_title, movie_id });
        }}
      >
        <div className="movie-content">
          <div className="d-none d-md-block d-lg-block">
          </div>
          <p className="movie-name">{movie_title}</p>
        </div>
      </div>
    </Link>
  );
};
export default withNamespaces()(HomeContainer);
