import React, { useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import useIsMobile from "@src/customHooks/useIsMobile";
import { getLangSpecificAttribute } from "@helper/languages";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

// Components
import Slider from "react-slick";

// Images
import placeholderBanner from "@assets/pictures/banner-placeholder-new.png";
import reel from "@assets/pictures/svgs/reel.svg";
import { movieLanguagesWithSubs } from "@helper/formatting";
import { useGetBannersQuery } from "@store/rtk/rtk.banner";

function BannerShowTypeHeading(props) {
  let { showOnly, pathname, banner, t } = props;
  let { now_demand_count, now_showing_count } = banner || {}
  let isGenericBanner = banner?.banner_type_id == 2
  let isNowShowing = banner?.movie_id && now_showing_count > 0
  let isOnDemand = banner?.movie_id && now_demand_count > 0

  // console.log({banner, isGenericBanner, isOnDemand, isNowShowing, header: banner?.banner_header})

  let heading = <></>

  if (pathname === "/now-showing" || pathname === "/new-on-demand" || pathname === "/upcoming")
    return heading

  if (!isGenericBanner) {
    if(isOnDemand){
      heading = (
        <h6 className="text-warning">{t('On-demand')}</h6>
      )
    }
    if(banner?.movie_banner_type_id?.split(",")?.includes("3") && showOnly !== 1 && showOnly !== 2) {
      heading = (
        <h6>{t("Upcoming")}</h6>
      )
    }
    if(isNowShowing){
      heading = (
        <h6>{t("Now Showing")}</h6>
      )
    }
  }

  /*return <>
    {
      banner &&
      banner?.movie_id &&
      !(pathname === "/now-showing" || pathname === "/new-on-demand" || pathname === "/upcoming") && (
      banner?.movie_banner_type_id?.split(",")?.includes("1") && showOnly !== 2 && showOnly !== 3
        ? (
            <h6>{t("Now Showing")}</h6>
        )
        : banner?.movie_banner_type_id?.split(",")?.includes("2") && showOnly !== 1 && showOnly !== 3
          ? (
              <h6 className="text-warning">{t('On-demand')}</h6>
          )
          : banner?.movie_banner_type_id?.split(",")?.includes("3") && showOnly !== 1 && showOnly !== 2
            ? (
              <h6>{t("Upcoming")}</h6>
            ) : (
              <></>
            )
    )}
  </>;*/

  return heading
}

export function getHeroBlurBackStyle(banner, lang_id, isMobile, fallbackImg = undefined) {
  if (isMobile) return {}

  return {
    cursor: 'pointer',
    backdropFilter: "blur(90px)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `${
      banner?.fade === "N"
        ? ""
        : "linear-gradient( 359.67deg, var(--dark-1) 0.29%, rgba(23, 22, 29, 0) 99.71% ), "
    } linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.81)), url(${
      fallbackImg ||
      getLangSpecificAttribute(
        banner?.languageSpecificImages,
        lang_id,
        "banner_image_url",
        "lang_id"
      ) ||
      placeholderBanner
    })`
  };
}

export function getHeroBlurBackDimensions(isMobile, isMiniFest = false) {
  return !isMobile
    ? { width: isMiniFest ? "100%" : "100%", marginInline: "auto" }
    : {};
}

const Banner = (props) => {
  const { t, banners, isLoading, showOnly = null } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;
  const {pathname} = useLocation();

  // STATE
  const [swiping, setSwiping] = useState(false);

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {isLoading: bannersLoading } = useGetBannersQuery()

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleBannerClick = (banner, lang = null, md_id = null) => {
    if (swiping) {
      setSwiping(false);
    } else {
      if (banner?.banner_type_id != 2) {
        if (banner?.now_showing_count > 0) {
          history.push(`/now-showing-booking/${banner?.movie_id}/${banner?.content_lang_id}/${!!lang ? lang : 'All'}/${!!md_id ? md_id : '-'}`);
        }
        else if (banner?.movie_banner_type_id?.split(',')?.includes('3') && showOnly !== 1) {
          history.push(`/movie-details/${banner?.movie_id}/${banner?.content_lang_id}/3`)
        }
        else if (banner?.now_demand_count > 0) {
          history.push(`/private-booking-flow/${banner?.movie_id}/${banner?.content_lang_id}/${!!lang ? lang : 'All'}/${!!md_id ? md_id : '-'}`);
        }
        else {
          history.push(`/movie-details/${banner?.movie_id}/${banner?.content_lang_id}/3`)
        }
      }
      else if (banner?.banner_generic_url) {
        window.location.href = (banner.banner_generic_url)
      }
    }
  }


  return (
    <>
      <Slider {...heroSlider} className="home_banner">
        {banners?.length > 0 && banners?.map((banner, index) => {
          return (
            <div className="bannersMapDiv" key={index}>
              <div
                className="blur"
                style={getHeroBlurBackStyle(banner, lang_id, isMobile)}
                onClick={() => handleBannerClick(banner)}
              >
                <section className="hero-section"
                         initial={{ opacity: 0 }}
                         animate={{ opacity: 1 }}
                         transition={{ duration: 0.3 }}
                         style={getHeroBlurBackDimensions(isMobile)}
                >
                  <article className={`hero-img-section`}>
                    <img
                      src={getLangSpecificAttribute(
                        banner?.languageSpecificImages,
                        lang_id,
                        "banner_image_url",
                        "lang_id"
                      ) || placeholderBanner}
                      alt="Movie Banner"
                      className="hero-img-1"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = placeholderBanner;
                      }}
              
                    />
                  </article>
                  <article className={`hero-text align-items-end ${banner?.fade === "N" ? "home_fade_none" : ""}`}
                  >
                    <div className="container-fluid">
                      <BannerShowTypeHeading
                        banner={banner}
                        pathname={pathname}
                        showOnly={showOnly}
                        t={t}
                      />
                      <h1>
                        {getLangSpecificAttribute(
                          banner?.languageSpecificImages,
                          lang_id,
                          "banner_header",
                          "lang_id"
                        )}
                      </h1>
                      {!isMobile && banner && banner?.banner_type_id == 2 && (
                        <p
                          className="text-limit-1 mb-md-3"
                          id={`desc${index}`}
                        >
                          {/* {banner?.banner_subheader} */}
                          {getLangSpecificAttribute(
                            banner?.languageSpecificImages,
                            lang_id,
                            "banner_subheader",
                            "lang_id"
                          ) || banner?.banner_subheader}
                        </p>
                      )}
                      {!isMobile && banner?.banner_type_id != 2 && <div className={
                        `home_banner_movie_versions ${
                          (
                            // !(banner?.movie_banner_type_id?.split(',')?.includes('1')) &&
                            // banner?.movie_banner_type_id?.split(',')?.includes('2') && showOnly !== 1
                            banner?.now_showing_count == 0 &&
                            banner?.now_demand_count > 0 &&
                            !banner?.movie_banner_type_id?.split(",")?.includes("3")
                          ) ?
                            "home_banner_movie_versions_on_demand"
                            : ""
                        }`
                      }>
                        {banner?.languages_with_translations?.filter((item, index, arr) => {
                          if (!!banner?.md_ids && !!item.md_id && banner?.now_demand_count > 0) {
                            let ss_md_ids = banner?.md_ids.split(",").map(x => parseInt(x, 10));

                            return !!ss_md_ids.includes(item.md_id);
                          } else
                            return true;
                        })?.map((item, index, arr) => {
                          return (
                            <span
                              key={index}
                              onClick={
                                (e) => {
                                  e.stopPropagation();
                                  handleBannerClick(
                                    banner,
                                    encodeURIComponent(movieLanguagesWithSubs(item?.lang_name, item?.sub_1_iso_2, item?.sub_2_iso_2)),
                                    item?.md_id
                                  );
                                }}
                            >
                          {movieLanguagesWithSubs(t(item?.lang_name) || item?.lang_name, item?.sub_1_iso_2, item?.sub_2_iso_2)}
                        </span>
                          );
                        })}
                      </div>}
                    </div>
                  </article>
                </section>
              </div>
            </div>
          );
        })}


        {(!!bannersLoading || (!banners || banners?.length === 0)) && (<>
          <div className="">
            <section className="hero-section" onClick={() => { }}>
              <article className="hero-img-section">
                <img
                  src={placeholderBanner}
                  alt="Movie Banner"
                  className="hero-img-1"
                />
              </article>
              <article className="hero-text align-items-end"
              >
                <div className="container-fluid">
                  <div className="container-fluid container-xl text-center">
                    <section className="placeholder-banner">
                      <figure className="">
                        <img src={reel} alt="" />
                      </figure>
                      <p className="">
                        {/*<img src={clockWhite} alt="" className="mr-2" />*/}
                        {/*{t("Loading")} ...*/}
                      </p>
                    </section>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </>)}
      </Slider>
    </>
  );
}

export default withNamespaces()(Banner);
