import React, { useEffect, useState, useContext, useRef } from "react";
import { Container, Row, div, Dropdown, Modal } from "react-bootstrap";
import MobileBottomMenu from "@components/partials/MobileBottomMenu.jsx";
import { useHistory, useLocation, Link } from "react-router-dom";
import { CityService, LanguageService } from "@apiService/tokenService";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";
import i18n from "@plugins/i18n";
import { motion } from "framer-motion";
import GenericService from "@apiService/GenericService";
import { useDispatch, useSelector } from "react-redux";

// for mobile-bottom-menu
// import homeIcon from "@assets/pictures/svgs/home.svg";
// import moviesIcon from "@assets/pictures/svgs/movies.svg";
// import transactionIcon from "@assets/pictures/svgs/transaction.svg";
// import profileIcon from "@assets/pictures/svgs/profile1.svg";
import {ReactComponent as Facebook} from "@assets/pictures/svgs/facebook-active.svg";
import {ReactComponent as Instagram} from "@assets/pictures/svgs/instagram-active.svg";
import {ReactComponent as Google} from "@assets/pictures/svgs/google-active.svg";
import mail from "@assets/pictures/svgs/mail-active.svg";
import {ReactComponent as Twitter} from "@assets/pictures/svgs/twitter-active.svg";
import gogoLogo from "@assets/pictures/svgs/gogo-logo.svg";

// import { LANDING_PAGE } from '@config';
import qs from "qs";
import { titleCase } from "@helper/formatting";

function Footer({ t }) {
  const newsLetterEmail = useRef();
  const subscribeMsg = useRef();
  const history = useHistory();
  const organization = useSelector((state) => state.movies.organizationDetails);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  const { org_logo_url, org_mycinema_logo, org_name } = organization;
  const { pathname } = useLocation();
  if (
    pathname.startsWith("/create-show") ||
    pathname.startsWith("/screening-type") ||
    pathname.startsWith("/seat-layout") ||
    pathname.startsWith("/payment")
    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }
  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t("Thank you for subscribing");
      } else {
        subscribeMsg.current.innerText = t("You have already subscribed!");
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t("Please enter a valid email");
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == "Y") {
          return true;
        } else if (footer_cms[0][item] == "N") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log("i am here", url);
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <footer>
      <motion.div
        className="container-fluid container-xl bottom-menu-space"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="row d-none d-md-flex">
          <div className="col-12">
            <div className="actual-footer">
              <div className="row justify-content-between pb-4">
                <article className="col-7 col-lg-4 d-flex">
                  <div className="footer-links d-flex flex-column justify-content-between">
                    {showItem("Logo") ? (
                      <Link className="mb-0" to="/">
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          width="115px"
                          className=""
                        />
                      </Link>
                    ) : (
                      <div
                        className="dummy-image"
                        style={{
                          width: "100px",
                        }}
                      ></div>
                    )}
                    {showItem("Social Links") && (
                      <div className="footer-sm-links pb-0">
                        {isItemActive("facebok_link") && (
                          // <img
                          //   src={facebook}
                          //   alt=""
                          //   onClick={() =>
                          //     openUrl(social_media[0]?.facebok_link)
                          //   }
                          // />
                          <Facebook  onClick={() =>
                              openUrl(social_media[0]?.facebok_link)
                            } />
                        )}
                        {isItemActive("insta_link") && (
                          // <img
                          //   src={instagram}
                          //   alt=""
                          //   onClick={() => openUrl(social_media[0]?.insta_link)}
                          // />
                          <Instagram   onClick={() => openUrl(social_media[0]?.insta_link)}/>
                        )}
                        {isItemActive("twitter_link") && (
                          // <img
                          //   src={twitter}
                          //   alt=""
                          //   onClick={() =>
                          //     openUrl(social_media[0]?.twitter_link)
                          //   }
                          // />
                          <Twitter  onClick={() =>
                              openUrl(social_media[0]?.twitter_link)
                            }/>
                        )}
                      </div>
                    )}
                  </div>

                  {showItem("NewsLetter") && (
                    <form
                      className="ml-4
                   ml-lg-5"
                    >
                      <label htmlFor="">
                        <input
                          type="email"
                          name=""
                          id=""
                          className=""
                          placeholder={t("Enter your Email ID")}
                          ref={newsLetterEmail}
                        />
                        <button
                          className="btn-main"
                          onClick={(e) => {
                            e.preventDefault();
                            subscribe();
                            newsLetterEmail.current.value = "";
                          }}
                        >
                          {t("SUBSCRIBE")}
                        </button>
                      </label>
                      <p ref={subscribeMsg} />
                    </form>
                  )}
                </article>
                <div className="col-5 col-lg-4" id="first-row">
                  <div className="d-flex justify-content-between">
                    <div className="footer-links">
                      {showItem("How its Work") && (
                        <Link to="/how-it-works">
                          {t("common.How It Works")}
                        </Link>
                      )}
                      {showItem("Cinemas") && (
                        <Link to="/cinema-listing">
                          {t("common.Cinema Listing")}
                        </Link>
                      )}
                      {showItem("Promotion n Offers") && (
                        <Link to="/promotions-and-offers" className="">
                          {t("common.Promotions & Offers")}
                        </Link>
                      )}
                    </div>

                    <div className="footer-links ml-4">
                      {showItem("About US") && (
                        <Link to="/about-us">{t("common.About Us")}</Link>
                      )}
                      {showItem("Contact US") && (
                        <Link to="/contact-us">{t("common.Contact Us")}</Link>
                      )}
                      {showItem("FAQs") && (
                        <Link to="/faqs">{t("common.FAQs")}</Link>
                      )}
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center d-none d-md-flex">
                <div className="col-12">
                  <section className="bottom-row">
                    <p className="copyright-text">
                      © {t("All Rights Reserved")}
                    </p>
                    <div className="footer-links d-flex">
                      {showItem("Documentation") && (
                        <Link to="/documentation" className="mr-3 mb-0">
                          {t("common.Documentation")}
                        </Link>
                      )}
                      {showItem("Terms n Conditions") && (
                        <Link to="/tnc" className="mr-3 mb-0">
                          {t("common.Terms and Conditions")}
                        </Link>
                      )}

                      {showItem("Privacy Policy") && (
                        <Link to="/privacy-policy" className="mb-0">
                          {t("common.Privacy Policy")}
                        </Link>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {false && (
        <motion.div
          className="container-fluid container-xl bottom-menu-space"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="row d-none d-md-flex">
            <div className="col">
              <div className="actual-footer">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-9">
                    <div
                      className="d-flex justify-content-between flex-wrap"
                      id="first-row"
                    >
                      <div className="footer-links">
                        <p>{t("common.For Clients")}</p>
                        <Link to="/how-it-works">
                          {t("common.How It Works")}
                        </Link>
                        <Link to="/cinema-listing">
                          {t("common.Cinema Listing")}
                        </Link>
                      </div>

                      <div className="footer-links">
                        <p>{t("common.Company")}</p>
                        <Link to="/about-us">{t("common.About Us")}</Link>
                        <Link to="/contact-us">{t("common.Contact Us")}</Link>
                        <Link to="/faqs">{t("common.FAQs")}</Link>
                      </div>

                      <div className="footer-links">
                        <Link to="/documentation">
                          {t("common.Documentation")}
                        </Link>
                        <Link to="/tnc">
                          {t("common.Terms and Conditions")}
                        </Link>
                        <Link to="/promotions-and-offers">
                          {t("common.Promotions & Offers")}
                        </Link>
                        <Link to="/privacy-policy">
                          {t("common.Privacy Policy")}
                        </Link>
                      </div>

                      <div className="footer-links d-md-none">
                        <p>{t("common.Social Media")}</p>
                        <Link to="/contact-us">Facebook</Link>
                        <Link to="/contact-us">Instagram</Link>
                        <Link to="/contact-us">Twitter</Link>
                      </div>
                    </div>
                    <div className="row justify-content-center d-none d-md-flex">
                      <div className="col-sm-10 col-md-8 col-lg-5">
                        <div className="footer-sm-links">
                          <Link to="#" className="d-flex align-items-center">
                            {/* <img src={facebook} alt="" /> */}
                             <Facebook
                            />
                            <span>FACEBOOK</span>
                          </Link>
                          <Link to="#" className="d-flex align-items-center">
                            {/* <img src={google} alt="" /> */}
                            <Google/>
                            <span>Google</span>
                          </Link>{" "}
                          <Link to="#" className="d-flex align-items-center">
                            {/* <img src={twitter} alt="" /> */}
                            <Twitter/>
                            <span>Twitter</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row" id="last-row">
                      <div className="col-12 text-center">
                        <p className="copyright-text">
                          {/* <figure className="mb-0 mr-3 text-center"> */}
                          <Link to="/">
                            <img
                              src={org_mycinema_logo || org_logo_url} //gogoLogo
                              alt={org_name}
                              title="Home"
                              width="115px"
                              className="mr-3"
                            />
                          </Link>
                          {/* </figure> */}© {t("All Rights Reserved")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="dropdown-bg">
                                  <Dropdown className="w-100 h-100 pr-4"
                                      onClick={handleShow}>
                                      <Dropdown.Toggle className="dropdownClassic frxl-16 frl-14 frm-12 frs-10 kumbh fw-reg" id="dropdown-basic">
                                          {originalLanguage
                                              ? originalLanguage
                                              : (languages && languages.length > 0)
                                                  ? 'SELECT LANGUAGE'
                                                  : 'No Languages Found'
                                          }
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="dropdownItems">
                                                  <Dropdown.Item href="#/action-1" className="border-bottom">ENGLISH</Dropdown.Item>
                                                  <Dropdown.Item href="#/action-2" className="border-bottom">SANSKRIT</Dropdown.Item>
                                                  <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                                              </Dropdown.Menu>
                                  </Dropdown>
                              </div> */}
        </motion.div>
      )}

      {/*Start Mobile Bottom Menu */}
      {/* <section className="mobile-bottom-menu d-block d-md-none">
        <div className="d-flex justify-content-around align-items-center h-100">
          <div className="">
            <img src={homeIcon} alt="home" />
          </div>
          <div className="">
            <img src={moviesIcon} alt="movies" />
          </div>
          <div className="">
            <img src={transactionIcon} alt="mini-festival" />
          </div>
          <div className="">
            <img src={profileIcon} alt="home" />
          </div>
        </div>
      </section> */}
      <MobileBottomMenu />
      {/*End Mobile Bottom Menu */}
    </footer>
  );
}
export default withNamespaces()(Footer);
