import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASEURL, TOKEN } from "@src/config"
import { createApi } from "@reduxjs/toolkit/dist/query/react"

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASEURL}`,
  prepareHeaders: (headers, { getState, extra, endpoint, forced, type }) => {
    // const token = getState().auth.token

    if (TOKEN) {
      headers.set("Authorization", `${TOKEN}`)
    }

    return headers
  },
})

const baseApi = createApi({
  reducerPath: "base",
  baseQuery,
  endpoints: () => ({}),

  // providesTags of all api endpoint
  tagTypes: [
    "BANNERS",
    "UPCOMING",
    "ONDEMAND",
    "NOWSHOWING",
    "MINIFESTIVAL",
    "FESTIVALLIST",
  ],
})

export { baseApi, baseQuery }
