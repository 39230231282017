export const FETCH_FORMAT_LIST = "FETCH_FORMAT_LIST";
export const FETCH_FORMAT_LIST_SUCCESS = "FETCH_FORMAT_LIST_SUCCESS";
export const FETCH_FORMAT_LIST_ERROR = "FETCH_FORMAT_LIST_ERROR";

export const FETCH_LANGUAGE_LIST = "FETCH_LANGUAGE_LIST";
export const FETCH_LANGUAGE_LIST_SUCCESS = "FETCH_LANGUAGE_LIST_SUCCESS";
export const FETCH_LANGUAGE_LIST_ERROR = "FETCH_LANGUAGE_LIST_ERROR";

export const SEARCH_MOVIE = "SEARCH_MOVIE";
export const SEARCH_MOVIE_SUCCESS = "SEARCH_MOVIE_SUCCESS";
export const SEARCH_MOVIE_ERROR = "SEARCH_MOVIE_ERROR";

export const QUICK_BOOK = "QUICK_BOOK";
export const QUICK_BOOK_MOVIE_SUCCESS = "QUICK_BOOK_MOVIE_SUCCESS";
export const QUICK_BOOK_MOVIE = "QUICK_BOOK_MOVIE";
export const QUICK_BOOK_MOVIE_ERROR = "QUICK_BOOK_MOVIE_ERROR";

export const QUICK_BOOK_LANG_SUCCESS = "QUICK_BOOK_LANG_SUCCESS";
export const QUICK_BOOK_FORMAT_SUCCESS = "QUICK_BOOK_FORMAT_SUCCESS";
export const QUICK_BOOK_CINEMA_SUCCESS = "QUICK_BOOK_CINEMA_SUCCESS";
export const QUICK_BOOK_DATE_SUCCESS = "QUICK_BOOK_DATE_SUCCESS";
export const QUICK_BOOK_TIME_SUCCESS = "QUICK_BOOK_TIME_SUCCESS";
export const QUICK_BOOK_ERROR = "QUICK_BOOK_ERROR";

export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR";
export const SET_FILTER = "SET_FILTER";
export const SET_QB_STAGE = "SET_QB_STAGE";

export const SET_SELECTED_MINI_FESTIVAL = "SET_SELECTED_MINI_FESTIVAL";

export const FETCH_CMS = "FETCH_CMS";
export const FETCH_CMS_ERROR = "FETCH_CMS_ERROR";
export const FETCH_CMS_SUCCESS = "FETCH_CMS_SUCCESS";

export const SET_ON_DEMAND_CMS = "SET_ON_DEMAND_CMS";
export const SET_SOCIALMEDIA_CMS = "SET_SOCIALMEDIA_CMS";
export const SET_CMS_SETTINGS = "SET_CMS_SETTINGS";
export const SET_HEADERS_CMS = "SET_HEADERS_CMS";
export const SET_FOOTER_CMS = "SET_FOOTER_CMS";
export const SET_MOVIE_CMS = "SET_MOVIE_CMS";
export const FETCH_LABELED_MOVIES = "FETCH_LABELED_MOVIES";
export const FETCH_LABELED_ONDEMAND_MOVIES = "FETCH_LABELED_ONDEMAND_MOVIES";
