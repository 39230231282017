import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import useIsMobile from "../../customHooks/useIsMobile";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCashCardTopupTransactionDetail } from "../../store/cashCard/cashCardActions";
import Spinner from "../partials/Spinner";
import { currencyFormatter } from "../../helper/currencyFormatter";
import successIcon from "@assets/pictures/svgs/successIcon.svg";
import giftCardImg from "@assets/pictures/gift-card.png";

const CashCardSuccess = ({ t }) => {
  const isMobile = useIsMobile()
  const history = useHistory()
  const dispatch = useDispatch()
  const { topup_code } = useParams()

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCashCardTopupTransactionDetail(topup_code))
  }, [topup_code]);

  const cashCardTrx = useSelector((state) => state.cashCard.cashCardBuyTrx);
  const cashCardTrxLoading = useSelector((state) => state.cashCard.cashCardBuyTrxLoading);

  if (cashCardTrxLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  /*if (false)
  return (
    <>
      <section className="container-fluid about_us_age_rating_wrapper">
        <CenteredTitle firstHalf={t(
          "Cash Card Topup Success"
        )} secondHalf="" />

        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="mb-4 mb-md-5">
              <p>
                {`${cashCardTrx?.[0]?.buyer.first_name} ${cashCardTrx?.[0]?.buyer.last_name}`}
              </p>
              <p>
                {`${cashCardTrx?.[0]?.buyer.email}`}
              </p>
              <p>

              </p>
            </div>
          </div>

          {(cashCardTrx || []).map((card, index) => (
            <article className="col-md-6 col-xl-5 ml-xl-auto" key={index}>
              <div className="about_us_age_rating">
                <article>
                  <span>
                    {card?.cash_card_visible}
                  </span>
                  <p>
                    {t('Amount')}: {curr_code}{" "}{currencyFormatter(card?.credit_amount)}
                  </p>
                </article>
                <p>
                  <article className="gift_card_info">
                    <figure>
                      <img src={
                        card?.w_cash_card_image_url
                      } alt="w_cash_card_image_url" />
                      <h5 className="text_image" style={{ color: `${
                        card.cash_card_detail?.receipeint_detail?.websiteCashCard?.w_font_color || 'white'
                      }`}}>
                        {
                          card?.cash_card_detail?.receipeint_detail?.name_on_card || '...'
                        }
                      </h5>
                    </figure>
                  </article>
                </p>
              </div>
            </article>
          ))}

          <div className="col-xl-10 mx-auto">
            <p className="mt-4 mt-md-5">
              <span className="age_rating_highlight">{''}</span>
            </p>
          </div>
        </div>

      </section>
    </>
  );*/

  return (
    <>
      <div className="success_main">

        <div className="child_success">
          <div>
            <img
              src={successIcon}
              alt="tick"
              className="suc_img"
            />

          </div>
          <div className="suc_heading my-2">
            <span>SUCCESS !</span>
          </div>
          <p>
            {t("CashCard.SuccessMsg")}
          </p>

          <img
            src={ cashCardTrx?.[0]?.w_cash_card_image_url || giftCardImg }
            alt="card"
            className="suc_img_main mt-4"
            width="50%"
          />
          <button
            className="suc_btn mt-4"
            onClick={() => {
              history.push(`/`);
            }}
          >
            {t("Back to home")}
          </button>
        </div>

      </div>
    </>
  );
};

export default withNamespaces()(CashCardSuccess);
