import React, { useEffect,
  useState,
  useRef,
  useContext,
  useCallback} from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "./partials/Spinner";
import {
  fetchAllMoviesSchedules,
  setStage,
} from "../store/movies/movieActions";
import Dropdown from "react-bootstrap/Dropdown";
import { setFromMoviePage } from "../store/movies/movieActions";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { getLangSpecificAttribute, getFormattedDate, getMomentLocalLang } from '@helper/languages';
import {
  getMovieVersionsText,
  generateMovieVersionsText,
  movieLanguagesWithSubs,
} from "@helper/formatting";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import useIsMobile from "../customHooks/useIsMobile";
import ModalVideo from "react-modal-video";
import play from "@assets/pictures/svgs/play.svg";
import {ReactComponent as TrailerIcon} from "@assets/pictures/svgs/play_new.svg";

import {
  setSelectedLanguage,
  setSelectedCinema,
  setLanguageSpecificMovieFunction,
} from "../store/movies/movieActions";
import {
  headerBg,
  titleCase,
  getLangISOCode,
} from "../helper/formatting";
import {
  logout,
  setShowLoginPopup,
  guestLogout,
  fetchGuestHistory,
  updateUserLang,
} from "../store/auth/authActions";
import i18n from "../plugins/i18n";
/*import { Row, Col,Menu, Space,Button,Dropdown } from 'antd';*/
//Images
import {ReactComponent as Dx} from "@assets/pictures/svgs/4dx.svg";
import { hasDirector, hasStaring } from "@src/helper";
import _ from "lodash";

import { checkIfURLSame, getLabelToshow } from "@helper/formatting";
import { Link } from "react-router-dom";

const Schedules = ({ t }) => {
  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const languageArray = globalOptions.find(
    (go) => go.go_key === `KIOSK_LANGUAGE`
  )?.go_value;

  const [languages, setLanguages] = useState(null);
  useEffect(() => {
    languageArray && setLanguages(JSON.parse(languageArray));
  }, [languageArray]);

  const onSelectLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    let lang = language.iso_2
      ? language.iso_2.toLowerCase()
      : getLangISOCode(language.lang_name);
    // console.log("found lang = ", lang);
    i18n.changeLanguage(lang);
    moment.locale(getMomentLocalLang(lang.toLowerCase()));
    dispatch(setLanguageSpecificMovieFunction());
    dispatch(updateUserLang(language));
    // window.location.reload();
  };

const HEADERS_CMS = useSelector((state) => state.homepage.headers_cms);
const CMSLOADER = useSelector((state) => state.homepage.cmsLoader)

  const isNavItemActive = (item) => {
    if (!CMSLOADER) {
      if (HEADERS_CMS && HEADERS_CMS.length > 0) {
        if (HEADERS_CMS[0][item] == "Y") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const [moreDetails, setMoreDetails] = useState(false);
  const [moreDetailsMovieId, setMoreDetailsMovieId] = useState(null);
  const movieSynopsisRef = useRef();
  useEffect(() => {
    if (movieSynopsisRef.current) {
      const movieSynopsis = movieSynopsisRef.current;
    }
  }, [movieSynopsisRef.current]);
  let movieInfoObj = {}

  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(
    moment().locale("en").format("YYYY-MM-DD")
  );
  const [todaysDate, setTodaysDate] = useState(
    moment().locale("en").format("YYYY-MM-DD")
  );
  const [videoChannel, setVideoChannel] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [language, setLanguage] = useState(null);

  let selectedCinema = useSelector((state) => state.movies.selectedCinema);
  let cinema_id = selectedCinema?.cinema_id;
  let cine_timezone = selectedCinema?.cine_timezone;

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;

  let allMoviesSchedules = useSelector(
    (state) => state.movies.allMoviesSchedules
  );
  let allMoviesSchedulesLoading = useSelector(
    (state) => state.movies.allMoviesSchedulesLoading
  );
  let allMoviesSchedulesError = useSelector(
    (state) => state.movies.allMoviesSchedulesError
  );
  let allMoviesSchedulesLanguages = useSelector(
    (state) => state.movies.allMoviesSchedulesLanguages
  );

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  let movieSchedules = allMoviesSchedules.movieSchedules;
  let datesArray = allMoviesSchedules.datesArray;

  useEffect(() => {
    dispatch(
      fetchAllMoviesSchedules({
        start_date: selectedDate,
        cinema_id: !isNaN(parseInt(cinema_id)) ? cinema_id : null,
      })
    ).finally(() => {
      setLanguage(null)
    });
  }, [selectedDate, cinema_id]);

  const handleDateChange = () => {
    setSelectedDate((prev) => moment(prev).add(1, "week").format("YYYY-MM-DD"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filterMovieByLang = (movie, language) => {
    if (!language) return true

    let toShow = 0
    let screensFiltered = movie?.screens?.map(
      screen => (
        screen?.format_final_array?.filter(ffa => {
          return ffa?.format_name === language
        })
      )) ?? []

    screensFiltered.forEach(scrn => {
      if (scrn.length > 0)
        ++toShow
    })

    return toShow > 0
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
    return () => {
      document.body.style.overflow = ""
    }
  }, [isOpen])

  const isShowFull = (show) => {
    if (show?.screen_total_seats == undefined) return false
    if (show?.booked_seats == undefined) return false

    return show?.screen_total_seats == show?.booked_seats
  }


  if (allMoviesSchedulesLoading) {
    return (
      <div className="col-12 text-center">
        <Spinner />
      </div>
    );
  }

  function isPlotCollapsedPhoneStyle(isMobile, moreDetails, moreDetailsMovieId, movie) {
    if(!isMobile) return ""

    if (!moreDetails) return "movie_info_synopsis_partial_mobile"

    if (moreDetails && moreDetailsMovieId != movie?.movie_id) return "movie_info_synopsis_partial_mobile"

    return "";
  }

  return (
    <>
      <div className="container-fluid-sch header-space schedules" style={{overflowX:"hidden"}}>
        {/* cinema name and buttons */}
        {/*<h1 className="cinema-name">{selectedCinema?.cinema_name}</h1>*/}
        <h5 style={{color:"grey",fontSize:"12px",marginBottom:"15px"}} className="prg">
          {t("Program")} <span>&#62;</span> {t("Showtimes")}
        </h5>
     { /*  <h1 className="cinema-name" style={{color:"white"}}>
          {t("Showtimes")}
        </h1>*/}
        <section className="row "> {/*mob_fnb_over_flow*/}
          <article className="col" style={{paddingLeft:isMobile ? undefined :"0px"}}>
            <div className="my-2" style={{backgroundColor:"var(--dark-1)",padding:"0px",marginLeft:"0px"}}>

              {!isMobile &&<section style={{display:"inline-flex"}} className="pose">
                <h6 className="period mx-2 pt-2 px-2">{t("Period")}</h6>
                <button
                  className="schedules-btn-new"
                  style={{
                    border:"1px solid var(--primary)",
                    backgroundColor: todaysDate == selectedDate ? "var(--primary)" : undefined,
                    color:"white"
                  }}
                  onClick={() =>
                    setSelectedDate(moment().locale("en").format("YYYY-MM-DD"))
                  }
                >
                  {t("This Week")}
                </button>
                <button
                  className="schedules-btn-new-arrow"
                  onClick={handleDateChange}
                  style={{
                    // backgroundColor: todaysDate != selectedDate ? "var(--primary)" : undefined,
                    color:"white"
                  }}
                >
                  {t(">")}
                </button>


            <div className="next mx-5">
                  <label for="Language" className="period mx-2  px-2">
                    {t("Language")}
                  </label>
                  <Dropdown className="sch_drop">
                    <Dropdown.Toggle id="dropdown-basic" className="sch_drop_heading">
                      {!!language ? language?.split('/').map(lang => !!t(lang) ? t(lang) : lang).join('/') : `${t("All")} ${t("Language")}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="sch_drop_item">
                      <Dropdown.Item
                        className="sch_item"
                        onClick={e => {
                          setLanguage(null)
                        }}
                      >
                        {t("All")}
                      </Dropdown.Item>
                      {
                        allMoviesSchedulesLanguages?.length > 0 &&
                        allMoviesSchedulesLanguages.map((lang, index) => (<>
                          <Dropdown.Item
                            key={index}
                            className="sch_item"
                            onClick={e => {
                              setLanguage(!!lang ? lang : null)
                            }}
                          >
                            {lang?.split('/').map(lang => !!t(lang) ? t(lang) : lang).join('/')}
                          </Dropdown.Item>
                        </>))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                 { /*<select
                    name="Language"
                    className="dropdown-btn dropdown-toggle "
                    onChange={e => {
                      setLanguage(!!e?.target?.value ? e?.target?.value : null)
                    }}
                  >
                    <option value="">{t("All")}</option>
                    {
                      allMoviesSchedulesLanguages?.length > 0 &&
                      allMoviesSchedulesLanguages.map((lang, index) => (<>
                        <option
                          key={index}
                          value={lang}
                        >
                          {t(lang)}
                        </option>
                      </>))
                    }
                  </select>*/}

                </div>

                {/*<section className="mr-3">
                {isNavItemActive("Languages") && (
                  <li className="list-inline-item">
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="jod">
                            {titleCase(
                              selected_language?.alternate_lang_name ??
                                selected_language?.lang_name
                            )}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          align="right"
                          className="dropdownItems align-right"
                        >
                          {languages?.map((language, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                onSelectLanguage(language);
                              }}
                              className="selected"
                            >
                              {language?.alternate_lang_name ??
                                language?.lang_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                )}
                              </section>*/}

        </section>}
{/* All languages Mobile */}

{ 
  isMobile &&
  <div className="mob_lang_wrapper">
            <div>
                <button
                  className="schedules-btn-new"
                  style={{
                    border:"1px solid var(--primary)",
                    backgroundColor: todaysDate == selectedDate ? "var(--primary)" : undefined,
                    color:"white",
                    whiteSpace: isMobile ? "nowrap" : undefined,
                  }}
                  onClick={() =>
                    setSelectedDate(moment().locale("en").format("YYYY-MM-DD"))
                  }
                >
                  {t("This Week")}
                </button>
            </div>
            <div>
            <button
                  className="schedules-btn-new-arrow"
                  onClick={handleDateChange}
                  style={{
                    // backgroundColor: todaysDate != selectedDate ? "var(--primary)" : undefined,
                    color:"white"
                  }}
                >
                  {t(">")}
                </button>
                </div>
                <div>
              <Dropdown className="sch_drop" >
                    <Dropdown.Toggle id="dropdown-basic" className="sch_drop_heading">
                      {!!language ? language?.split('/').map(lang => !!t(lang) ? t(lang) : lang).join('/') : `${t("All")} ${t("Language")}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="sch_drop_item">
                      <Dropdown.Item
                        className="sch_item"
                        onClick={e => {
                          setLanguage(null)
                        }}
                      >
                        {t("All")}
                      </Dropdown.Item>
                      {
                        allMoviesSchedulesLanguages?.length > 0 &&
                        allMoviesSchedulesLanguages.map((lang, index) => (<>
                          <Dropdown.Item
                            key={index}
                            className="sch_item"
                            onClick={e => {
                              setLanguage(!!lang ? lang : null)
                            }}
                          >
                            {lang?.split('/').map(lang => !!t(lang) ? t(lang) : lang).join('/')}
                          </Dropdown.Item>
                        </>))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
            </div>
            </div>   
}


{/* All languages Mobile */}
          </div>

          </article>
      </section>



        {/* movie details and schedules */}
        {movieSchedules?.length > 0 &&
          movieSchedules.map((movie, movieIndex) => (
            filterMovieByLang(movie, language) &&
            <>
              <section className="row schedules-row bing" style={{}}>
                <article className={`${isMobile ? "col-12" : "col-lg-5 col-xl-5 col-md-9  mb-3 mb-lg-0"}`}>
                  <section className="row" style={{paddingTop: isMobile ? "20px" : "20px"}}>
                    <article className="col-5 pr-0 schedulesLabel">
                      {/* Movie Image */}
                      <figure className="img-wrapper position-relative movie_info_wrapper schedulesLabel  schedule_fixes">
                        <img
                          src={
                            getLangSpecificAttribute(
                              movie?.movie_content,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          onClick={() => {
                            dispatch(setStage({ stage: 1, show: null }));
                            history.push(
                              `/now-showing-booking/${movie?.movie_id}/${movie?.content_lang_id || 1}`
                            );
                          }}
                          alt={movie?.original_movie_title}
                          title={movie?.original_movie_title}
                          style={{cursor:"pointer"}}
                        />
                          {movie?.movie_labels_with_translations?.length > 0 && (
                            <article className="movie_info_label_wrapper">
                              <span className="movie_info_label_background" />
                              <span className="movie_info_label">
                                {getLangSpecificAttribute(
                                  getLabelToshow(movie?.movie_labels_with_translations),
                                  lang_id,
                                  "label_name",
                                  "lang_id"
                                )  || movie?.movie_labels_with_translations?.[0].label}
                              </span>
                            </article>
                          )}
                      </figure>
                    </article>
                    <article className={`schedule_fixes ${isMobile ? "col-7" : "col-7"}`} style={{paddingLeft:isMobile ? "20px" : undefined}}>
                      {/* Movie details */}
                      {/*<p style={{color:"var(--primary)",textTransform:"uppercase"}} className="pb-2">
                        {t("Now Showing")}
                                </p>*/ }
                      <p className="movie-title">
                        {getLangSpecificAttribute(
                          movie?.movie_content,
                          lang_id,
                          "mc_title"
                        ) || movie?.original_movie_title}
                      </p>
                      <table>
                        <tr>
                          <td>
                            <button className="age">
                              {movie?.rating}
                            </button>
                          </td>
                          <td>
                            <button className="newtime">
                              {movie?.runtime} {t("min")}
                            </button>
                          </td>
                          {false && (movie?.mrrdr_release_date || movie?.original_release_date) && <td>
                            <button className="newtime">
                              {getFormattedDate(
                                (movie?.mrrdr_release_date && movie?.mrrdr_release_date !== "Invalid date")
                                  ? movie?.mrrdr_release_date
                                  : (movie?.original_release_date && movie?.original_release_date !== "Invalid date")
                                    ? movie?.original_release_date
                                    : "TBA",
                                lang_name.toLowerCase() === "german"
                                  ? "DD. MMM YYYY"
                                  : "Do MMM, YYYY"
                              )}
                            </button>
                          </td>}
                          { /*<td style={{fontSize:"10px",color:"var(--primary)"}}>Saal 1 | ENG/DE-FR</td>*/}
                        </tr>
                        <tr>

                        </tr>
                      </table>
                      {!isMobile && (
                        <p className="schedule_movie_synopsis">
                          {getLangSpecificAttribute(
                            movie?.movie_content,
                            lang_id,
                            "mc_plot"
                          )}
                        </p>
                      )}

                      <div className=" align-items-center flex-wrap mt-4 mt-lg-1 can">
                        <span style={{marginRight:"12px"}} className="trai">
                        <td >
                      {false && movie?.trailers?.length > 0 && (
                                    <p className="mob_vi">
                                      {movie?.trailers?.length > 1
                                        ? t("common.Trailers")
                                        : t("Trailer")}{" "}
                                      :
                                    </p>
                                  )}
                                  </td>
                                  <td>
                                  <ul className={`${isMobile ? "list_mob" : "list-inline mb-0"}`}>
                                    <ModalVideo
                                      autoplay
                                      channel={videoChannel}
                                      isOpen={isOpen}
                                      videoId={currentVideoId}
                                      onClose={() => setOpen(false)}
                                    />
                                    {movie?.trailers?.map(
                                      (trailer, trailerIndex) => (
                                        <li
                                          className="list-inline-item"
                                          key={trailerIndex}
                                        >
                                          <p
                                            className={`mr-2 play-trailer my-1 sch_trailer_icon my-2`}
                                            onClick={() => {
                                              if (
                                                trailer?.trailer_link?.includes(
                                                  "youtube"
                                                )
                                              ) {
                                                setVideoChannel("youtube");
                                                setCurrentVideoId(
                                                  trailer?.trailer_link?.split(
                                                    "="
                                                  )[1]
                                                );
                                              }
                                              if (
                                                trailer?.trailer_link?.includes(
                                                  "vimeo"
                                                )
                                              ) {
                                                setVideoChannel("vimeo");
                                                setCurrentVideoId(
                                                  trailer?.trailer_link
                                                    ?.split("/")
                                                    .pop()
                                                    .split(".")?.[0]
                                                );
                                              }
                                              setOpen(true);
                                            }}
                                          >
                                            <TrailerIcon />
                                            {/*{generateMovieVersionsText(
                                              selectedCinema?.region_name,
                                              trailer.movie_lang_iso_3,
                                              trailer.sub_lang_1_iso_3,
                                              trailer.sub_lang_2_iso_3,
                                              trailer.movie_lang_iso_2 ?? '',
                                            )}*/}
                                            {movieLanguagesWithSubs(
                                              trailer?.movie_lang_iso_3,
                                              trailer?.sub_lang_1_iso_2,
                                              trailer?.sub_lang_2_iso_2,
                                            )}
                                          </p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </td>
                                </span>
                        <button
                          className="schedules-btn-main"
                          onClick={() => {
                            dispatch(setStage({ stage: 1, show: null }));
                            history.push(
                              `/now-showing-booking/${movie?.movie_id}/${movie?.content_lang_id || 1}`
                            );
                          }}
                        >
                          {t("Info")}
                        </button>
                      </div>
                    </article>
                  </section>
                </article>

               { isMobile && (
               <div className="movie_info_wrapper ">


                  <div className="movie_info_tabs">
                    <p
                      className={`${(!moreDetails || moreDetailsMovieId != movie?.movie_id) ? "active" : ""}`}
                      onClick={() => {
                        setMoreDetails(false);
                        setMoreDetailsMovieId(movie?.movie_id);
                      }}>
                      {t("Story")}
                    </p>
                    <p
                      className={`${(moreDetails && moreDetailsMovieId == movie?.movie_id) ? "active" : ""}`}
                      onClick={() => {
                        setMoreDetails(true);
                        setMoreDetailsMovieId(movie?.movie_id);
                      }}>
                      {t("More details")}
                    </p>
                  </div>
                  <section className="movie_info_more_details" style={{padding:"10px"}}>
                  {/* MOVIE DESCRIPTION */}
                  {
                  !!getLangSpecificAttribute(
                    movie?.movie_content,
                    lang_id,
                    "mc_plot"
                  ) && (
                    <p
                    className={`movie_info_synopsis ${(false && !moreDetails) ? "movie_info_synopsis_partial" : ""} ${
                        isPlotCollapsedPhoneStyle(isMobile, moreDetails, moreDetailsMovieId, movie)
                      }`
                    }
                    ref={movieSynopsisRef}>
                            {getLangSpecificAttribute(
                              movie?.movie_content,
                              lang_id,
                              "mc_plot"
                            )}
                    </p>
                  )}

                    {(moreDetails && moreDetailsMovieId == movie?.movie_id) &&
                      <>
                        {/* MOVIE LANGUAGE */}
                        {movie?.movie_languages?.length > 0 && (
                          <div>
                            <h6>
                              {t("common.Languages")}:{" "}
                            </h6>
                            <p>
                              {movie?.movie_languages
                              ?.map((x) => ( t(x.lang_name) || x.lang_name ))
                              .join(", ")}
                            </p>
                          </div>
                        )}

                        {/* MOVIE GENRES */}
                        {!!(movie?.genres
                          ?.map((g) => g[iso_2?.toLowerCase()])
                          .filter((x) => x)
                          .splice(0, 13)
                          .join(", "))
                          && (
                            <div>
                              <h6 style={{ color: "white" }}>{t("Genre")}:{" "}</h6>
                              <p>
                                {
                                  (<>
                                    {movie?.genres
                                    ?.map((g) => g[iso_2?.toLowerCase()])
                                    .filter((x) => x)
                                    .splice(0, 13)
                                    .join(", ")}
                                  </>)
                                }
                              </p>
                            </div>
                          )}
                        {/* MOVIE CAST */}
                        {hasStaring(movie?.cast_crew) && (
                          <div>
                            <h6>
                              {t("Cast")}:{" "}

                            </h6>
                            <p className="comma-separated">
                              {movie?.cast_crew?.map(
                                (cast) =>
                                  cast?.crew_title !== `Director` && (
                                    <span>{cast.cast_name}</span>
                                  )
                              )}
                            </p>
                          </div>
                        )}

                        {/* MOVIE DIRECTOR */}
                        {hasDirector(movie?.cast_crew) && (
                          <div>
                            <h6>
                              {t("Director")}:{" "}
                            </h6>
                            <p className="comma-separated">
                              {movie?.cast_crew?.map(
                                (cast, index) =>
                                  cast?.crew_title === `Director` && (
                                    <span>{cast.cast_name}</span>
                                  )
                              )}
                            </p>
                          </div>
                        )}
                      </>
                    }
                  </section>

                </div>

               )}
               {/*  */}
                {/* Schedules */}
                <article className="col-lg-6 mk_pd">
                  <div className="schedules-section overflow-x-visible">
                    {/* Dates and Days */}
                    <div className="schedule-grid date-n-day">
                      {/* First two boxes is for offset */}
                      <section className="make"  style={{display:"none"}}/>
                      <section className="make"/>
                      {movie?.datesArray?.length > 0 &&
                        movie?.datesArray?.map(
                          ({ date, formats, totalShowsToday }, dateIndex) => (
                            <section
                                className={'datesArrayDateTime'}
                                style={{ opacity: totalShowsToday > 0 ? 1 : 0.5,fontWeight:"300px" }}
                                key={dateIndex}
                            >
                              {date === todaysDate ? (
                                <>
                                  <p>{t("Today")}</p>
                                  <p >
                                    {getFormattedDate(
                                      date,
                                      lang_name.toLowerCase() === "german"
                                        ? "DD- MMM"
                                        : "MMM Do"
                                    )}

                                  </p>
                                </>
                              ) : (
                                <>
                                  <p >{moment(date).format("ddd")}</p>
                                  <p>{moment(date).format("DD.MM")}</p>
                                </>
                              )}
                            </section>
                          )
                        )}
                    </div>

                    {movie?.screens?.length > 0 &&
                      movie?.screens?.map(
                        (screen, screenIndex) => (
                          screen?.format_final_array?.filter(ffa => {
                            if (!language)
                              return true
                            else
                              return ffa?.format_name === language
                          })?.map((ffa, ffi) => (
                            <React.Fragment key={ffi}>
                            <div className="saal">
                              <button className="saalnew2">
                                {
                                  ffa?.format_name?.split('/').map(lang => !!t(lang) ? t(lang) : lang).join('/')
                                  // t(ffa?.format_name)
                                }
                              </button>
                            </div>

                            <div
                              className="schedule-grid schedule"
                              key={screenIndex}
                            >
                              {/* Language */}
                              <section style={{ display: "none" }}>
                              </section>

                              <section className="sec_screen" style={{ borderLeft: "none" }}>
                                <span className="hall-name">
                                  {screen?.screen_name}
                                </span>
                              </section>


                              {ffa?.shows?.length > 0 &&
                                ffa?.shows?.map((showDate, showDateIndex) =>
                                  showDate?.shows?.length === 0 ? (
                                    <section key={showDateIndex}></section>
                                  ) : (
                                    <section className="" key={showDateIndex}>
                                      {showDate?.shows.sort((a, b) => {
                                        return a?.time_stamp_show - b?.time_stamp_show
                                      })?.map((show, showIndex) => (
                                        <>
                                          <div
                                            disabled={true}
                                            className={`movie-card__tooltip__wrapper show-btn yellow lg ${
                                              show?.mycinema_booking_type_id == 2
                                                ? "yellow"
                                                : "green"
                                            } ${
                                              false && movie?.mf_name ? "lg" : ""
                                            } ${
                                              // false && movie?.showType == 3
                                              isShowFull(show)
                                                ? "full"
                                                : ""
                                            } ${

                                              momentTimezone
                                              .tz(
                                                moment(
                                                  show?.ss_actual_start_date,
                                                  "YYYY-MM-DD"
                                                ).format("YYYY-MM-DD") +
                                                " " +
                                                show?.ss_start_show_time,
                                                "YYYY-MM-DD HH:mm",
                                                cine_timezone
                                              )
                                              .isBefore(
                                                momentTimezone().tz(cine_timezone)
                                              )
                                                ? "inactive"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (isShowFull(show)) return
                                              dispatch(setFromMoviePage());
                                              history.push(
                                                // `/seat-layout/${show.ss_id}/${show.screen_id}/${show.md_id}`
                                                `/now-showing-single-show-booking/${show.ss_id}/${show.screen_id}/${show.md_id}/${show.movie_id}`
                                              );
                                            }}
                                          >

                                            <div className="">
                                              <p
                                                className={`show-time ${!!show?.mf_name && show?.mf_name?.toLowerCase() !== "2d"}`}
                                                style={{
                                                  marginTop: !!show?.mf_name && show?.mf_name?.toLowerCase() !== "2d" ?  undefined : "unset",
                                                }}
                                              >
                                                {show?.ss_start_show_time}
                                              </p>
                                              {
                                                (!!show?.mf_name && show?.mf_name?.toLowerCase() !== "2d") && (
                                                  <button className="three_d">{
                                                    show?.mf_name?.toLowerCase() == "4dx"
                                                      ? <Dx/>
                                                      : show?.mf_name
                                                  }</button>
                                                )
                                              }
                                              {
                                                isShowFull(show) && (
                                                  <article className="movie-card__tooltip">
                                                    <p style={{ width: isMobile ? undefined : 'max-content' }}>{t('Fully Booked')}</p>
                                                  </article>)
                                              }
                                              {
                                                show?.mycinema_booking_type_id === 2 && (
                                                  <article className="movie-card__tooltip">
                                                    <p>{t("CrowdSource Screening")}</p>
                                                  </article>)
                                              }
                                            </div>
                                            {/* {true && (
                                      item?.mf_name &&
                                        item?.mf_name.toLowerCase() !== "2d" &&

                                      <div className="exp">{show?.mf_name}</div>
                                    )} */}
                                          </div>
                                         {/* {
                                            (!!show?.mf_name && show?.mf_name?.toLowerCase() !== "2d") &&
                                            <button className="d_btn">
                                          <span>
                                            {show?.mf_name}
                                          </span>
                                              {
                                                show?.experience_format?.toLowerCase() == "4dx" &&
                                                <Dx style={{ paddingLeft: "5px", width: "20px" }} />
                                              }
                                            </button>
                                          }*/}
                                        </>
                                      ))}
                                    </section>
                                  )
                                )}

                            </div>
                            </React.Fragment>
                            ))
                        )
                      )
                    }
                  </div>

                  {
                    movie?.nextAvailableSS &&
                    movie?.nextAvailableSS?.split('|')?.[0] &&
                    movie?.nextAvailableSS?.split('|')?.[1] && (
                      <Link
                        to={`/now-showing-single-show-booking/${movie?.nextAvailableSS?.split('|')?.[0]}`}
                      >
                        <section className="col-12 mt-4">
                        <span className="hall-name w-100" style={{color:"white"}}>
                          {t("Next available show on")} {`${getFormattedDate(
                          movie?.nextAvailableSS?.split("|")?.[1],
                          lang_name.toLowerCase() === "german"
                            ? "DD-MMM"
                            : "MMM Do"
                        )?.toUpperCase()
                        }`}
                        </span>
                        </section>
                      </Link>
                    )
                  }
                </article>
              </section>
              {/*movieIndex !== movieSchedules.length - 1 && <hr />*/}
            </>
          ))}

        {movieSchedules?.length === 0 &&
          allMoviesSchedulesLoading === false && (
            <div className="d-flex justify-content-center align-items-center">
              {t("No Movies Found")}
            </div>
          )}
      </div>
    </>
  );
};

export default withNamespaces()(Schedules);
